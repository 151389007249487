<template>
    <div class="homePage" :class="isBlack != 'true'?'whiteBox':''">
		<div class="top_header">
			<div class="header">
				<div class="header_left">
					<img src="../../static/demo/logo.png" class="logoimg" style="height: 42px;width: auto;" />
					<div class="leftname" v-if="false">
						HKEX
					</div>
				</div>
				<div class="header_right">
					<div class="action">
						UID:{{userid}}
					</div>
				</div>
			</div>
		</div>
		
		<div class="content">
			
			<div  class="new-feature" dir="auto">
				<div  class="left-bg1"></div>
				<div  class="left-bg2"></div>
				<div  class="titles">
					<div  class="balance">
						<img  src="../../static/demo/balance.0dce5020.svg" alt="">
					</div> {{$t('tabbar.text6')}}
				</div>
				<div  class="banalce-info">
					<div  style="display: inline;">${{useryue}}</div>
					<span  dir="ltr">0.00%</span>
				</div>
				<div  class="infos">
					<div  class="info">
						<div  class="info-title">{{$t('tabbar.text7')}}</div>
						<div  class="info-content" dir="ltr">${{ topinfo.dayshouyi }}</div>
					</div>
					<div  class="info">
						<div  class="info-title">{{$t('tabbar.text3')}}</div>
						<div  class="info-content" dir="ltr">$ {{ topinfo.shouyi }}</div>
					</div>
					<div  class="info">
						<div  class="info-title">{{$t('tabbar.text8')}}</div>
						<div  class="info-content" dir="ltr">0.00%</div>
					</div>
				</div>
			</div>
			
			<div  class="buttons-list">
				
				
				<div v-for="(item, index) in toplist" @click="toInfos(item[0],0)" class="button-big button-big1" dir="auto">
					<div  class="title">{{ item[0] }}/{{ item[1] }}</div>
					<div  class="address">Bitcoin</div>
					<div  class="number-info number-info-down" v-if="item[8]<0">
						<div >{{item[8]}}<span >%</span>
						</div>
						<div  class="number-info-arrow">
							<!---->
							<img  src="../../static/demo/down_arrow.72e387a2.svg" alt="">
						</div>
					</div>
					<div v-else class="number-info">
						<div >{{item[8]}}<span >%</span>
						</div>
						<div  class="number-info-arrow">
							<img  src="../../static/demo/up_arrow.05a4c908.svg" alt="">
							<!---->
						</div>
					</div>
				</div>

			</div>
			
			
			<div class="markets">
				<div class="markets-title">
					<span>{{$t('tabbar.text2')}}</span>
					<span class="more" @click="gotomarket">{{$t('tabbar.text9')}}</span>
				</div>
				<div v-for="(item, index) in list" :key="index" @click="toInfos(item[0],0)" class="markets-main">
					<div class="markets-icon markets-icon1">
						<img class="icon1" :src="'https://hkex-admin.t92.net' + item[10]" />
						<div class="markets-name">
							{{ item[0] }}/{{ item[1] }}
							<div class="names">{{ item[9] }}</div>
						</div>
					</div>
					<div class="markets-chart">
					    <div :id="'myChart' + index" :style="{ width: '88px', height: '40px' }"></div>
					</div>
					<div class="markets-number">
						{{ item[2] }}
						<div v-if="item[8]<0"  class="rersult">{{item[8]}}%</div>
						<div v-else  class="rersult rersult-succsee">+{{item[8]}}%</div>
					</div>
				</div>
			</div>
			
			<div  class="strategy" dir="auto">
				<div  class="strategy-icon">
					<img  src="../../static/demo/saleCoin.6d24a7ae.svg" alt="">
				</div>
				<div  class="strategy-text">{{$t('tabbar.text10')}}</div>
				<div  class="strategy-text1">{{$t('tabbar.text11')}}</div>
				<div  class="strategy-text">{{$t('tabbar.text12')}}</div>
				<div  class="strategy-button" @click="gotosaleCoin">{{$t('tabbar.text13')}}</div>
			</div>
			
			<div  class="strategy" dir="auto">
				<div  class="strategy-icon">
					<img  src="../../static/demo/trading.60cebfaa.svg" alt="">
				</div>
				<div  class="strategy-text">{{$t('tabbar.text14')}}</div>
				<div  class="strategy-text1">{{$t('tabbar.text15')}}</div>
				<div  class="strategy-text">{{$t('tabbar.text16')}}</div>
				<div  class="strategy-button" @click="gototab2">
					<img  src="../../static/demo/icon_add.df32452b.svg" alt="">{{$t('tabbar.text17')}}</div>
			</div>
			
		</div>
		
		<div style="height: 50px;"></div>
      <Loading :show="loading"></Loading>
    </div>
</template>


<script>
import {
    toRefs,
    reactive,
    onMounted,
    nextTick
} from 'vue'
import * as echarts from 'echarts'

import {
    useRouter
} from 'vue-router'
import {
  getallcoinList, kline, kline1, kxianbData, getmsgunread,balance
} from '@/api/tab1'
import { initIndex } from "@/api/tab4";
import { logall } from "@/api/tab2";
import { userlogin,Login } from '@/api/hometab'
import Loading from "@/components/Loading";
import { showToast } from 'vant';
import Leftmenu from '@/components/leftmenu.vue'
import { useI18n } from "vue-i18n";

export default {
    components: {
      Loading,
	  Leftmenu
    },
    setup() {
        let router = useRouter();

		const {
		  t,locale
		} = useI18n()
        // 数据
        const State = reactive({
            query: {
                id: 1, //交易区，默认传1
                type: 0 //0 货币 1 股票 2贵金属 3外汇
            },
            list: [],
			toplist:[],
            isBlack: 'true',//默认黑色（缓存内的值是字符串）
			loading: false,
			tongzhinum:0,
			
			setlang:'en',
			
			username:'',
			userid:'',
			
			useryue:0,
			topinfo: [],
        });

        // 方法
        const Function = reactive({
            setTheme() {
                //console.log('主题模式', localStorage.getItem('isBlack'))
                State.isBlack = 'true';
                if (localStorage.getItem('isBlack')) {
                    State.isBlack = localStorage.getItem('isBlack')//true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
                }
				balance().then(res => {
					State.useryue = res.data.url.banlance
				})
				logall().then((res) => {
				       
				  State.topinfo = res.data.info;
				});
				userlogin().then(res => {
					if(res.data.url){
						//if(localStorage.getItem('username') !== undefined){
							//console.log(1)
							//State.username = localStorage.getItem('username').substr(-8)
						//} else {
							//console.log(2)
							//console.log(res.data.url.username)
							localStorage.setItem('username', res.data.url.username)
							State.username = localStorage.getItem('username').substr(-8)
						//}
					} else {
						if (window.ethereum) {
							window.ethereum.enable().then((data) => {
							    //console.log("当前钱包地址:" + data[0])
								const param = {
								  username: data[0]
								}
								Login(param).then(res => {
									if(res.data.status==0){
										showToast(res.data.info);
									} else if(res.data.status==1){
										//sessionStorage.setItem('username', param.username)
										localStorage.setItem('username', param.username)
										window.location.reload()
									}
									
								}).catch((error) => {
									//console.log(error.message)
									showToast(error.message)
								})
							}).catch((error) => {
								//console.log(error.message)
								showToast(error.message)
							})
						} else {
							router.push({
							    path: '/login'
							})
						}
						
					}
				}).catch((error) => {
					//console.log(error.message)
					showToast(error.message)
				})
				initIndex()
				  .then((res) => {
				    State.userid = res.data.url.userid;
				  })
				  .catch((err) => {
				    console.log("err-->", err);
				  });
            },
			quehuan(type){
				State.query.type = type
				localStorage.setItem('indextab', State.query.type)
				Function.getData()
			},
            getData() {
				//console.log(State.query)
        //       kline({symbol: 'btcusdt',period: '1min',size: 50})
              State.loading = true
              kline(State.query).then(res => {
                    const {
                        data
                    } = res;
                    State.list = data.url
					State.toplist = data.url.slice(0,4)
                    if (State.list?.length > 0) {
                        nextTick(() => {
                            State.list.forEach((item, index) => {
                                Function.setEcharts(item, index)
                            })
                        })
                    }
                State.loading = false
                })
            },
			leftmenu(){
				// 获取子组件实例
				//console.log(this.$refs.menu)
				const childInstance = this.$refs.menu;
				//console.log(childInstance)
				// 直接修改子组件中的 data 数据
				childInstance.showLeft = !childInstance.showLeft;
			},
			toInfos(coin,type) {
			    router.push({
			        path: '/marketInfo',
			        query: {
			            coin,
						type
			        }
			    })
			},
			gotomarket(){
				router.push({
				    path: '/market',
				})
			},
			gotonotifiCations(){
				router.push({
				    path: '/notifiCations',
				})
			},
			gotosaleCoin(){
				router.push({
				    path: '/saleCoin',
				})
			},
			gototab2(){
				router.push({
				    path: '/tab2',
				})
			},
            setEcharts(item, index) {
                var x = []
                var y = []
                if (item && item.k && item.k?.length > 0) {
                    item.k.forEach(itemdata => {
                        x.push(itemdata.time)
                        y.push(itemdata.value)
                    })
                    //console.log(item)
                    let colors = '#ce7735'
                    if (item[6] > 0) {
                        colors = '#c2e22a'
                    }
                    let myChart = echarts.init(document.getElementById("myChart" + index));

                    // 绘制图表
                    let option = {
						grid: {
						    left: '0%',
						    right: '0%',
						    top: '0%',
						    bottom: '0%',
						},
                        xAxis: {
                            data: x,
                            show: false
                        },
                        yAxis: {
                            show: false,
                            splitLine: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                            min: function (value) {
                                return value.min;
                            },
                          max:function (value) {
                            return value.max
                          }
                        },
                        tooltip: {
                            show: false
                        },
                        series: [{
                            type: 'line',//折线
                            smooth: true,//是否曲线显示
                            data: y,
                            lineStyle: {//线条的样式
                                width: 1,
                                color: colors
                            }
                        }]
                    }
                    myChart.setOption(option);
                }

            },
        });
        // 接口
        const Interface = reactive({});

        onMounted(() => {
            Function.getData()
            Function.setTheme()

        });
        return {
            ...toRefs(State),
            ...toRefs(Function),
            ...toRefs(Interface),
        }
    },
}
</script>

<style scoped lang="scss">
.homePage {
    background-color: #000;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
	color: #ffffff;

	.top_header{
		position: fixed;
		top: 0;
		left: 0;
		height: 29px;
		width: 100%;
		background: #000;
		padding: 16px 0;
		z-index: 10;
	}
	.header{
		padding: 0 20px;
		height: 29px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #ffffff;
	}
	.header_left{
		display: flex;
		align-items: baseline;
	}
	.logoimg{
		width: 24px;
		height: 24px;
		margin-right: 6px;
	}
	.leftname{
		font-family: Inter;
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
	}
	.header_right{
		//display: flex;
		align-items: center;
		justify-content: space-between;
		width: 124px;
		height: 42px;
		border-radius: 10px;
		background: #1a1a1a;
		position: relative;
		text-align: center;
		line-height: 42px;
		
		.action{
			//display: flex;
			text-align: center;
			align-items: center;
			font-weight: 500;
			font-size: 14px;
			//margin-left: 12px;
			//margin-right: 12px;
		}
	}
	
	.content{
		margin: auto;
		margin-top: 61px;
		padding-bottom: 30px;
		border-radius: 20px;
		padding-left: 20px;
		padding-right: 20px;
		
		.new-feature{
			width: calc(100% - 32px);
			height: 144px;
			background: #1a1a1a;
			border-radius: 20px;
			padding: 16px;
			overflow: hidden;
			position: relative;
			
			.left-bg1{
				background: #d8d8d8;
				mix-blend-mode: normal;
				opacity: .08;
				transform: rotate(-35deg);
				width: 139.84px;
				height: 236.21px;
				position: absolute;
				right: -65px;
				top: -100px;
			}
			.left-bg2{
				right: -100px;
				top: -90px;
				background: #d8d8d8;
				mix-blend-mode: normal;
				opacity: .08;
				transform: rotate(-35deg);
				width: 139.84px;
				height: 236.21px;
				position: absolute;
			}
			.titles {
			    font-weight: 500;
			    font-size: 14px;
			    display: flex;
			    justify-content: flex-start;
			}
			.balance {
			    width: 16px;
			    height: 16px;
			    margin-right: 8px;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			}
			.banalce-info {
			    font-family: DIN Alternate;
			    font-style: normal;
			    font-weight: 700;
			    font-size: 25px;
			    margin-top: 15.5px;
			    border-bottom: 1px solid hsla(0, 0%, 100%, .07);
			    padding-bottom: 15.5px;
			}
			.banalce-info span {
			    font-family: Inter;
			    font-weight: 600;
			    font-size: 14px;
			    color: #c2e22a;
			    margin-left: 14px;
			}
			.infos {
			    display: flex;
			    justify-content: space-between;
			    margin-top: 15px;
			}
			.info-title{
			    font-weight: 500;
			    font-size: 14px;
			    color: #fff;
			    opacity: .3;
			}
			.info-content {
			    font-weight: 700;
			    font-size: 14px;
			    font-family: DIN Alternate;
			    margin-top: 8px;
			}
		}
		.buttons-list {
		    width: 100%;
		    height: 110px;
		    margin-top: 20px;
		    display: -webkit-box;
		    display: -moz-box;
		    -webkit-box-orient: horizontal;
		    overflow-x: scroll;
		    overflow-y: hidden;
		    -webkit-overflow-scrolling: touch;
		    scrollbar-width: none;
		    -ms-overflow-style: none;
			
			.button-big1 {
			    margin-left: 0;
			}
			.button-big {
			    background: linear-gradient(180deg, #2c2c2c, #1a1a1a);
			    opacity: .8;
			    border-radius: 16px;
			    width: calc(33% - 18px);
			    height: 94px;
			    padding: 16px 0 0 12px;
			    margin-left: 10px;
			}
			.title {
			    font-weight: 600;
			    font-size: 14px;
			}
			.address {
			    font-weight: 400;
			    font-size: 12px;
			    color: #fff;
			    opacity: .3;
			    margin-top: 4px;
			}
			.number-info {
			    font-weight: 600;
			    font-size: 20px;
			    display: flex;
			    justify-content: space-between;
			    color: #c2e22a;
			    margin-top: 18px;
			}
			.number-info span {
			    font-size: 12px;
			}
			.number-info-down {
			    color: #ce7735;
			}
			.number-info-arrow {
			    width: 22px;
			    height: 22px;
			    border-radius: 22px;
			    background: hsla(0, 0%, 100%, .13);
			    margin-right: 16px;
			    line-height: 22px;
			    text-align: center;
			}
		}
		
		.markets {
		    background: #1a1a1a;
		    border-radius: 20px;
		    margin-top: 20px;
		    padding-bottom: 11px;
			
			.markets-title {
			    height: 59px;
			    line-height: 59px;
			    padding: 0 16px;
			    width: calc(100% - 32px);
			    display: flex;
			    justify-content: space-between;
			    font-weight: 600;
			    font-size: 16px;
			}
			.more {
			    font-weight: 400;
			    font-size: 14px;
			    opacity: .3;
			}
			.markets-main {
			    width: calc(100% - 32px);
			    height: 40px;
			    margin: auto;
			    display: flex;
			    justify-content: space-between;
			    padding: 13px 16px;
			}
			.markets-icon {
			    display: flex;
			    flex-direction: row;
			    width: 124px;
			}
			.icon1 {
			    height: 32px;
			    z-index: 1;
			    margin-right: 12px;
			}
			.markets-name {
			    font-weight: 600;
			    font-size: 14px;
			}
			.names {
			    font-weight: 500;
			    font-size: 12px;
			    color: hsla(0, 0%, 100%, .3);
			    margin-top: 6px;
			    height: 12px;
			}
			.markets-chart {
			    width: 88px;
			}
			.markets-number {
			    font-weight: 600;
			    font-size: 14px;
			}
			.rersult {
			    font-weight: 500;
			    font-size: 12px;
			    color: #ce7735;
			    margin-top: 8px;
			    text-align: right;
			}
			.rersult-succsee {
			    color: #c2e22a;
			}
		}
		
		.strategy {
		    height: 248px;
		    background: #1a1a1a;
		    border-radius: 20px;
		    margin-top: 20px;
		    text-align: center;
		    padding-top: 32px;
			
			.strategy-text {
			    font-weight: 500;
			    font-size: 14px;
			    color: hsla(0, 0%, 100%, .3);
			    margin-top: 14px;
			}
			.strategy-text1 {
			    margin-top: 14px;
			    font-weight: 500;
			    font-size: 22px;
			    color: #fff;
			}
			.strategy-text {
			    font-weight: 500;
			    font-size: 14px;
			    color: hsla(0, 0%, 100%, .3);
			    margin-top: 14px;
			}
			.strategy-button {
			    margin: auto;
			    width: 304px;
			    height: 48px;
			    background: #c0dd2b;
			    border-radius: 13px;
			    color: #000;
			    font-weight: 600;
			    font-size: 16px;
			    text-align: center;
			    line-height: 48px;
			    margin-top: 24px;
			}
		}
	}
}
</style>

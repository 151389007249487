export default {
	account: {
		Start_making_money_plan: "Inizia a fare soldi pianificando",
		Market: "Mercato",
		Migital_currency: "Valuta Migitale",
		Foreign_exchange: "Cambio valuta",
		Noble_metal: "Metallo nobile",
		Send_Cryop_Now: "Invia criopreservazione ora",
		Send: "Inviare",
		Receive: "Ricevere",
		Buy: "Acquistare",
		Currency_account: "Conto valutario",
		Contract_account: "Conto contrattuale",
		Transfer: "Trasferimento",
		Select_a_wallet: "Seleziona un portafoglio",
		Select_Coin: "Seleziona moneta",
		Confirm: "Confermare",
		Wallet: "Portafoglio",
		Availavle: "Disponibile",
		Frozen: "Congelato",
		Convert: "Convertire",
		Receiving_Address: "Indirizzo di ricezione",
		Amount: "Quantità",
		Max: "Massimo",
		Send_Now: "Spedisci ora",
		Please_check_if: "Si prega di verificare che l'indirizzo di ricezione sia corretto prima di inviare, in modo da non causare perdite di beni. La commissione di prelievo è",
		new1: 'criptovaluta',
		new2: 'indice',
		new3: 'Massa',
		new4: 'Forex',
		new5: 'saldo totale',
	},
	"314962-0": "Google 2FA",
	"314962-1": "Scarica Google Authenticator per collegare",
	"314962-2": "Copia il codice di verifica",
	"314962-3": "Inserisci il codice di verifica",
	"314962-4": "Richiesta fallita:",
	"314962-5": "Codice di verifica copiato",
	"314962-6": "Impossibile copiare il testo:",
	"314962-7": "Copia fallita, riprova",
	"199917-0": "Impostazioni",
	"199917-1": "Notifiche",
	"199917-2": "Google 2FA",
	"199917-3": "Email",
	"199917-4": "Lingua",
	"199917-5": "Versione",
	"199917-6": "Esci",
	home: {
		USDTAccount: 'USDT Account',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: 'Open',
		ETHTodayProfit: 'ETH Today\'s Profit',
		totalRevenue: 'Total Revenue',
		portfolio: 'Portfolio',
		reliableSecurity: 'Reliable Security Guarantee',
		stableReliable: 'Stable and Reliable Investment',
		convenienEasy: 'Convenien and Easy Operation',
		pleaseLookForward: 'Please look forward to...',
		rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
	},
	"964128-0": "Giorno",
	"964128-1": "Oh!",
	"964128-2": "Non hai registrazioni di transazioni. L'elenco è vuoto.",
	"652934-0": "Commercio Quantitativo AI",
	"652934-1": "Quantità AI",
	"652934-2": "Profitto di Oggi",
	"652934-3": "Profitto Totale",
	"652934-4": "Sicurezza dei Fondi",
	"652934-5": "Profitto Stabile",
	"652934-6": "Operazione Semplice",
	"652934-7": "Visualizza di Più",
	"652934-8": "Crea Commercio AI Quantitativo",
	"652934-9": "Periodo",
	"652934-10": "Quantità",
	"652934-11": "Tasso di Residuo",
	"652934-12": "Quota",
	"652934-13": "Conferma",
	"652934-14": "Informazioni in Alto",
	"652934-15": "Modalità Tema",
	"652934-16": "Prova---",
	"ConnectW": "Connect Wallet",
	"dappbrowser": "This website only accepts access from decentralized wallet DAPP browsers.",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "Earn Interest",
	"$": "$",
	"ETH Today's Profit": "ETH Today's Profit",
	"Wallet": "Wallet",
	"Record": "Record",
	"Support": "Support",
	"General": "General",
	"Notifications": "Notifications",
	"Invite Friends": "Invite Friends",
	"FAQ": "FAQ",
	"Privacy & Security": "Privacy & Security",
	"Legality": "Legality",
	"Authenticator": "Authenticator",
	"Settings": "Settings",
	"Change Language": "Change Language",
	"Daytime mode": "Daytime mode",
	"Black mode": "Black mode",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Lungo',
    'Down':'Breve',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
	'lessthans': 'non può essere maggiore di',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',
	'About_us': 'Di noi',





	//钱包
	TransactiónDetails: 'Transactión Details',
	dealdetails: 'deal details',
	ViewTransactionHistory: 'View Transaction History',
	Deposit: 'Deposit',
	Rechargeaddress: 'Recharge address：',
	DepositAmount: 'Deposit Amount：',
	EnterDepositAmount: 'Enter Deposit Amount',
	EnterHash: 'Enter Hash',
	Hash: 'Hash：',
	Voucherimage: 'Voucher image：',
	Continue: 'Continue',
	Withdraw: 'Withdraw',
	Withdrawal: 'Withdrawal：',
	EnterAmounttoWithdraw: 'Enter Amount to Withdraw',
	Available: "Available：",
	ReceiveAddress: 'Receive Address：',
	EnterWalletAddress: 'Enter Wallet Address',
	WithdrawalDesc: 'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
	Exchange: 'Exchange',
	Send: 'Send:',
	Max: 'Max:',
	rechargeAmount: 'Please input a recharge amount',
	hashcode: 'hash code and screenshot cannot be empty at the same time',
	correcthashcode: 'please input a correct hash code',
	maximumwithdrawalamount: 'The maximum withdrawal amount is',
	Incorrectamountformat: 'Incorrect amount format',
	copy: 'copy',


	//tab2
	"Total Profit": "Total Profit",
	"Today's Profit": "Today's Profit",
	"What is Automated Trading?": "What is Automated Trading?",
	"Learn about earning": "Learn about earning",
	"Staking Period": "Staking Period",
	"Staking Amount": "Staking Amount",
	"Yield": "Yield",
	"Limit": "Limit",
	"Subscribe": "Subscribe",
	"Record": "Record",

	//tab3
	"Proof of Stake": "Proof of Stake",
	"ETH Today is Price": "ETH Today is Price",
	"ETH Today's Increase": "ETH Today's Increase",
	"Joint Staking": "Joint Staking",
	"Individual Staking": "Individual Staking",
	"tab3long1": "Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2": "Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1": "Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2": "By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3": "Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4": "Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction": "Introduction",
	"webviewlong5": "Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)": "Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6": 'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process": "The Staking Process",
	"webviewlong7": "To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks": "Staking Rewards and Risks",
	"webviewlong8": "By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking": "Joint Staking and Individual Staking",
	"webviewlong9": "For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion": "Conclusion",
	"webviewlong10": "Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total": "Total",

	//record
	"Account": "Account",
	"interest-bearing": "interest-bearing",
	"POS Staking": "POS Staking",
	"convert": "convert",
	"transaction": "transaction",
	"pledge": "pledge",
	"recordno": "You don't have any news yet. The list is empty.",
	"Oops": "Oops",
	"recharge": "recharge",
	"payment": "payment",
	"staking": "staking",
	"income": "income",
	"Pledge quantity": "Pledge quantity",
	"cycle": "cycle",
	"Days Remaining": "Days Remaining",
	"cumulative gain": "cumulative gain",
	"Price increase": "Price increase",
	"Price decrease": "Price decrease",
	"Opening price": "Opening price",
	"Closing price": "Closing price",
	"loss": "loss",
	"pfofit": "pfofit",
	"amount": "amount",

	//notifiCations
	"notifiCationslong1": "Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2": "The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3": "Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4": "Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5": "VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6": "VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7": "VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8": "VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9": "VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10": "VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11": "VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12": "VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13": "VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn": "Refer and Earn",
	"Copy Link": "Copy Link",

	//faq
	"Frequently Asked Questions": "Frequently Asked Questions",

	//authenticator
	"mailbox": "mailbox",
	"Front photo": "Front photo",
	"Back photo": "Back photo",
	"Handheld ID photo": "Handheld ID photo",
	"define": "define",

	//legality
	"legalitylong1": "Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2": "Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3": "1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4": "2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5": "3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6": "Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7": 'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language": "language",
	"Confirm": "Confirm",
	"Cancel":'Cancellare',

	"newtext1": "Sign out",
	"newtext2": "Cancel",
	"newtext3": "Are you sure you want to log out of this account?",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "Login",
	"newtext9": "username",
	"newtext10": "Mobile phone number",
	"newtext11": "Please enter user name",
	"newtext12": "Please enter your mobile phone number",
	"newtext13": "password",
	"newtext14": "Please enter password",
	"newtext15": "No account yet",
	"newtext16": "Register",
	"newtext17": "Country",
	"newtext18": "Select a country",
	"newtext19": "Mobile phone number verification code",
	"newtext20": "Please enter the verification code",
	"newtext21": "Email address",
	"newtext22": "Please enter your email address",
	"newtext23": "Confirm password",
	"newtext24": "Please enter the confirmation password",
	"newtext25": "Have an account",
	"newtext26": "Send verification code",
	"newtext27": "Mobile phone number is incorrect",
	"newtext28": "Sent successfully",
	"newtext29": "Registration successful",

	"newtext30": 'currency',
	"newtext31": 'Forex',
	"newtext32": 'precious metals',

	"newtext33": 'Conto',
	"newtext34": 'Attività della piattaforma',
	"newtext35": 'Servizio clienti',
	"newtext36": 'invito',
	"newtext37": 'Verifica KYC',
	"newtext38": 'impostazione',
	wallet_p: {
		text1: "Copia Indirizzo",
		text2: 'Conferma',
		text3: 'Scambia da',
		text4: "Scambia a",
		text5: "MAX",
		text6: "Invita",
		text7: 'Ottieni ricompense per referenze',
		text8: "Quando i tuoi amici partecipano all'arbitraggio AI e noleggiano macchine per il mining, puoi ottenere ricompense per referenze",
		text9: "copia",
		text10: 'Condividi Link',
		text11: "Verifica KYC",
		text12: "Paese",
		text13: "Seleziona un paese",
		text14: "Nome",
		text15: "Inserisci il tuo nome",
		text16: "Cognome",
		text17: "Inserisci il tuo cognome",
		text18: "Tipo di Certificato",
		text19: "Seleziona il Tipo di Certificato",
		text20: "Numero di Certificato",
		text21: "Inserisci il numero di certificato",
		text22: "Carica una foto della parte anteriore della tua carta d'identità",
		text23: "Carica una foto della parte posteriore della tua carta d'identità",
		text24: "Carica una foto della parte anteriore del tuo passaporto",
		text25: "Carica una foto della parte posteriore del tuo passaporto",
		text26: "Email",
		text27: "Inserisci la tua email",
		text28: "invia",
		text29: "Codice di verifica",
		text30: "Inserisci il tuo codice di verifica",
		text31: "ID",
		text32: "passaporto",
		text33: "annulla",
		text34: "Paese/Regione",
		text35: "Inserisci le informazioni corrette per l'autenticazione",
		text36: "Inviato correttamente!",
		text37: "Errore nel formato dell'email!",
		text38: "patente di guida",
		text39: "Carica una foto della parte anteriore della patente di guida",
		text40: "Carica una foto del retro della patente di guida",

		text41: "Numero di identificazione",
		text42: "Inserisci il tuo numero identificativo",
		text43: "Numero di passaporto",
		text44: "Inserisci il numero del passaporto",
		text45: "numero della patente di guida",
		text46: "Inserisci il numero della tua patente di guida",
	},
	jiaoyi: {
		text1: 'Secondo contratto',
		text2: 'Contratto perpetuo',
		text3: 'Transazione a pronti',
		text4: 'Delega immediatamente',
		text5: 'Tipo di conto',
		text6: 'tempi di consegna',
		text7: 'Fascia di prezzo',
		text8: 'Prezzo di acquisto',
		text9: 'costo',
		text10: 'Saldo',
		text11: 'almeno',
		text12: 'previsto',
		text13: 'Importo di acquisto',
		text14: 'direzione',
		text15: 'Prezzo di acquisto',
		text16: 'Prezzo in tempo reale',
		text17: 'Conto alla rovescia',
		text18: 'Il mio contratto',
		text19: 'Profitto',
		text20: 'Contratto',
		text21: 'prezzo di spedizione',
		text22: 'tempi di consegna',
		text23: 'Prezzo di mercato',
		text24: 'in aumento',
		text25: 'autunno',
		text26: 'Prezzo di acquisto',
		text27: 'massimo',
		text28: 'Profitto',
		text29: 'commissione di gestione',
		text30: 'Acquista',
		text31: 'Vendi',
		text32: 'Quantità acquistabile',
		text33: 'conto reale',
		text34: 'Conto virtuale',
		text35: 'Ancora nessun dato',
		text36: 'Posizione attuale',
		text37: 'Commissione storica',
		text38: 'Chiudi posizione',
		text39: 'Margine',
		text40: 'Profitti e perdite',
		text41: 'tieni premuto',
		text42: 'Record di acquisto',
		text43: 'Record di vendita',
		text44: 'Disponibile',
		text45: 'convertito',
		text46: 'Autenticazione riuscita',
		text47: 'Tempo di acquisto',
		text48: 'non letto',
		text49: 'Leggere',
		text50: 'Ordine dell\'host',
		text51: 'Arbitraggio totale',
		text58: 'Guadagni di oggi',
		text52: 'Come funzionano i robot di intelligenza artificiale',
		text53: 'prodotto di arbitraggio',
		text54: 'giorno',
		text55: 'Importo',
		text56: 'Reddito giornaliero',
		text57: 'Tipo di valuta',
		text59: 'Unisciti all\'arbitraggio AI',
		text60: 'Rischio zero, rendimenti rapidi',
		text61: 'Arbitraggio AI',
		text62: 'Guadagni attesi',
		text63: 'Tipo di moneta di arbitraggio',
		text64: 'Importo del deposito in garanzia',
		text65: 'Ospita ora',
		text66: 'Reddito giornaliero inviato al tuo portafoglio USDT',
		text67: 'Fondo di investimento a rischio zero',
		text68: 'Puoi ritirare i tuoi fondi in qualsiasi momento',
		text69: 'L\'intelligenza artificiale funziona 24 ore al giorno',
		text70: 'Reddito cumulativo',
		text71: 'Tempo di scadenza',
		text72: 'tutto',
		text73: 'In corso',
		text74: 'Completato',
		text75: 'Dettagli dell\'hosting',
		text76: 'Seleziona valuta',
		text77: 'Supporto tecnico',
		text78: 'Invia e-mail',
	},
	tabbar:{
		text1:'Pagina iniziale',
		text2:'Mercato',
		text3:'Quantificazione Ai',
		text4:'Prestito',
		text5:'Conto',
		text6:'Saldo del conto',
		text7:'Guadagni di oggi',
		text8:'Tasso di rendimento',
		text9:'altro',
		text10:'Prossimamente',
		text11:'Prevendita nuova moneta',
		text12:'Intraprendi un viaggio verso la ricchezza digitale',
		text13:'Acquista ora',
		text14:'Facile da usare',
		text15:'Trading quantitativo con intelligenza artificiale',
		text16:'Sicuro / stabile / semplice',
		text17:'Crea transazione',
		text18:'Commissione totale',
		text19:'Condividi questo link con i tuoi amici e ricevi fino a $200. ',
		text20:'Link consigliato',
		text21:'Condividi con i tuoi amici tramite il software social',
	},
	tab4: {
		text1: "Saldo conto",
		text2: "Guadagni di oggi",
		text3: "Quantificazione AI",
		text4: "Rendimento",
		text5: "Deposito",
		text6: "Bonifico bancario",
		text7: "Prelievo",
		text8: "Deposito",  // 注意：此条重复，可能需要确认是否应改为其他内容或删除
		text9: "Indirizzo portafoglio",
		text10: "Copia",
		text11: "Carica prova",
		text12: "Ti invitiamo a ricontrollare l'indirizzo del deposito per assicurarti che sia corretto, poiché un trasferimento errato potrebbe non essere annullato. I depositi richiedono conferma prima di essere accreditati sul tuo conto.",
		text13: "Bonifico bancario",
		text14: "Prima di effettuare il bonifico:",
		text15: "Prima di effettuare il bonifico, contatta il nostro team di assistenza clienti per ottenere informazioni accurate sull'account di bonifico. Questo passaggio garantisce la sicurezza e la ricezione senza problemi dei tuoi fondi.",
		text16: "Tempo di elaborazione del bonifico:",
		text17: "Prima di effettuare il bonifico, contatta il nostro team di assistenza clienti per ottenere informazioni accurate sull'account di bonifico. Questo passaggio garantisce la sicurezza e la ricezione senza problemi dei tuoi fondi.",
		text18: "Assistenza durante il bonifico:",
		text19: "Se durante il bonifico incontri qualsiasi problema o dubbio, non esitare a contattare il nostro team di assistenza clienti. Siamo impegnati a fornire assistenza e supporto, garantendoti un'esperienza di transazione fluida.",
		text20: "Importo",
		text21: "Valuta di prelievo",
		text22: "Conferma",
		text23: "Assicurati di confermare il tuo indirizzo di ricezione poiché i trasferimenti errati non possono essere annullati. Ci vorranno alcuni minuti affinché la conferma venga accreditata sul tuo conto.",
		text24: "Ordine opzioni",
		text25: "Ordine contratto",
		text26: "Ordine quantificazione AI",
		text27: "Cronologia",
		text28: "Invita amici",
		text29: "Domande frequenti",
		text30: "Servizio clienti in linea",
		text31: "Chi siamo",
		text32: "Cambia lingua",
		text33: "Uscita dalla sessione"
	},
	uploadProof: {
		text1: "Carica prova",
		text2: "Seleziona immagine",
		text3: "Anteprima",
		text4: "Carica la schermata del tuo deposito",
		text5: "Importo",
		text6: "Hash della transazione",
		text7: "Ultime 6 cifre",
		text8: "Carica la schermata del pagamento in criptovaluta e attendi la verifica dopo l'invio",
		text9: "Conferma"
	},
	newadd:{
		text1:'successo',
		text2:'Errore',
		text3:'Cose da fare',
		text4:'Il mio gettone della corona',
		text5:'Fornitura',
		text6:'Venduto',
		text7:'La prevendita termina',
		text8:'importo',
		text9:'Scambio',
		text10:'Caratteristiche tecniche',
		text11:'La nuova valuta utilizza la tecnologia blockchain, che offre un\'elevata velocità di transazione, basse commissioni di gestione, elevata sicurezza delle transazioni, nonché funzioni di contratto intelligente e tecnologia di protezione della privacy. ',
		text12:'Visione del progetto',
		text13:'La visione di Xincoin è quella di diventare la piattaforma di trading di asset digitali leader a livello mondiale, fornendo agli utenti servizi di trading di asset digitali più sicuri, convenienti ed efficienti. ',
		text14:'Scenario applicativo',
		text15:'La nuova valuta ha una vasta gamma di scenari applicativi, tra cui transazioni di valuta digitale, transazioni di titoli digitali, transazioni di asset digitali, regolamento dei pagamenti, finanza della catena di fornitura e altri campi. ',
		text16:'Importo del prestito',
		text17:'Verifica la tua identità e ottieni più servizi! ',
		text18:'Inizia la verifica',
		text19:'Voglio prendere in prestito...',
		text20:'Periodo di prestito',
		text21:'giorno',
		text22:'Tasso di interesse giornaliero',
		text23:'Importo totale degli interessi',
		text24:'Nessun interesse è dovuto per 1 giorno dopo la presa del prestito, gli interessi saranno pagabili successivamente. ',
		text25:'Prendilo in prestito adesso',
		text26:'Registro del prestito',
		text27:'Ancora nessun prestito',
		text28:'Impossibile trovare le informazioni sul tuo prestito',
		text29:'Supera l\'intervallo del limite del prestito',
		text30:'Applicazione',
		text31:'in corso',
		text32:'Fine',
		text33:'Apri',
		text34:'Chiudi',
		text35:'registra',
		text36:'Deposito',
		text37:'Recesso',
		text38:'Spiacenti!',
		text39:'Non hai ancora alcun record di transazione. L\'elenco è vuoto. ',
		text40:'Inserisci l\'importo',
		text41:'normale',
		text42:'Fine',
		text43:'casella di posta',
		text44:'Codice di verifica email',
		text45:'Conferma pagamento su DAPP',
		text46:'Errore di ricarica, riprova o contatta il servizio clienti',
		text47:'La richiesta di ricarica è stata inviata',
		text48:'In corso di revisione',
		text49:'Approvato',
		
		//关于我们
		text50:'HKEX è una piattaforma di servizi di scambio di valuta digitale famosa in tutto il mondo. HKEX supporta una varietà di transazioni di asset digitali. Fondata nel giugno 2019, è diventata una delle piattaforme di servizi di trading di valuta digitale più popolari.',
		text51:'Attualmente fornisce servizi one-stop come valuta, valuta legale, contratti e prestiti a oltre 1 milione di utenti in 35 paesi e regioni in tutto il mondo. È conosciuta come "la piattaforma di servizi di trading più intelligente e veloce".',
		text52:'HKEX opera a Hong Kong, fornendo agli utenti un team di assistenza clienti multilingue, 7 ore su 24, 7 ore su 24. Allo stesso tempo, HKEX ha creato agenzie localizzate in Canada, Francia, Singapore, Stati Uniti, Corea del Sud, Giappone, Italia. , Turchia, Russia, India e altri luoghi raggruppano per fornire i servizi più localizzati agli utenti di tutto il mondo. Nel gennaio 2020, HKEX ha ricevuto 150 milioni di dollari in finanziamenti di serie A dalle società canadesi KTR Capital e Kunpeng Venture Capital. Lo scambio HKEX è profondamente coinvolto nel mercato internazionale da molti anni e conta milioni di utenti in quasi 30 paesi in tutto il mondo.',
		text53:'Da maggio 2022, per entrare nel mercato cinese, il team HKEX Asia Pacific ha lavorato duramente negli ultimi mesi critici per realizzare il prodotto intelligente definitivo, che ha attirato grande attenzione nelle principali comunità cinesi. Lo scambio HKEX aderisce alla strategia di sviluppo di "opzioni + contratti" e aderisce rigorosamente alla conformità di fondo, fornendo agli utenti globali servizi finanziari di asset digitali concentrandosi sul "negoziazione di opzioni e negoziazione di contratti" e assistendo lo sviluppo della liquidità finanziaria digitale globale .',
		//关于我们 end
		
		text54:'Avviso sul contratto d\'uso',
		text55:'Dichiarazione legale',
		text56:'Regole di negoziazione OTC',
		text57:'Notifica dei rischi di ritiro dell\'operazione',
		
		text58:'Intervallo di peso',
		text59:'Intervallo di quota',
		text60:'Profitto massimo',
		text61:'tasso di rendimento previsto',
		text62:'Seleziona area di trading',
		text63:'Seleziona moneta',
		text64:'moltiplicatore di leva',
		text65:'Seleziona moltiplicatore',
		text66:'Importo pianificato',
		text67:'Inserisci l\'importo pianificato',
		text68:'Seleziona piano di livello',
		text69:'Il tuo punteggio di peso attuale è',
		text70:'Questo rapporto di leva non è stato ancora sbloccato',
		text71:'Inserisci importo',
		text72:'Seleziona moltiplicatore di leva',
		text73:'Perdita massima',
		
		text74:'Continua a fare trading',
		text75:'Stabilirsi',
		text76:'Codice di verifica Google',
		text77:'Inserisci il codice di verifica di Google',
	}
}

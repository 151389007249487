import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/pages/LayoutView.vue'
import HomeTab from '@/pages/HomeTab.vue'
import TabBar1 from '@/pages/TabBar1.vue'
import TabBar2 from '@/pages/TabBar2.vue'
import TabBar3 from '@/pages/TabBar3.vue'
import TabBar4 from '@/pages/TabBar4.vue'


const routes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/tab1',
    meta: {
        needLogin: false
    },
    children: [
	{
        path: '/homeTab',
        name: 'HomeTab',
        component: HomeTab,
        meta: {
            index: 0
        }
    },
    {
        path: '/tab1',
        name: 'tab1',
        component: TabBar1,
        meta: {
            index: 1
        }
    },
    {
        path: '/tab2',
        name: 'tab2',
        component: TabBar2,
        meta: {
            index: 2
        }
    },
    {
        path: '/tab3',
        name: 'tab3',
        component: TabBar3,
        meta: {
            index: 3
        }
    },
    {
        path: '/tab4',
        name: 'tab4',
        component: TabBar4,
        meta: {
            index: 4
        }
    },
	{
		path: '/market',
		name: 'market',
		component: () =>
			import ('@/pages/market'),
		meta:{
			needLogin:true
		}
	},
	{
		    path: '/saleCoin',
		    name: 'saleCoin',
		    component: () =>
		        import('@/pages/saleCoin'),
		    meta: {
		        needLogin: true
		    }
		},
    ]
},
{
	    path: '/pledgehistory',
	    name: 'pledgehistory',
	    component: () =>
	        import('@/pages/pledgehistory'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/buyStep',
	    name: 'buyStep',
	    component: () =>
	        import('@/pages/buyStep'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/marketInfos',
	    name: 'marketInfos',
	    component: () =>
	        import('@/pages/marketInfos'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/marketInfo',
	    name: 'marketInfo',
	    component: () =>
	        import('@/pages/marketInfo'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/wallet',
	    name: 'wallet',
	    component: () =>
	        import('@/pages/account/Wallet'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/authenticator',
	    name: 'authenticator',
	    component: () =>
	        import('@/pages/authenticator'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/notifiCations',
	    name: 'notifiCations',
	    component: () =>
	        import('@/pages/notifiCations/notifiCations'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/notifydetails',
	    name: 'notifydetails',
	    component: () =>
	        import('@/pages/notifiCations/notifydetails'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/google',
	    name: 'google',
	    component: () =>
	        import('@/pages/google'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/invite',
	    name: 'Invite',
	    component: () =>
	        import('@/pages/Invite'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/faqcontent',
	    name: 'faqcontent',
	    component: () =>
	        import('@/pages/faqcontent'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/legality',
	    name: 'Legality',
	    component: () =>
	        import('@/pages/Legality'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/faq',
	    name: 'Faq',
	    component: () =>
	        import('@/pages/Faq'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/walletDetails',
	    name: 'walletDetails',
	    component: () =>
	        import('@/pages/myWallet/walletDetails'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/language',
	    name: 'language',
	    component: () =>
	        import('@/pages/Language'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/language',
	    name: 'language',
	    component: () =>
	        import('@/pages/Language'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/recorddetails',
	    name: 'recorddetails',
	    component: () =>
	        import('@/pages/RecordDetails'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/record',
	    name: 'record',
	    component: () =>
	        import('@/pages/Record'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/web_view',
	    name: 'web_view',
	    component: () =>
	        import('@/pages/web_view'),
	    meta: {
	        needLogin: true
	    }
	},
	    {
	        path: '/verification',
	        name: 'verification',
	        component: () =>
	            import('@/pages/verification'),
	        meta: {
	            needLogin: true
	        }
	    },
	{
	    path: '/orderdetails',
	    name: 'orderdetails',
	    component: () =>
	        import('@/pages/orderdetails'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/secondorder',
	    name: 'secondorder',
	    component: () =>
	        import('@/pages/secondorder'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/contractOrder',
	    name: 'contractOrder',
	    component: () =>
	        import('@/pages/contractOrder'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/optionOrder',
	    name: 'optionOrder',
	    component: () =>
	        import('@/pages/optionOrder'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/perpetualorder',
	    name: 'perpetualorder',
	    component: () =>
	        import('@/pages/perpetualorder'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/spotorder',
	    name: 'spotorder',
	    component: () =>
	        import('@/pages/spotorder'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/account',
	    name: 'account',
	    component: () =>
	        import ('@/pages/account/Account'),
	    meta:{
	        needLogin:true
	    }
	},
		{
			path: '/about',
			name: 'about',
			component: () =>
				import ('@/pages/about'),
			meta:{
				needLogin:true
			}
		},
		{
			path: '/uploadProof',
			name: 'uploadProof',
			component: () =>
				import ('@/pages/uploadProof'),
			meta:{
				needLogin:true
			}
		},
		{
			path: '/uploadProofs',
			name: 'uploadProofs',
			component: () =>
				import ('@/pages/uploadProofs'),
			meta:{
				needLogin:true
			}
		},
{
    path: '/empty_wallet',
    name: 'empty_wallet',
    component: () =>
        import('@/pages/empty_wallet'),
    meta: {
        needLogin: false
    }
},
{
    path: '/empty_wallet_login',
    name: 'empty_wallet_login',
    component: () =>
        import('@/pages/empty_wallet_login'),
    meta: {
        needLogin: false
    }
},
{
    path: '/login',
    name: 'login',
    component: () =>
        import('@/pages/login'),
    meta: {
        needLogin: false
    }
},
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//韩语
export default {
	account: {
		Start_making_money_plan: "돈 버는 계획을 시작하세요",
		Market: "시장",
		Migital_currency: "미지탈 화폐",
		Foreign_exchange: "외환",
		Noble_metal: "귀금속",
		Send_Cryop_Now: "지금 Cryop 보내기",
		Send: "보내다",
		Receive: "받다",
		Buy: "구입하다",
		Currency_account: "통화 계정",
		Contract_account: "계약 계정",
		Transfer: "옮기다",
		Select_a_wallet: "지갑을 선택하세요",
		Select_Coin: "동전 선택",
		Confirm: "확인하다",
		Wallet: "지갑",
		Availavle: "사용 가능",
		Frozen: "겨울 왕국",
		Convert: "전환하다",
		Receiving_Address: "수신 주소",
		Amount: "양",
		Max: "맥스",
		Send_Now: "지금 보내",
		Please_check_if: "발송 전 수신 주소가 정확한지 확인하시어 자산 손실이 발생하지 않도록 해주시기 바랍니다. 출금 수수료는 다음과 같습니다.",
		new1: '암호화폐',
		new2: '색인',
		new3: '대부분',
		new4: '외환',
		new5: '총자산',
	},
	"964128-0": "일",
	"964128-1": "아!",
	"964128-2": "거래 기록이 없습니다. 목록이 비어 있습니다.",
	"652934-0": "AI 양적 트레이딩",
	"652934-1": "AI 양적 금액",
	"652934-2": "오늘의 수익",
	"652934-3": "총 수익",
	"652934-4": "자금 안전",
	"652934-5": "안정적인 수익",
	"652934-6": "간단한 운영",
	"652934-7": "더 보기",
	"652934-8": "AI 양적 생성",
	"652934-9": "기간",
	"652934-10": "금액",
	"652934-11": "수익률",
	"652934-12": "할당량",
	"652934-13": "확인",
	"652934-14": "상단 정보",
	"652934-15": "테마 모드",
	"652934-16": "테스트---",
	"314962-0": "Google 2FA",
	"314962-1": "Google Authenticator를 다운로드하여 연결하세요",
	"314962-2": "인증 코드 복사",
	"314962-3": "인증 코드 입력",
	"314962-4": "요청 실패:",
	"314962-5": "인증 코드가 복사되었습니다",
	"314962-6": "텍스트를 복사할 수 없습니다:",
	"314962-7": "복사 실패, 다시 시도해 주세요",
	"199917-0": "설정",
	"199917-1": "알림",
	"199917-2": "Google 2FA",
	"199917-3": "이메일",
	"199917-4": "언어",
	"199917-5": "버전",
	"199917-6": "로그아웃",
	home: {
		USDTAccount: 'USDT 계좌',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: '승인하다',
		ETHTodayProfit: '오늘 ETH 수익',
		totalRevenue: '총 수익',
		portfolio: '투자하다',
		reliableSecurity: '믿을 수 있는 보안 보장',
		stableReliable: '안정적이고 믿음직한 투자',
		convenienEasy: '편리하고 쉬운 조작',
		pleaseLookForward: '기대해주세요...',
		rateOfReturn: '응답률',
		walletnot: 'MetaMask 또는 Ethereum 확장 지갑을 설치하세요.',
		wallettip: '경험에 영향을 미치지 않도록 주소를 연결하십시오.',
	},
	"ConnectW": "지갑 연결",
	"dappbrowser": "이 웹사이트는 분산형 지갑 DAPP 브라우저에서의 액세스만 허용합니다.",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "이자를 벌다",
	"$": "$",
	"ETH Today's Profit": "오늘 ETH 수익",
	"Wallet": "지갑",
	"Record": "주문하다",
	"Support": "고객 서비스",
	"General": "포괄적인",
	"Notifications": "통지하다",
	"Invite Friends": "친구를 초대",
	"FAQ": "일반적인 문제",
	"Privacy & Security": "개인 정보 보호 및 보안",
	"Legality": "개인 정보 정책",
	"Authenticator": "확인됨",
	"Settings": "설정",
	"Change Language": "언어 변경",
	"Daytime mode": "주간 모드",
	"Black mode": "야간 모드",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'열리는',
    'High':'제일 높은',
    'Low':'가장 낮은',
    'Close':'닫은',
    'Up':'구매',
    'Down':'구매 또는 판매',
    'Time':'시간',
    'TransactionFee':'수수료',
    'AvailableBalance':'사용 가능한 잔액',
    'submit':'신청',
    'Direction':'방향',
    'Profit':'이익',
    'Quantity':'수량',
    'Price':'가격',
    'different':'최소 한도를 보려면 다양한 척도를 클릭하세요.',
    'lessthan':'적어도 이상',
	'lessthans': '다음보다 클 수 없습니다.',
    'least':'최소:',
    'Transactionhistory':'거래기록',
    'Transactionrecords':'거래기록',
    'Number':'수량',
    'Profitloss':'이익의 손실',
	'Ror':'반품',
	'operate':'작동하다',
	'About_us': '우리에 대해',





	//钱包
	TransactiónDetails: '상세 거래 내역',
	dealdetails: '상세 거래 내역',
	ViewTransactionHistory: '거래 내역 보기',
	Deposit: '충전하다',
	Rechargeaddress: '충전 주소:',
	DepositAmount: '입금 금액:',
	EnterDepositAmount: '입금액을 입력하세요',
	EnterHash: '해시 값을 입력하세요',
	Hash: '해시 값:',
	Voucherimage: '바우처 이미지:',
	Continue: '계속하다',
	Withdraw: '철회하다',
	Withdrawal: '검색하다:',
	EnterAmounttoWithdraw: '인출금액을 입력하세요',
	Available: "쓸 수 있는:",
	ReceiveAddress: '수신 주소:',
	EnterWalletAddress: '지갑 주소를 입력하세요',
	WithdrawalDesc: '영어로 출금하려면 1%의 처리 수수료가 필요하며 입금되기 전에 네트워크 노드의 확인이 필요합니다. 암호화폐를 낯선 사람에게 양도하지 마십시오.',
	Exchange: '교환',
	Send: '순간이동:',
	Max: '최고:',
	rechargeAmount: '충전금액을 입력해주세요',
	hashcode: '해시 값과 스크린샷은 동시에 비워둘 수 없습니다.',
	correcthashcode: '올바른 해시 값을 입력하세요.',
	maximumwithdrawalamount: '최대 인출 금액은',
	Incorrectamountformat: '금액 형식이 잘못되었습니다.',
	copy: '복사',


	//tab2
	"Total Profit": "총 이익",
	"Today's Profit": "오늘의 수익",
	"What is Automated Trading?": "자동거래란 무엇인가요?",
	"Learn about earning": "소득에 대해 알아보기",
	"Staking Period": "공약기간",
	"Staking Amount": "약속 수량",
	"Yield": "혜택",
	"Limit": "한계",
	"Subscribe": "신청",

	//tab3
	"Proof of Stake": "지분 증명",
	"ETH Today is Price": "ETH 오늘 가격",
	"ETH Today's Increase": "오늘 ETH의 상승",
	"Joint Staking": "공동서약",
	"Individual Staking": "개인 서약",
	"tab3long1": "참가자들은 스마트 계약을 통해 짝을 이루어 공동 베팅을 실현하며 총 베팅 금액은 32 ETH에 이릅니다.",
	"tab3long2": "독립적인 베팅에서는 참가자를 일치시키기 위해 스마트 계약이 필요하지 않습니다.  개인 배팅은 배팅 금액이 32ETH에 도달하면 완료될 수 있습니다.",


	//web_view
	"webviewlong1": "암호화폐 에어드랍은 신흥 블록체인 기반 프로젝트의 성장을 돕습니다.  그들의 뿌리는 보상과 공유 목표를 통해 커뮤니티 구성원을 연결합니다.  성공적인 에어드랍의 결과는 더욱 강력한 커뮤니티, 더 나은 프로젝트, 최종 사용자를 위한 보상입니다.  수년에 걸쳐 우리는 작업 증명(POW) 블록체인에서 수행되는 많은 에어드롭을 보아왔습니다.  지분 증명(POS) 이더리움 체인은 이제 수십 개의 네트워크에 걸쳐 수백 개의 프로젝트에 대한 암호화폐 에어드랍을 지원합니다.  PoS가 지배적인 합의 패러다임이 되면서 이더리움 네트워크의 에어드롭 수는 줄어들 기미를 보이지 않습니다.",
	"webviewlong2": "암호화폐를 스테이킹하면 스테이킹한 암호화폐의 양에 따라 귀중한 에어드랍 보상을 받을 수 있습니다.",
	"webviewlong3": "보상 지분은 지분 보유자에게만 제공되며 수백 개의 다양한 프로젝트 에어드롭에서 나오며 단일 독립 에어드롭보다 수익률이 훨씬 높습니다.",
	"webviewlong4": "거래소에 입금하는 암호화폐 소유자에게는 에어드랍 보상이 어려운 경우가 많으며, 일부 관리인은 에어드랍을 전혀 지원하지 않습니다.  이것이 바로 자신의 암호화폐를 관리하는 것이 중요한 이유입니다.  이더리움은 비수탁 실체이며 모든 주요 네트워크에 스테이킹될 수 있습니다.",
	"Introduction": "소개하다",
	"webviewlong5": "대표적인 블록체인 플랫폼인 이더리움은 이더리움 2.0 업그레이드를 통해 작업 증명(PoW) 합의 메커니즘에서 지분 증명(PoS) 메커니즘으로 전환하고 있습니다. PoS 스테이킹은 이러한 변화의 중요한 부분으로, 사용자에게 잠재적으로 보상을 획득하면서 네트워크 보안 및 운영을 지원할 수 있는 기회를 제공합니다.  이 글은 이더리움 PoS 스테이킹과 그 이점에 대한 개요를 제공합니다.",
	"Ethereum 2.0 and Proof of Stake (PoS)": "이더리움 2.0과 지분증명(PoS)",
	"webviewlong6": 'Ethereum 2.0은 확장성, 보안 및 탄력성을 향상하도록 설계된 주요 네트워크 업그레이드입니다.  주요 변화 중 하나는 에너지 집약적인 PoW 합의 메커니즘에서 보다 환경 친화적인 PoS 메커니즘으로의 전환입니다.  PoS에서는 검증인이 선택되어 새로운 블록을 생성하고 자신이 보유하고 있는 암호화폐의 양을 기반으로 거래를 확인하며 담보로 "스테이킹"할 의향이 있습니다.',
	"The Staking Process": "서약 절차",
	"webviewlong7": "이더리움 PoS 스테이킹에 참여하려면 사용자는 이더리움 2.0 예금 계약에 예치하여 최소 32 ETH를 스테이킹해야 합니다.  일단 스테이킹된 ETH는 일정 기간 동안 잠겨 있으며 네트워크 보안을 보장하기 위한 담보 역할을 합니다.  검증인은 스테이킹된 ETH 금액과 온라인 활동을 기준으로 선택됩니다.  그들은 새로운 블록을 제안하고 검증하는 것뿐만 아니라 네트워크에서 거래를 확인하는 일을 담당합니다.",
	"Staking Rewards and Risks": "스테이킹 보상 및 위험",
	"webviewlong8": "PoS 스테이킹에 참여함으로써 사용자는 새로 발행된 ETH와 거래 수수료 형태로 보상을 받을 수 있습니다.  보상은 스테이킹된 ETH의 양과 전체 네트워크 활동을 기반으로 합니다.  그러나 스테이킹에는 검증인이 악의적으로 행동하거나 지속적으로 온라인 상태를 유지하지 못할 경우 처벌을 받을 수 있는 등의 위험이 따릅니다.  또한 스테이킹된 ETH는 일정 기간 동안 잠겨 있어 비유동성이고 가격 변동에 취약합니다.",
	"Joint Staking and Individual Staking": "공동서약과 개별서약",
	"webviewlong9": "개별적으로 스테이킹할 만큼 32 ETH가 부족한 사용자에게는 공동 스테이킹이 또 다른 옵션을 제공합니다.  공동 스테이킹에서는 여러 사용자가 ETH를 함께 모아 필요한 스테이킹 보증금에 도달합니다.  이러한 풀링은 스마트 계약이나 스테이킹 서비스를 통해 촉진되는 경우가 많으므로 사용자는 더 적은 양의 ETH로 이더리움 PoS 스테이킹에 참여할 수 있습니다.",
	"Conclusion": "결론적으로",
	"webviewlong10": "이더리움 PoS 스테이킹은 이더리움 2.0 업그레이드의 중요한 측면으로, 사용자에게 네트워크 보안을 지원하고 분산화를 개선하며 보상을 받을 수 있는 기회를 제공합니다.  스테이킹 프로세스, 보상 및 위험을 이해함으로써 사용자는 이더리움 네트워크의 중요한 발전에 참여하는 것에 대해 정보에 입각한 결정을 내릴 수 있습니다.",


	//mywallet
	"Total": "일시금",

	//record
	"Account": "계정",
	"interest-bearing": "관심",
	"POS Staking": "POS 서약",
	"convert": "교환",
	"transaction": "거래",
	"pledge": "저당",
	"recordno": "당신은 아직 아무것도 듣지 못했습니다.  목록이 비어 있습니다.",
	"Oops": "이런",
	"recharge": "충전하다",
	"payment": "지불하다",
	"staking": "약속",
	"income": "소득",
	"Pledge quantity": "약속 수량",
	"cycle": "주기",
	"Days Remaining": "남은 일수",
	"cumulative gain": "누적 이득",
	"Price increase": "가격 인상",
	"Price decrease": "가격 하락",
	"Opening price": "개장 가격",
	"Closing price": "종가",
	"loss": "손실",
	"pfofit": "이익",
	"amount": "수량",

	//notifiCations
	"notifiCationslong1": "참고: VIP 등록은 해당 전체 계정의 저장된 가치에 따라 레벨을 결정합니다.  이 범위에서는 기존 계좌에서 탈퇴 후 총 보유금액을 차감하여 회원등급을 결정합니다.",
	"notifiCationslong2": "회원가입 기간도 등급에 따라 구분됩니다.  멤버십을 유지하려면 지정된 시간 내에 매번 최소 5,000 USDT를 충전해야 멤버십 혜택을 누릴 수 있습니다.  멤버십을 충전할 때마다 주기가 ​​다시 계산되어 누적될 수 있습니다.",
	"notifiCationslong3": "고객 서비스는 회원 등급에 따라 구분됩니다.  공식 고객 서비스는 회원들에게 통일된 서비스를 제공하기 위해 APP 직원이 제공합니다.  더 나은 서비스 제공을 위해 전담 비서의 수를 제한하고 있습니다.  전담 개인 비서가 사용자에게 전담 리셉션 서비스와 재무 관리 조언을 제공합니다.",
	"notifiCationslong4": "충전 예약을 위해 사전에 고객센터에 문의하세요.  지정된 시간 내에 충전을 완료하시면, 해당 멤버십 등급에 따라 추가 예약 충전 보상을 받으실 수 있습니다.",
	"notifiCationslong5": "VIP1: UID 계정 기준으로 누적 금액이 10,000 USD일 경우 177 USDT 보상을 받을 수 있습니다.  VIP 레벨에 도달하고 일반 회원 메달을 획득한 후 포인트가 계정에 에어드롭됩니다.",
	"notifiCationslong6": "VIP2: UID 계정을 기준으로 누적 저장 가치가 30,000 USD에 도달하면 777 USDT를 받게 됩니다.  보너스 금액은 VIP 레벨에 도달하고 브론즈 멤버십 배지를 받은 후 즉시 계정에 입금됩니다.",
	"notifiCationslong7": "VIP3: UID 계정에 따르면 누적 금액이 70,000 US 달러인 경우 1,777 USDT를 받게 됩니다.  VIP 레벨에 도달하고 실버 회원 메달을 받은 후 포인트가 계정에 에어드롭됩니다.  또한 다음 예약 금액에 대해 추가로 1.5% 보너스를 받으실 수 있습니다.",
	"notifiCationslong8": "VIP4: UID 계정에 따르면 누적 저장 가치는 150,000 US 달러이며 2,777 USDT가 지급됩니다.  VIP 레벨에 도달하고 골드 회원 메달을 받은 후 포인트가 계정에 에어드롭됩니다.  또한 다음 예약 시 충전 금액에 대해 추가로 2% 보너스를 받으실 수 있습니다.",
	"notifiCationslong9": "VIP5: UID 계정을 기준으로 누적 저장 가치가 300,000 USD에 도달하면 4777 USDT를 선물로 제공합니다.  VIP 레벨에 도달하고 다이아몬드 VIP 배지를 받은 후 포인트가 계정에 에어드랍됩니다.  또한 다음 예약 시 저장된 금액에 대해 추가로 2.5% 보너스를 받으실 수 있습니다.",
	"notifiCationslong10": "VIP6: UID 계정을 기준으로 누적 저장 가치는 800,000 USD에 도달하고 16,888 USDT를 선물로 제공합니다.  보너스 금액은 VIP 레벨 달성 및 블랙다이아몬드 멤버십 뱃지 획득 후 즉시 계정으로 에어드랍되며, 다음 사전등록 충전금액의 3%를 추가로 적립해 드립니다.  재정적 조언을 제공하거나 세금 문제를 처리하는 전담 개인 비서를 갖게 됩니다.",
	"notifiCationslong11": "VIP7: UID 계정 기준, 누적 보관 금액이 US$3백만에 도달하면 15일간의 유럽 럭셔리 여행(할인 포함)을 제공합니다.  코인비포스(CoinBpos) 글로벌 평생 명예 VIP상을 수여하고 크라운 멤버십 메달을 받았습니다.  다음 예약 충전시 3.5% 추가 보너스를 받으실 수 있습니다.",
	"notifiCationslong12": "VIP8: UID 계정에 따르면 누적 저장 금액이 미화 800만 달러에 도달하면 CoinBpos 모로코 연간 연회 상품권을 받을 수 있습니다.  보상: 1 BTC 및 CoinBpos 글로벌 평생 명예 VIP를 받으세요.  다음 예약 충전 시 추가 4% 보너스를 받으실 수 있습니다.",
	"notifiCationslong13": "VIP9: UID 계정 기준으로 총 3천만 달러를 입금하면 CoinBpos 지분 3%를 획득할 수 있습니다.  그리고 연간 3%의 이익 배당금을 누리세요.  무료 Cronix 순금 기념 핀.",

	//invite
	"Refer and Earn": "추천하고 적립하세요",
	"Copy Link": "링크 복사",

	//faq
	"Frequently Asked Questions": "자주 묻는 질문",

	//authenticator
	"mailbox": "우편",
	"Front photo": "초상화 사진",
	"Back photo": "뒷면 사진",
	"Handheld ID photo": "증명사진을 들고 있는 모습",
	"define": "확신하는",

	//legality
	"legalitylong1": "Coinbpos는 암호화폐 커뮤니티가 커뮤니티 안정성을 생성, 성장 및 유지하는 데 도움이 되는 도구를 함께 제공하고 수천 개의 분산형 애플리케이션을 지원하며 암호화폐의 미래를 주도하고 전통 통화의 디지털화 및 글로벌 경제 촉진을 촉진하는 여러 암호화폐 회사로 구성됩니다. 번영.",
	"legalitylong2": "Coinbpos 내의 각 회사는 서비스와 관련하여 개인 데이터 처리를 위한 데이터 컨트롤러 역할을 하며, 각 서비스의 데이터 컨트롤러는 다음과 같습니다.",
	"legalitylong3": "1. 블록체인 기술 - 이더리움 블록체인.",
	"legalitylong4": "2. 탈중앙화 지갑 - 트러스트 지갑, 메타마스크, 암호화폐 지갑.",
	"legalitylong5": "3. 거래 기술 - Coinbase, Crypto, linch, Binance.",
	"legalitylong6": "개인 정보 보호 정책은 유럽 연합의 일반 데이터 보호 규정(GDPR)을 포함한 관련 데이터 보호법에 따른 의무에 따라 Coinbpos가 개인 데이터를 처리하는 방법을 자세히 설명합니다.",
	"legalitylong7": '캘리포니아 소비자 개인 정보 보호법(CCPA) 및 기타 일반 법률(통칭하여 "데이터 보호법"*).',

	//language
	"language": "언어",
	"Confirm": "확인하다",
	"Cancel":'취소',

	"newtext1": "그만두다",
	"newtext2": "취소",
	"newtext3": "정말로 이 계정에서 로그아웃하시겠습니까?",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "로그인",
	"newtext9": "사용자 이름",
	"newtext10": "전화 번호",
	"newtext11": "사용자 이름을 입력하세요",
	"newtext12": "전화번호를 입력해주세요",
	"newtext13": "비밀번호",
	"newtext14": "비밀번호를 입력 해주세요",
	"newtext15": "아직 계정이 없습니다",
	"newtext16": "등록하다",
	"newtext17": "국가",
	"newtext18": "국가를 선택하세요",
	"newtext19": "휴대폰번호 인증코드",
	"newtext20": "인증번호를 입력해주세요",
	"newtext21": "이메일 주소",
	"newtext22": "이메일 주소를 입력해주세요",
	"newtext23": "비밀번호 확인",
	"newtext24": "확인 비밀번호를 입력해주세요",
	"newtext25": "계정을 갖고있다",
	"newtext26": "인증 코드 보내기",
	"newtext27": "휴대폰 번호가 올바르지 않습니다.",
	"newtext28": "성공적으로 보냈습니다",
	"newtext29": "등록 성공",

	"newtext30": '통화',
	"newtext31": '외환',
	"newtext32": '귀금속',

	"newtext33": '계정',
	"newtext34": '플랫폼 활동',
	"newtext35": '고객 서비스',
	"newtext36": '초대',
	"newtext37": 'KYC 인증',
	"newtext38": '설정',
	wallet_p: {
		text1: "주소 복사",
		text2: '확인',
		text3: '교환 시작',
		text4: "교환 대상",
		text5: "최대",
		text6: "초대하기",
		text7: '추천 보상 받기',
		text8: "당신의 친구들이 AI 중재에 참여하고 마이닝 머신을 대여할 때, 당신은 추천 보상을 받을 수 있습니다",
		text9: "복사",
		text10: '링크 공유',
		text11: "KYC 인증",
		text12: "국가",
		text13: "국가를 선택하세요",
		text14: "이름",
		text15: "당신의 이름을 입력하세요",
		text16: "성",
		text17: "당신의 성을 입력하세요",
		text18: "증명서 유형",
		text19: "증명서 유형을 선택하세요",
		text20: "증명서 번호",
		text21: "증명서 번호를 입력하세요",
		text22: "당신의 신분증의 전면 사진을 업로드하세요",
		text23: "당신의 신분증의 뒷면 사진을 업로드하세요",
		text24: "당신의 여권의 전면 사진을 업로드하세요",
		text25: "당신의 여권의 뒷면 사진을 업로드하세요",
		text26: "이메일",
		text27: "당신의 이메일을 입력하세요",
		text28: "전송",
		text29: "인증 코드",
		text30: "당신의 인증 코드를 입력하세요",
		text31: "신분증",
		text32: "여권",
		text33: "취소",
		text34: "국가/지역",
		text35: "인증을 위해 올바른 정보를 입력하세요",
		text36: "성공적으로 보냈습니다!",
		text37: "이메일 형식 오류!",
		text38: "운전면허증",
		text39: "운전면허증 앞면 사진을 업로드해주세요.",
		text40: "운전면허증 뒷면 사진을 업로드해주세요.",

		text41: "ID 번호",
		text42: "귀하의 ID 번호를 입력해주세요",
		text43: "여권번호",
		text44: "여권번호를 입력해주세요",
		text45: "운전면허증 번호",
		text46: "운전면허번호를 입력해주세요",
	},
	jiaoyi: {
		text1: '두 번째 계약',
		text2: '영구 계약',
		text3: '현물 거래',
		text4: '즉시 위임',
		text5: '계정 유형',
		text6: '배달 시간',
		text7: '가격 범위',
		text8: '구매 가격',
		text9: '비용',
		text10: '균형',
		text11: '적어도',
		text12: '예상',
		text13: '구매 금액',
		text14: '방향',
		text15: '구매 가격',
		text16: '실시간 가격',
		text17: '카운트다운',
		text18: '내 계약서',
		text19: '이익',
		text20: '계약',
		text21: '배송비',
		text22: '배달 시간',
		text23: '시장 가격',
		text24: '상승',
		text25: '가을',
		text26: '구매 가격',
		text27: '최대',
		text28: '이익',
		text29: '취급 수수료',
		text30: '구매',
		text31: '판매',
		text32: '구매 수량',
		text33: '실제 계정',
		text34: '가상계좌',
		text35: '아직 데이터가 없습니다',
		text36: '현재 위치',
		text37: '역사적 위원회',
		text38: '포지션 닫기',
		text39: '여백',
		text40: '손익',
		text41: '보류',
		text42: '구매 기록',
		text43: '판매 기록',
		text44: '사용 가능',
		text45: '변환됨',
		text46: '인증 성공',
		text47: '구매시간',
		text48: '읽히지 않는',
		text49: '읽다',
		text50: '호스트 순서',
		text51: '전체 차익거래',
		text58: '오늘의 수입',
		text52: '인공지능 로봇이 작동하는 방식',
		text53: '차익거래 상품',
		text54: '일',
		text55: '금액',
		text56: '일일 수입',
		text57: '통화 유형',
		text59: 'AI 차익거래에 참여하세요',
		text60: '위험 없음, 빠른 수익',
		text61: 'AI 차익거래',
		text62: '예상 수입',
		text63: '차익거래 코인 유형',
		text64: '에스크로 금액',
		text65: '지금 호스트하세요',
		text66: 'USDT 지갑으로 일일 수입이 전송됩니다',
		text67: '투자 펀드 제로 리스크',
		text68: '언제든지 자금을 인출할 수 있습니다.',
		text69: '인공지능은 하루 24시간 작동합니다',
		text70: '누적 수입',
		text71: '만료 시간',
		text72: '모두',
		text73: '진행 중',
		text74: '완료',
		text75: '호스팅 세부정보',
		text76: '통화 선택',
		text77: '기술지원',
		text78: '이메일 보내기',
	},
	tabbar:{
		text1:'홈페이지',
		text2:'시장',
		text3:'Ai 정량화',
		text4:'대출',
		text5:'계정',
		text6:'계정 잔액',
		text7:'오늘의 수입',
		text8:'반품률',
		text9:'더 보기',
		text10:'곧 출시 예정',
		text11:'신규 코인 사전 판매',
		text12:'디지털 부의 여정을 시작하세요',
		text13:'지금 구매',
		text14:'조작하기 쉽습니다',
		text15:'AI 퀀트 트레이딩',
		text16:'안전함/안정적/간단함',
		text17:'거래 생성',
		text18:'총 커미션',
		text19:'이 링크를 친구들과 공유하고 최대 $200를 받으세요. ',
		text20:'추천 링크',
		text21:'소셜 소프트웨어를 통해 친구들과 공유하세요',
	},
	tab4: {
		text1: "계좌 잔액",
		text2: "오늘의 수익",
		text3: "AI 양화",
		text4: "수익률",
		text5: "입금",
		text6: "전자송금",
		text7: "출금",
		text8: "입금",  // 注意：此条重复，可能需要确认是否应改为其他内容或删除
		text9: "지갑 주소",
		text10: "복사",
		text11: "증거 자료 업로드",
		text12: "입금 주소가 정확하지 않은 경우에는 취소가 불가능하오니, 입금 주소가 맞는지 다시 한 번 확인해 주시기 바랍니다. 예금은 귀하의 계좌에 입금되기 전에 확인이 필요합니다.",
		text13: "전자송금",
		text14: "전자송금 전:",
		text15: "전자송금 전에, 귀하의 자금의 안전과 원활한 수금을 보장하기 위해 고객 서비스 팀에 연락하여 정확한 전자송금 계좌 정보를 받아주세요.",
		text16: "전자송금 처리 시간:",
		text17: "전자송금 전에, 귀하의 자금의 안전과 원활한 수금을 보장하기 위해 고객 서비스 팀에 연락하여 정확한 전자송금 계좌 정보를 받아주세요.",
		text18: "전자송금 과정의 지원:",
		text19: "전자송금 과정에서 문제나 의문 사항이 있으면 언제든지 고객 서비스 팀에 연락해 주세요. 우리는 원활한 거래 경험을 제공하기 위해 지원과 도움을 제공해 드리겠습니다.",
		text20: "금액",
		text21: "출금 통화",
		text22: "확인",
		text23: "잘못된 이체는 되돌릴 수 없으므로 반드시 수취인 주소를 확인하시기 바랍니다. 확인이 귀하의 계정에 반영되는 데 몇 분 정도 걸립니다.",
		text24: "옵션 주문",
		text25: "계약 주문",
		text26: "AI 양화 주문",
		text27: "이력",
		text28: "친구 초대",
		text29: "FAQ",
		text30: "온라인 고객 서비스",
		text31: "회사 소개",
		text32: "언어 변경",
		text33: "로그아웃"
	},
	uploadProof: {
		text1: "증거 자료 업로드",
		text2: "이미지 선택",
		text3: "미리보기",
		text4: "입금 스크린샷 업로드",
		text5: "금액",
		text6: "거래 해시",
		text7: "마지막 6자리 숫자",
		text8: "암호화폐 지불 스크린샷을 업로드하고 제출 후 심사를 기다리세요",
		text9: "확인"
	},
	newadd:{
		text1:'성공',
		text2:'실패',
		text3:'할 일',
		text4:'내 왕관 토큰',
		text5:'공급',
		text6:'판매됨',
		text7:'사전 판매 종료',
		text8:'금액',
		text9:'교환',
		text10:'기술적 기능',
		text11:'새 통화는 빠른 거래 속도, 낮은 처리 수수료, 높은 거래 보안은 물론 스마트 계약 기능과 개인 정보 보호 기술을 갖춘 블록체인 기술을 사용합니다. ',
		text12:'프로젝트 비전',
		text13:'Xincoin의 비전은 세계 최고의 디지털 자산 거래 플랫폼이 되어 사용자에게 보다 안전하고 편리하며 효율적인 디지털 자산 거래 서비스를 제공하는 것입니다. ',
		text14:'응용 시나리오',
		text15:'새 화폐에는 디지털 화폐 거래, 디지털 증권 거래, 디지털 자산 거래, 지불 결제, 공급망 금융 및 기타 분야를 포함하여 광범위한 응용 시나리오가 있습니다. ',
		text16:'대출 금액',
		text17:'본인을 인증하고 더 많은 서비스를 받아보세요! ',
		text18:'확인 시작',
		text19:'빌리고 싶어요...',
		text20:'대출 기간',
		text21:'일',
		text22:'일일 이자율',
		text23:'총 이자 금액',
		text24:'대출을 받은 후 1일 동안은 이자가 지급되지 않으며 그 이후에는 이자가 지급됩니다. ',
		text25:'지금 대여',
		text26:'대출 기록',
		text27:'아직 대출이 없습니다',
		text28:'대출 정보를 찾을 수 없습니다',
		text29:'대출 한도 범위 초과',
		text30:'적용 중',
		text31:'진행 중',
		text32:'종료',
		text33:'열기',
		text34:'닫기',
		text35:'기록',
		text36:'입금',
		text37:'철회',
		text38:'앗!',
		text39:'아직 거래기록이 없습니다. 목록이 비어 있습니다. ',
		text40:'금액을 입력해주세요',
		text41:'정상',
		text42:'종료',
		text43:'사서함',
		text44:'이메일 확인 코드',
		text45:'DAPP 결제 확인',
		text46:'충전 오류입니다. 다시 시도하거나 고객 서비스에 문의하세요.',
		text47:'충전 신청이 제출되었습니다',
		text48:'검토중',
		text49:'승인됨',
		
		//关于我们
		text50:'HKEX는 세계적으로 유명한 디지털 화폐 거래 서비스 플랫폼으로, 다양한 디지털 자산 거래를 지원합니다. 2019년 6월에 설립된 이 회사는 가장 인기 있는 디지털 통화 거래 서비스 플랫폼 중 하나로 성장했습니다.',
		text51:'현재 전 세계 35개 국가 및 지역의 100만 명 이상의 사용자에게 화폐, 법정화폐, 계약, 대출 등 원스톱 서비스를 제공하고 있다. "가장 지능적이고 빠른 거래 서비스 플랫폼"으로 알려져 있습니다.',
		text52:'HKEX는 홍콩에서 운영되며 사용자에게 다국어 7×24시간 고객 서비스 팀을 제공하는 동시에 캐나다, 프랑스, 싱가포르, 미국, 한국, 일본, 이탈리아에 현지 대리점을 설립했습니다. , 터키, 러시아, 인도 및 기타 지역 그룹을 통해 전 세계 사용자에게 가장 현지화된 서비스를 제공합니다. 2020년 1월, HKEX는 캐나다의 KTR Capital과 Kunpeng Venture Capital로부터 시리즈 A 자금 조달로 1억 5천만 달러를 받았습니다. HKEX 거래소는 수년 동안 국제 시장에 깊이 관여해 왔으며 전 세계 거의 30개국에 수백만 명의 사용자를 보유하고 있습니다.',
		text53:'2022년 5월부터 중국 시장에 진출하기 위해 HKEX 아시아 태평양 팀은 지난 몇 달 동안 중요한 몇 달 동안 열심히 노력하여 최고의 스마트 제품을 만들었으며 이는 중국 주요 커뮤니티에서 큰 관심을 끌었습니다. HKEX 거래소는 "옵션 + 계약" 개발 전략을 고수하고 규정 준수의 최종 기준을 엄격히 준수하여 글로벌 사용자에게 "옵션 거래 및 계약 거래"에 중점을 둔 디지털 자산 금융 서비스를 제공하고 글로벌 디지털 금융 유동성 개발을 지원합니다. .',
		//关于我们 end
		
		text54:'사용자 계약 공지',
		text55:'법적 진술',
		text56:'OTC 거래 규칙',
		text57:'운영 철수 위험 알림',
		
		text58:'가중치 범위',
		text59:'할당량 범위',
		text60:'최대 수익',
		text61:'예상 수익률',
		text62:'거래 지역 선택',
		text63:'코인 선택',
		text64:'레버리지 배수',
		text65:'배수 선택',
		text66:'계획 금액',
		text67:'계획 금액을 입력하세요',
		text68:'레벨 플랜 선택',
		text69:'현재 가중치 점수는',
		text70:'이 레버리지 비율은 아직 잠금 해제되지 않았습니다',
		text71:'금액을 입력하세요',
		text72:'레버리지 배수를 선택하세요',
		text73:'최대 손실',
		
		text74:'계속 거래하세요',
		text75:'고정',
		text76:'Google 인증 코드',
		text77:'Google 인증 코드를 입력하세요',
	}

}

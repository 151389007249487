<template>
  <div class="container" :style="isBlack=='false' ? 'background-color: #ffffff;color:#333333;' : ''">
    <img class="garden" width="48" height="48" :src="item.image">
    <div class="name-container">
      <div class="name" :style="isBlack=='false' ? 'color:#333333;' : ''">{{item.name}}</div>
      <div class="type" :style="isBlack=='false' ? 'color:#dedede;' : ''">{{item.type}}</div>
    </div>
    <img v-show="false" v-if="item.trend === 'up'" width="60" height="30" src="~assets/home/icon_trend_up.png">
    <img v-show="false" v-else width="60" height="30" src="~assets/home/icon_trend_down.png">
	<div :id="'myChart' + item.name" :style="{ width: '90px', height: '70px'}"></div>
    <div class="data-container">
      <div class="data-text">${{item.data}}</div>
      <div class="trend-container">
        <img v-if="item.trend === 'up'" width="12" height="12" src="~assets/home/icon_up.png">
        <img v-else width="12" height="12" src="~assets/home/icon_down.png">
        <div class="trend-data">{{item.trendData}}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps,onMounted} from "vue";
import * as echarts from 'echarts'

const props = defineProps({
  item: {
    type :Object,
    default: ()=>({
      image: '',
      name: '',
      type: '',
      trend: '',
      data: '',
      trendData: '',
	  echarstData:{}
    })
  }
})
const isBlack = localStorage.getItem('isBlack') ? localStorage.getItem('isBlack') : 'true'
async function doecharts(){
	const propsarray = JSON.parse(JSON.stringify(props))
	let itemname = propsarray.item.name
	const echarstData = propsarray.item.echarstData
	let trend = propsarray.item.trend
	let colors = '#5fc88f'
	if(trend==='up'){
		colors = '#ff6464'
	}
	let myChart = echarts.init(document.getElementById("myChart" + itemname))
	
	
	// 绘制图表
	let option = {
	  xAxis: {
	    data: echarstData.x,
	    show: false
	  },
	  yAxis: {
	    show: false,
	    splitLine: {
	      show: false
	    },
	    axisLine: {
	      show: false
	    },
		min:function(value){
			return value.min;
		}
	  },
	  tooltip: {
	    show: false
	  },
	  series: [{
	    type: 'line',//折线
	    symbolSize: 0,
	    smooth: false,//是否曲线显示
	    data: echarstData.y,
	    lineStyle: {//线条的样式
	      width: 2,
	      color: colors
	    },
	    areaStyle: {
	      color: {
	        type: 'linear',
	        x: 0,
	        y: 0,
	        x2: 0,
	        y2: 1,
			colorStops: [{
			    offset: 0,
			    color: colors // 0% 处的颜色
			}, {
			    offset: 1,
			    color: 'rgba(6,37,55,0)' // 100% 处的颜色
			}],
	        global: false // 缺省为 false
	      }
	    }
	  }]
	}
	
	myChart.setOption(option);
	
}

onMounted(async ()=>{
	doecharts()
})


</script>

<style scoped lang="scss">
.container{
  color: #FFFFFF;
  display: flex;
  align-items: center;
  margin: 24px 0;
  .garden{
	  border-radius: 50%;
  }
  .name-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    /*margin-right: 24px;*/
    .name{
      color: #FFFFFF;
      font-size: 12px;
      font-weight: bold;
    }
    .type{
      color: rgba(255, 255, 255, .5);
      font-size: 10px;
    }
  }
  .data-container{
	flex: 1;
    margin-left: 34px;
    .data-text{
		text-align: right;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: bold;
    }
    .trend-container{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .trend-data{
        margin-left: 7px;
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <router-view></router-view>
</template>

<script setup>
	window.onload = function() {
	    document.addEventListener('touchstart', function(e) {
	      //console.log("1",e.touches.length)
	      if (e.touches.length > 1) {
	        e.preventDefault()
	      }
	    })
	    document.addEventListener('gesturestart', function(e) {
	      //console.log("2")
	      e.preventDefault()
	    })
	  }
</script>

<style>
  /*去掉底部导航栏顶部白边*/
  .van-hairline--top-bottom:after{
    border: unset !important;
  }
   .el-popper{
    background: rgba(0, 0, 0, 0.8) !important;
     z-index: 999999999999 !important;
  }
  .el-select-dropdown {
    color: var(--el-color-primary);
    font-weight: 700;
    background: rgba(0, 0, 0, 0.8) !important;
    z-index: 99999999999 !important;
  }
  .el-select-dropdown__item.selected {
    color: var(--el-color-primary);
    font-weight: 700;
    background: rgba(0, 0, 0, 0.8) !important;
    z-index: 99999999999 !important;
  }
  .container .router-view{
	position: relative !important;
	height: 100% !important;
  }
</style>

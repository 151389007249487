<template>
	
	<van-popup
	  v-model:show="showLeft"
	  position="left"
	  class="leftmain"
	  :style="{ width: '70%', height: '100%', color:'#ffffff', background: 'rgba(13,14,20,0.9)',border:'border: 1px solid #212429'  }"
	>
		
		<div class="topname">
			RobinHood OTC
		</div>
		<div class="topuid">
			UID:{{userid}}
		</div>
		<div style="height: 20px;border-bottom: 1px solid rgba(255, 255, 255, 0.1);"></div>
		<div class="caidan" @click="gotourl(1)">
			<img class="caidanimg" src="../../static/new/account_1.png" />
			<span class="caidantext">{{$t('newtext33')}}</span>
		</div>
		<div class="caidan" @click="gotourl(2)">
			<img class="caidanimg" src="../../static/new/defi_2.png" />
			<span class="caidantext">DeFi</span>
		</div>
		<div class="caidan" @click="gotourl(3)" v-if="false">
			<img class="caidanimg" src="../../static/new/yaoqing_3.png" />
			<span class="caidantext">{{$t('newtext34')}}</span>
		</div>
		<div class="caidan" @click="gotourl(4)">
			<img class="caidanimg" src="../../static/new/diannao_4.png" />
			<span class="caidantext">
				{{$t('jiaoyi.text77')}}
				<div v-if="usermsgstatus==1" class="hongdian"></div>
			</span>
		</div>
		<div class="caidan" @click="gotourl(5)">
			<img class="caidanimg" src="../../static/new/yonghu_5.png" />
			<span class="caidantext">{{$t('newtext36')}}</span>
		</div>
		<div class="caidan" @click="gotourl(6)">
			<img class="caidanimg" src="../../static/new/dun_6.png" />
			<span class="caidantext" v-if="renzhengstatus==2">
				{{$t('newtext37')}}
				<img src="../../static/new/kycok.png" style="width: 12px; height: 12px;" />
			</span>
			<span class="caidantext" v-else style="color: rgba(255, 255, 255, 0.7);">
				{{$t('newtext37')}}
			</span>
		</div>
		<div class="caidan" @click="gotourl(7)">
			<img class="caidanimg" src="../../static/new/shezhi_7.png" />
			<span class="caidantext">{{$t('newtext38')}}</span>
		</div>
	</van-popup>
	
	<van-dialog v-model:show="kefushow" :style="{ color:'#ffffff', background: 'rgba(13,14,20,1)',border:'border: 1px solid #212429'  }" :close-on-click-overlay="true" :show-cancel-button="false" :show-confirm-button="false">
	  <div style="padding: 40px 20px;">
		<div @click="gotourl(29)" style="display: flex;align-items: center;justify-content: left;border-bottom: 1px solid rgba(255, 255, 255, 0.5);padding-bottom: 20px;">
			<img src="../../static/new/kefu.png" style="width: 25px;height:25px;" />
			<span style="margin-left: 10px;">{{$t('newtext35')}}<div v-if="usermsgstatus==1" style="margin-left: 5px;" class="hongdian"></div></span>
		</div>
		
		<div @click="gotourl(29)" style="display: flex;align-items: center;justify-content: left;margin-top: 20px;margin-bottom: 5px;">
			<img src="../../static/new/youjian.png" style="width: 25px;height:25px;" />
			<span style="margin-left: 10px;">{{$t('jiaoyi.text78')}}<div v-if="usermsgstatus==1" style="margin-left: 5px;" class="hongdian"></div></span>
		</div>
		<div style="display: flex;align-items: center;justify-content: space-between;">
			<span>{{youxiangdizhi}}</span>
			<img @click="copys" src="../../static/new/copy.png" style="width: 25px;height: 25px;" />
		</div>
	  </div>
	</van-dialog>
</template>

<script>
	
	import {
	  toRefs,
	  reactive,
	  onMounted,
	  getCurrentInstance,
	} from 'vue'
	import {
	    useRouter
	} from 'vue-router'
	import {
	    showToast
	} from 'vant';
	import {
	    initIndex,getmasgstatus,upmsgstatus
	} from '@/api/tab4'
	import {
	    userkycstatus
	} from '@/api/tab5'
	import {
	  useI18n
	} from "vue-i18n";
	
	export default {
		setup() {
			let router = useRouter();
			const {
			  t
			} = useI18n()
			const { proxy }  = getCurrentInstance()
			// 数据
			const State = reactive({
				showLeft:false,
				kefushow:false,
				userid:0,
				usermsgstatus:0,//0:已读 1未读
				youxiangdizhi:'abcdd@gmail.com',
				renzhengstatus:0,
			});
			// 方法
			const Function = reactive({
				gotourl(type){
					if(type==1){
						router.push({
						    path: "/account"
						});
					} else if(type==2){
						router.push({
						    path: "/tab2"
						});
					} else if(type==3){
						
					} else if(type==4){
						State.kefushow = true
					} else if(type==5){
						router.push({
						    path: "/Invite"
						});
					} else if(type==6){
						if(State.renzhengstatus==2){
							showToast(t('jiaoyi.text46'))
						} else {
							router.push({
							    path: "/verification"
							});
						}
					} else if(type==7){
						router.push({
						    path: "/tab4"
						});
					} else if(type==29){
						//客服
						upmsgstatus({msg:0,uid:State.userid}).then(res => {
							window.location.href = "http://154.23.188.40:83/mobile/index?code=44BlyA9eyXO8ELYdy3FkUkSNN1sLpcZ5jKi4MYA3XOG1NLxUpNmNLWcmz12qHQoQlj2s4iXw%2FzjgvtmomDR0VshLNZtBAxBLxRoBsOnZh8%2FS7vKKZuG9lF1RoudmuJT4XQ&userid="+State.userid;
						}).catch(err => {
						    console.log('err-->', err)
						})
					}
				},
				onloads(){
					userkycstatus().then(res => {
						console.log('认证状态',res.data)
						State.renzhengstatus = res.data.info.idstate
					}).catch(err => {
					    console.log('err-->', err)
					})
					//console.log('left')
					initIndex().then(res => {
					    State.userid = res.data.url.userid
						Function.getmsgstatus()
					}).catch(err => {
					    console.log('err-->', err)
					})
				},
				getmsgstatus(){
					getmasgstatus({uid:State.userid}).then(res => {
					    console.log(res)
						State.usermsgstatus = res.data.info
					}).catch(err => {
					    console.log('err-->', err)
					})
				},
				copys(){
					const buttonText = State.youxiangdizhi;
					const input = document.createElement('input');
					document.body.appendChild(input);
					input.setAttribute('value', buttonText);
					input.setAttribute('create', 1);
					input.select();
					document.execCommand("Copy");
					var list = document.getElementsByTagName('input')
					var inputList = Array.prototype.slice.call(list)
					inputList.forEach((item) => {
					    if (item.getAttribute('create')) document.body.removeChild(item);
					});
					showToast("Copy Success")
				}
			});
			// 接口
			const Interface = reactive({});
			
			onMounted(() => {
				Function.onloads()
			});
			return {
			  ...toRefs(State),
			  ...toRefs(Function),
			  ...toRefs(Interface),
			}
		},
	}
</script>

<style type="text/css" scoped>
	.topname{
		width: 100%;
		padding: 20px;
		height: 24px;
		font-family: eryaxindahei, eryaxindahei;
		font-weight: 400;
		/* font-size: 24px; */
		font-size: 22px;
		color: #FFFFFF;
		line-height: 24px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
	.topuid{
		width: 100%;
		height: 12px;
		padding:0 20px;
		font-family: Manrope, Manrope;
		font-weight: 400;
		font-size: 12px;
		color: #999999;
		line-height: 12px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
	.caidan{
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: left;
		margin: 35px 0;
	}
	.caidantext{
		margin-left: 22px;
	}
	.caidanimg{
		width: 25px;
		height: 25px;
	}
	.hongdian{
		width: 5px;
		height: 5px;
		background-color: red;
		border-radius: 50%;
		display: inline-block;
	}
</style>
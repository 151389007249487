import http from '@/utils/http'
//faq
export const faq = () => {
    return http({
        url: '/page/help',
        method: 'get',
    })
}

//notifications通知接口
export const notifications = () => {
    return http({
        url: '/page/notice',
        method: 'get',
    })
}

//邀请好友
export const invite = () => {
    return http({
        url: '/user/invite',
        method: 'get',
    })
}

//钱包余额信息
export const purse = () => {
    return http({
        url: '/api/purse',
        method: 'get',
    })
}

//获取汇率
export const exchange = (data) => {
    return http({
        url: '/api/exchange',
        method: 'get',
        params: data
    })
}

//提现申请接口
export const Exchangeshenqing = (param) => {
    return http({
        url: '/Exchange/shenqing',
        method: 'post',
        data: param
    })
}

//兑换货币
export const convert = (param) => {
    return http({
        url: '/api/convert',
        method: 'post',
        data: param
    })
}

//充值钱包类型
export const recharge = () => {
    return http({
        url: '/api/recharge',
        method: 'get',
    })
}

//充值接口
export const Financerecharge = (param) => {
    return http({
        url: '/Finance/recharge',
        method: 'post',
        data: param
    })
}
//图片上传公共接口
export const uploadimage = (param) => {
        return http({
            url: '/api/image',
            method: 'post',
            data: param,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
    }
    //订单流水
export const record = (type, coin) => {
        return http({
            url: '/user/accountRecords',
            method: 'get',
            params: {
                type: type,
                coin: coin
            }
        })
    }
    //质押记录
export const financing = () => {
    return http({
        url: '/financing/log',
        method: 'get',
    })
}
    //个人中心
export const user = () => {
    return http({
        url: '/user/center',
        method: 'get',
    })
}

//认证
export const authenticator = (data) => {
    return http({
        url: '/User/kyc1_Handle',
        method: 'post',
        data: data
    })
}
//认证状态
export const userkycstatus = () => {
    return http({
        url: '/user/kyc1',
        method: 'get',
    })
}

//注册谷歌验证
export const zhucegoogleAuthenticator = (data) => {
    return http({
        url: 'user/rega',
        method: 'get',
        params: data
    })
}

//谷歌验证
export const googleAuthenticator = (data) => {
    return http({
        url: 'user/ga',
        method: 'get',
        params: data
    })
}

//输入验证
export const inputVerification = (data) => {
    return http({
        url: 'user/gaGoogle',
        method: 'get',
        params: data
    })
}
export const emailCode = (data) => {
    return http({
        url: 'home/login/mailcode',
        method: 'post',
        data: data
    })
}
export const banlace = () =>{
    return http({
        url: 'user/balance',
        method:'get',
    })
}

export const ctrecords = (data) => {
    return http({
        url: 'user/ctrecords',
        method: 'get',
        params: data
    })
}

// 钱包唤起充值
export const walletdeposit = (data) => {
    return http({
        url: '/Finance/rechargebywallet',
        method: 'post',
        data: data
    })
}
//越南语
export default {
	account: {
		Start_making_money_plan: "Bắt đầu lập kế hoạch kiếm tiền",
		Market: "Chợ",
		Migital_currency: "Tiền tệ tiền tệ",
		Foreign_exchange: "Ngoại hối",
		Noble_metal: "Kim loại quý",
		Send_Cryop_Now: "Gửi Cryop ngay",
		Send: "Gửi",
		Receive: "Nhận được",
		Buy: "Mua",
		Currency_account: "Tài khoản tiền tệ",
		Contract_account: "Tài khoản hợp đồng",
		Transfer: "Chuyển khoản",
		Select_a_wallet: "Chọn ví",
		Select_Coin: "Chọn đồng xu",
		Confirm: "Xác nhận",
		Wallet: "Cái ví",
		Availavle: "Có sẵn",
		Frozen: "Đông cứng",
		Convert: "Chuyển thành",
		Receiving_Address: "Địa chỉ nhận hàng",
		Amount: "Số lượng",
		Max: "Tối đa",
		Send_Now: "Gửi ngay bây giờ",
		Please_check_if: "Vui lòng kiểm tra xem địa chỉ nhận của bạn có chính xác không trước khi gửi, để không gây mất mát tài sản Phí rút tiền là",
		new1: 'tiền điện tử',
		new2: 'chỉ số',
		new3: 'số lượng lớn',
		new4: 'Ngoại hối',
		new5: 'tổng tài sản',
	},
	"964128-0": "Ngày",
	"964128-1": "Ồ!",
	"964128-2": "Bạn không có giao dịch nào. Danh sách trống.",
	"652934-0": "Giao Dịch Kỹ Thuật Số AI",
	"652934-1": "Số Lượng AI",
	"652934-2": "Lợi Nhuận Hôm Nay",
	"652934-3": "Lợi Nhuận Tổng Cộng",
	"652934-4": "An Toàn Tài Chính",
	"652934-5": "Lợi Nhuận Đều Đặn",
	"652934-6": "Hoạt Động Đơn Giản",
	"652934-7": "Xem Thêm",
	"652934-8": "Tạo Giao Dịch AI",
	"652934-9": "Thời Gian",
	"652934-10": "Số Lượng",
	"652934-11": "Tỷ Lệ Trả Lại",
	"652934-12": "Định Mức",
	"652934-13": "Xác Nhận",
	"652934-14": "Thông Tin Trên Đầu",
	"652934-15": "Chế Độ Chủ Đề",
	"652934-16": "Kiểm Tra---",
	"314962-0": "Google 2FA",
	"314962-1": "Tải xuống Google Authenticator để liên kết",
	"314962-2": "Sao chép mã xác thực",
	"314962-3": "Nhập mã xác thực",
	"314962-4": "Yêu cầu thất bại:",
	"314962-5": "Mã xác thực đã được sao chép",
	"314962-6": "Không thể sao chép văn bản:",
	"314962-7": "Sao chép thất bại, vui lòng thử lại",
	"199917-0": "Cài đặt",
	"199917-1": "Thông báo",
	"199917-2": "Google 2FA",
	"199917-3": "Email",
	"199917-4": "Ngôn ngữ",
	"199917-5": "Phiên bản",
	"199917-6": "Đăng xuất",
	home: {
		USDTAccount: 'tài khoản USDT',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: 'Ủy quyền',
		ETHTodayProfit: 'Lợi nhuận ETH hôm nay',
		totalRevenue: 'Tổng doanh thu',
		portfolio: 'đầu tư',
		reliableSecurity: 'Đảm bảo an ninh đáng tin cậy',
		stableReliable: 'Đầu tư ổn định và đáng tin cậy',
		convenienEasy: 'Hoạt động thuận tiện và dễ dàng',
		pleaseLookForward: 'Xin vui lòng chờ đợi...',
		rateOfReturn: 'Tỷ lệ phản hồi',
		walletnot: 'Vui lòng cài đặt MetaMask hoặc bất kỳ ví mở rộng Ethereum nào',
		wallettip: 'Vui lòng liên kết địa chỉ của bạn để tránh ảnh hưởng đến trải nghiệm',
	},
	"ConnectW": "Kết nối ví",
	"dappbrowser": "Trang web này chỉ chấp nhận quyền truy cập từ trình duyệt DAPP ví phi tập trung.",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "kiếm lãi",
	"$": "$",
	"ETH Today's Profit": "Lợi nhuận ETH hôm nay",
	"Wallet": "cái ví",
	"Record": "Đặt hàng",
	"Support": "dịch vụ khách hàng",
	"General": "toàn diện",
	"Notifications": "thông báo",
	"Invite Friends": "Mời bạn bè",
	"FAQ": "vấn đề thường gặp",
	"Privacy & Security": "Quyền riêng tư và bảo mật",
	"Legality": "Chính sách bảo mật",
	"Authenticator": "Đã xác minh",
	"Settings": "cài đặt",
	"Change Language": "thay đổi ngôn ngữ",
	"Daytime mode": "chế độ ngày",
	"Black mode": "Chế độ ban đêm",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'khai mạc',
    'High':'Cao nhất',
    'Low':'thấp nhất',
    'Close':'đóng cửa',
    'Up':'mua lên',
    'Down':'Mua hoặc bán',
    'Time':'thời gian',
    'TransactionFee':'phí xử lý',
    'AvailableBalance':'Số dư khả dụng',
    'submit':'đăng ký',
    'Direction':'phương hướng',
    'Profit':'lợi nhuận',
    'Quantity':'Số lượng',
    'Price':'giá ',
    'different':'Nhấp vào các thang đo khác nhau để hiển thị giới hạn tối thiểu',
    'lessthan':'Ít nhất không kém hơn',
	'lessthans': 'không thể lớn hơn',
    'least':'ít nhất：',
    'Transactionhistory':'Ghi lại giao dịch',
    'Transactionrecords':'Ghi lại giao dịch',
    'Number':'Số lượng',
    'Profitloss':'mất lợi nhuận',
	'Ror':'trở lại',
	'operate':'vận hành',
	'About_us': 'Giới thiệu về chúng tôi',





	//钱包
	TransactiónDetails: 'Chi tiết giao dịch',
	dealdetails: 'chi tiết giao dịch',
	ViewTransactionHistory: 'Xem lịch sử giao dịch',
	Deposit: 'nạp tiền',
	Rechargeaddress: 'Địa chỉ nạp tiền：',
	DepositAmount: 'Số tiền gửi：',
	EnterDepositAmount: 'Nhập số tiền gửi',
	EnterHash: 'Nhập giá trị băm',
	Hash: 'Giá trị băm：',
	Voucherimage: 'Hình ảnh thông tin xác thực：',
	Continue: 'Tiếp tục',
	Withdraw: 'rút',
	Withdrawal: 'lấy lại：',
	EnterAmounttoWithdraw: 'Nhập số tiền rút',
	Available: "có thể sử dụng được：",
	ReceiveAddress: 'địa chỉ nhận：',
	EnterWalletAddress: 'Nhập địa chỉ ví',
	WithdrawalDesc: 'Rút tiền bằng tiếng Anh yêu cầu phí xử lý 1% và yêu cầu xác nhận từ nút mạng trước khi có thể ghi có. Vui lòng không chuyển tiền điện tử cho người lạ.',
	Exchange: 'trao đổi',
	Send: 'chuyển giao:',
	Max: 'tối đa:',
	rechargeAmount: 'Vui lòng nhập số tiền nạp',
	hashcode: 'Giá trị băm và ảnh chụp màn hình không được để trống cùng lúc',
	correcthashcode: 'Vui lòng nhập giá trị băm chính xác',
	maximumwithdrawalamount: 'Số tiền rút tối đa là',
	Incorrectamountformat: 'Định dạng số tiền không chính xác',
	copy: 'sao chép',


	//tab2
	"Total Profit": "Tổng lợi nhuận",
	"Today's Profit": "Lợi nhuận hôm nay",
	"What is Automated Trading?": "Giao dịch tự động là gì？",
	"Learn about earning": "Tìm hiểu về thu nhập",
	"Staking Period": "Thời hạn cam kết",
	"Staking Amount": "Số lượng cam kết",
	"Yield": "lợi ích",
	"Limit": "giới hạn",
	"Subscribe": "đăng ký",

	//tab3
	"Proof of Stake": "Bằng chứng về cổ phần",
	"ETH Today is Price": "Giá ETH hôm nay",
	"ETH Today's Increase": "ETH tăng giá ngày hôm nay",
	"Joint Staking": "Cam kết chung",
	"Individual Staking": "Cam kết cá nhân",
	"tab3long1": "Những người tham gia được ghép nối thông qua hợp đồng thông minh để thực hiện đặt cược chung, với tổng số tiền đặt cược đạt 32 ETH.",
	"tab3long2": "Cá cược độc lập không yêu cầu hợp đồng thông minh để phù hợp với người tham gia.  Cược cá nhân có thể hoàn thành khi số tiền đặt cược đạt 32 ETH",


	//web_view
	"webviewlong1": "Các đợt airdrop tiền điện tử hỗ trợ sự phát triển của các dự án dựa trên blockchain mới nổi.  Nguồn gốc của họ kết nối các thành viên cộng đồng thông qua phần thưởng và mục tiêu chung.  Kết quả của đợt airdrop thành công là một cộng đồng mạnh mẽ hơn, các dự án tốt hơn và phần thưởng dành cho người dùng cuối.  Trong những năm qua, chúng tôi đã thấy nhiều đợt airdrop được thực hiện trên chuỗi khối Proof-of-Work (POW).  Chuỗi Ethereum bằng chứng cổ phần (POS) hiện hỗ trợ airdrop tiền điện tử cho hàng trăm dự án trên hàng chục mạng.  Khi PoS trở thành mô hình đồng thuận thống trị, số lượng airdrop trên mạng Ethereum không có dấu hiệu chậm lại.",
	"webviewlong2": "Bằng cách đặt cược tiền điện tử của mình, bạn có đủ điều kiện để mở khóa các phần thưởng airdrop có giá trị dựa trên số lượng tiền điện tử mà bạn đặt cược.",
	"webviewlong3": "Phần thưởng vốn chủ sở hữu chỉ dành cho những người nắm giữ cổ phần và đến từ hàng trăm đợt airdrop dự án khác nhau, với tỷ suất lợi nhuận cao hơn nhiều so với bất kỳ đợt airdrop độc lập nào.",
	"webviewlong4": "Phần thưởng airdrop thường gây khó khăn cho chủ sở hữu tiền điện tử gửi tiền trên các sàn giao dịch và một số người giám sát hoàn toàn không hỗ trợ airdrop.  Đây là lý do tại sao việc quản lý tiền điện tử của riêng bạn lại quan trọng.  Ethereum là một thực thể không giám sát và có thể được đặt cược trên tất cả các mạng lớn",
	"Introduction": "giới thiệu",
	"webviewlong5": "Ethereum, nền tảng blockchain hàng đầu, đang chuyển đổi từ cơ chế đồng thuận bằng chứng công việc (PoW) sang cơ chế bằng chứng cổ phần (PoS) thông qua nâng cấp Ethereum 2.0. Đặt cược PoS là một phần quan trọng của quá trình chuyển đổi này, mang đến cho người dùng cơ hội hỗ trợ hoạt động và bảo mật mạng đồng thời có khả năng kiếm được phần thưởng.  Bài viết này cung cấp cái nhìn tổng quan về đặt cược Ethereum PoS và lợi ích của nó.",
	"Ethereum 2.0 and Proof of Stake (PoS)": "Ethereum 2.0 và Bằng chứng cổ phần (PoS)",
	"webviewlong6": 'Ethereum 2.0 là một bản nâng cấp mạng lớn được thiết kế để cải thiện khả năng mở rộng, bảo mật và khả năng phục hồi.  Một trong những thay đổi quan trọng là việc chuyển từ cơ chế đồng thuận PoW tiêu tốn nhiều năng lượng sang cơ chế PoS thân thiện với môi trường hơn.  Trong PoS, người xác thực được chọn để tạo các khối mới và xác nhận giao dịch dựa trên số lượng tiền điện tử mà họ nắm giữ và sẵn sàng “đặt cọc” làm tài sản thế chấp.',
	"The Staking Process": "Quy trình cầm cố",
	"webviewlong7": "Để tham gia đặt cược Ethereum PoS, người dùng phải đặt cược ít nhất 32 ETH bằng cách gửi số tiền đó vào hợp đồng tiền gửi Ethereum 2.0.  Sau khi đặt cược, ETH sẽ bị khóa trong một khoảng thời gian và đóng vai trò là tài sản thế chấp để đảm bảo an ninh cho mạng.  Trình xác thực được chọn dựa trên số lượng ETH đặt cược và hoạt động trực tuyến của họ.  Họ chịu trách nhiệm đề xuất và xác thực các khối mới, cũng như xác nhận các giao dịch trên mạng.",
	"Staking Rewards and Risks": "Đặt cược phần thưởng và rủi ro",
	"webviewlong8": "Bằng cách tham gia đặt cược PoS, người dùng có thể kiếm được phần thưởng dưới dạng ETH mới được đúc và phí giao dịch.  Phần thưởng dựa trên số lượng ETH đặt cược và hoạt động tổng thể của mạng.  Tuy nhiên, đặt cược đi kèm với rủi ro, chẳng hạn như có thể bị phạt nếu người xác thực có hành động ác ý hoặc không trực tuyến liên tục.  Ngoài ra, ETH đặt cược sẽ bị khóa trong một khoảng thời gian, khiến nó có tính thanh khoản kém và dễ bị biến động về giá.",
	"Joint Staking and Individual Staking": "Cam kết chung và cam kết cá nhân",
	"webviewlong9": "Đối với những người dùng không có đủ 32 ETH để đặt cược riêng lẻ, việc đặt cược chung sẽ cung cấp một tùy chọn khác.  Trong đặt cược chung, nhiều người dùng gộp ETH của họ lại với nhau để đạt được số tiền đặt cược cần thiết.  Việc tổng hợp này thường được tạo điều kiện thuận lợi thông qua các hợp đồng thông minh hoặc dịch vụ đặt cược, cho phép người dùng tham gia đặt cược Ethereum PoS với số lượng ETH nhỏ hơn.",
	"Conclusion": "Tóm lại là",
	"webviewlong10": "Đặt cược Ethereum PoS là một khía cạnh quan trọng của bản nâng cấp Ethereum 2.0, mang đến cho người dùng cơ hội hỗ trợ bảo mật mạng, cải thiện tính phân cấp và kiếm phần thưởng.  Bằng cách hiểu rõ quy trình đặt cược, phần thưởng và rủi ro của nó, người dùng có thể đưa ra quyết định sáng suốt về việc tham gia vào quá trình phát triển quan trọng này của mạng Ethereum.",


	//mywallet
	"Total": "số tiền một lần",

	//record
	"Account": "tài khoản",
	"interest-bearing": "Quan tâm",
	"POS Staking": "Cam kết POS",
	"convert": "trao đổi",
	"transaction": "buôn bán",
	"pledge": "thế chấp",
	"recordno": "Bạn vẫn chưa nghe thấy gì cả.  Danh sách trống.",
	"Oops": "Ối",
	"recharge": "nạp tiền",
	"payment": "chi trả",
	"staking": "lời hứa",
	"income": "thu nhập",
	"Pledge quantity": "Số lượng cam kết",
	"cycle": "xe đạp",
	"Days Remaining": "Số ngày còn lại",
	"cumulative gain": "lợi nhuận tích lũy",
	"Price increase": "Giá tăng",
	"Price decrease": "rớt giá",
	"Opening price": "giá mở cửa",
	"Closing price": "Giá đóng cửa",
	"loss": "sự mất mát",
	"pfofit": "lợi nhuận",
	"amount": "Số lượng",

	//notifiCations
	"notifiCationslong1": "Lưu ý: Đăng ký VIP xác định cấp độ của bạn dựa trên giá trị được lưu trữ của tài khoản tổng thể tương ứng.  Phạm vi này cần khấu trừ tổng giá trị được lưu trữ sau khi rút tiền từ tài khoản hiện có để xác định cấp độ thành viên.",
	"notifiCationslong2": "Thời hạn thành viên cũng được chia theo các cấp độ khác nhau.  Để duy trì tư cách thành viên của mình, bạn cần nạp ít nhất 5.000 USDT mỗi lần trong thời gian quy định để được hưởng các lợi ích thành viên.  Sau mỗi lần nạp thẻ thành viên, chu kỳ sẽ được tính lại và có thể được tích lũy.",
	"notifiCationslong3": "Dịch vụ khách hàng được chia theo cấp độ thành viên.  Dịch vụ khách hàng chính thức được cung cấp bởi nhân viên APP để cung cấp các dịch vụ thống nhất cho các thành viên.  Số lượng thư ký chuyên trách được hạn chế để đảm bảo phục vụ tốt hơn.  Thư ký cá nhân độc quyền cung cấp cho người dùng các dịch vụ lễ tân và tư vấn quản lý tài chính độc quyền.",
	"notifiCationslong4": "Liên hệ trước với bộ phận chăm sóc khách hàng để đặt lịch hẹn nạp tiền.  Nếu bạn hoàn tất việc nạp tiền trong thời gian quy định, bạn có thể nhận thêm phần thưởng nạp tiền dành riêng dựa trên cấp độ thành viên tương ứng.",
	"notifiCationslong5": "VIP1: Dựa trên tài khoản UID, nếu giá trị tích lũy là 10.000 USD, bạn có thể nhận được phần thưởng 177 USDT.  Sau khi đạt đến cấp độ VIP và nhận được huy chương thành viên thông thường, điểm sẽ được chuyển vào tài khoản.",
	"notifiCationslong6": "VIP2: Dựa trên tài khoản UID, nếu giá trị tích lũy đạt 30.000 USD, bạn sẽ nhận được 777 USDT.  Số tiền thưởng sẽ được gửi vào tài khoản ngay sau khi đạt cấp VIP và nhận Huy hiệu thành viên Đồng.",
	"notifiCationslong7": "VIP3: Theo tài khoản UID, nếu giá trị tích lũy là 70.000 đô la Mỹ, bạn sẽ nhận được 1.777 USDT.  Sau khi đạt cấp VIP và nhận Huy chương Thành viên Bạc, điểm sẽ được chuyển vào tài khoản.  Ngoài ra, bạn sẽ nhận được thêm 1,5% tiền thưởng trên giá trị đặt phòng tiếp theo.",
	"notifiCationslong8": "VIP4: Theo tài khoản UID, giá trị tích lũy được lưu trữ là 150.000 đô la Mỹ và sẽ được thưởng 2.777 USDT.  Sau khi đạt cấp VIP và nhận Huy chương Thành viên Vàng, điểm sẽ được chuyển vào tài khoản.  Ngoài ra, bạn sẽ nhận được thêm 2% tiền thưởng cho giá trị nạp thêm của lần đặt phòng tiếp theo.",
	"notifiCationslong9": "VIP5: Dựa trên tài khoản UID, nếu giá trị tích lũy đạt 300.000 USD, 4777 USDT sẽ được tặng làm quà tặng.  Sau khi đạt cấp VIP và nhận được huy hiệu VIP Kim cương, điểm sẽ được chuyển vào tài khoản.  Ngoài ra, bạn sẽ nhận được thêm 2,5% tiền thưởng trên giá trị tích lũy của lần đặt phòng tiếp theo.",
	"notifiCationslong10": "VIP6: Dựa trên tài khoản UID, giá trị tích lũy đạt 800.000 USD và 16.888 USDT sẽ được tặng làm quà tặng.  Số tiền thưởng sẽ được airdrop vào tài khoản ngay sau khi đạt cấp VIP và nhận được huy hiệu thành viên Kim cương đen, đồng thời sẽ nhận được thêm 3% số tiền nạp đăng ký trước tiếp theo.  Bạn sẽ có một thư ký riêng tận tâm để cung cấp bất kỳ lời khuyên tài chính nào hoặc giải quyết các vấn đề về thuế.",
	"notifiCationslong11": "VIP7: Dựa trên tài khoản UID, nếu giá trị tích lũy đạt 3 triệu USD, bạn sẽ nhận được chuyến du lịch sang trọng trong 15 ngày tới Châu Âu (có giảm giá).  Được trao giải VIP danh dự trọn đời toàn cầu của CoinBpos và nhận được Huân chương Thành viên Crown.  Bạn sẽ nhận được thêm 3,5% tiền thưởng khi nạp tiền cho lần đặt chỗ tiếp theo.",
	"notifiCationslong12": "VIP8: Theo tài khoản UID, nếu giá trị tích lũy đạt 8 triệu đô la Mỹ, bạn có thể nhận được phiếu quà tặng tiệc hàng năm của CoinBpos Ma-rốc.  Phần thưởng: 1 BTC và nhận VIP danh dự trọn đời toàn cầu của CoinBpos.  Bạn có thể nhận thêm 4% tiền thưởng khi nạp tiền cho lần đặt chỗ tiếp theo.",
	"notifiCationslong13": "VIP9: Dựa trên tài khoản UID, nếu bạn gửi tổng cộng 30 triệu đô la Mỹ, bạn có thể nhận được 3% cổ phần CoinBpos.  Và được hưởng cổ tức lợi nhuận hàng năm là 3%.  Tặng kèm ghim kỷ niệm bằng vàng nguyên khối Cronix.",

	//invite
	"Refer and Earn": "Tham khảo và kiếm tiền",
	"Copy Link": "Sao chép đường dẫn",

	//faq
	"Frequently Asked Questions": "Các câu hỏi thường gặp",

	//authenticator
	"mailbox": "thư",
	"Front photo": "ảnh chân dung",
	"Back photo": "Ảnh quay lại",
	"Handheld ID photo": "Giữ ảnh CMND",
	"define": "Chắc chắn",

	//legality
	"legalitylong1": "Coinbpos bao gồm nhiều công ty tiền điện tử cùng nhau cung cấp các công cụ giúp cộng đồng tiền điện tử tạo ra, phát triển và duy trì sự ổn định của cộng đồng, hỗ trợ hàng nghìn ứng dụng phi tập trung, thúc đẩy tương lai của tiền điện tử và thúc đẩy sự phát triển của tiền tệ truyền thống. sự phồn vinh.",
	"legalitylong2": "Mỗi công ty trong Coinbpos đóng vai trò là người kiểm soát dữ liệu để xử lý dữ liệu cá nhân liên quan đến các dịch vụ của mình, người kiểm soát dữ liệu cho mỗi dịch vụ là:",
	"legalitylong3": "1. Công nghệ chuỗi khối - chuỗi khối Ethereum.",
	"legalitylong4": "2. Ví phi tập trung-Ví Trust, ví MetaMask và tiền điện tử.",
	"legalitylong5": "3. Công nghệ giao dịch-Coinbase, Crypto, linch, Binance.",
	"legalitylong6": "Chính sách quyền riêng tư nêu chi tiết cách Coinbpos xử lý dữ liệu cá nhân theo nghĩa vụ của mình theo luật bảo vệ dữ liệu có liên quan, bao gồm Quy định bảo vệ dữ liệu chung của Liên minh Châu Âu (GDPR),",
	"legalitylong7": 'Đạo luật về quyền riêng tư của người tiêu dùng California (CCPA) và các luật chung khác (gọi chung là "Luật bảo vệ dữ liệu"*).',

	//language
	"language": "ngôn ngữ",
	"Confirm": "xác nhận",
	"Cancel":'Hủy bỏ',

	"newtext1": "từ bỏ",
	"newtext2": "Hủy bỏ",
	"newtext3": "Bạn có chắc chắn muốn đăng xuất khỏi tài khoản này không?",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "Đăng nhập",
	"newtext9": "tên tài khoản",
	"newtext10": "Số điện thoại",
	"newtext11": "Vui lòng nhập tên người dùng",
	"newtext12": "Vui lòng nhập số điện thoại",
	"newtext13": "mật khẩu",
	"newtext14": "Xin vui lòng nhập mật khẩu",
	"newtext15": "Chưa có tài khoản",
	"newtext16": "đăng ký",
	"newtext17": "Quốc gia",
	"newtext18": "Chọn một quốc gia",
	"newtext19": "Mã xác minh số điện thoại di động",
	"newtext20": "vui lòng nhập mã xác nhận",
	"newtext21": "địa chỉ email",
	"newtext22": "Vui lòng nhập địa chỉ email",
	"newtext23": "Xác nhận mật khẩu",
	"newtext24": "Vui lòng nhập mật khẩu xác nhận",
	"newtext25": "có một tài khoản",
	"newtext26": "Gửi mã xác minh",
	"newtext27": "Số điện thoại di động không chính xác",
	"newtext28": "Gửi thành công",
	"newtext29": "đăng ký thành công",

	"newtext30": 'tiền tệ',
	"newtext31": 'Ngoại hối',
	"newtext32": 'kim loại quý',

	"newtext33": 'Tài khoản',
	"newtext34": 'Hoạt động trên nền tảng',
	"newtext35": 'Dịch vụ khách hàng',
	"newtext36": 'lời mời',
	"newtext37": 'Xác minh KYC',
	"newtext38": 'cài đặt',
	wallet_p: {
		text1: "Sao chép địa chỉ",
		text2: 'Xác nhận',
		text3: 'Chuyển đổi từ',
		text4: "Chuyển đổi sang",
		text5: "TẤT CẢ",
		text6: "Mời gọi",
		text7: 'Nhận phần thưởng giới thiệu',
		text8: "Khi bạn bè của bạn tham gia vào AI arbitrage và thuê máy khai thác, bạn có thể nhận phần thưởng giới thiệu",
		text9: "Sao chép",
		text10: 'Chia sẻ liên kết',
		text11: "Xác minh KYC",
		text12: "Quốc gia",
		text13: "Vui lòng chọn một quốc gia",
		text14: "Tên",
		text15: "Vui lòng nhập tên của bạn",
		text16: "Họ",
		text17: "Vui lòng nhập họ của bạn",
		text18: "Loại chứng chỉ",
		text19: "Vui lòng chọn loại chứng chỉ",
		text20: "Số chứng chỉ",
		text21: "Vui lòng nhập số chứng chỉ",
		text22: "Vui lòng tải lên ảnh mặt trước của thẻ căn cước của bạn",
		text23: "Vui lòng tải lên ảnh mặt sau của thẻ căn cước của bạn",
		text24: "Vui lòng tải lên ảnh mặt trước của hộ chiếu của bạn",
		text25: "Vui lòng tải lên ảnh mặt sau của hộ chiếu của bạn",
		text26: "Email",
		text27: "Vui lòng nhập email của bạn",
		text28: "gửi",
		text29: "Mã xác minh",
		text30: "Vui lòng nhập mã xác minh của bạn",
		text31: "Chứng minh nhân dân",
		text32: "hộ chiếu",
		text33: "hủy bỏ",
		text34: "Quốc gia/Khu vực",
		text35: "Vui lòng nhập thông tin chính xác để gửi để xác thực",
		text36: "Đã gửi thành công!",
		text37: "Định dạng email sai!",
		text38: "bằng lái xe",
		text39: "Vui lòng tải lên ảnh mặt trước bằng lái xe của bạn",
		text40: "Vui lòng tải lên ảnh mặt sau bằng lái xe của bạn",

		text41: "số CMND",
		text42: "Vui lòng nhập số ID của bạn",
		text43: "Số hộ chiếu",
		text44: "Vui lòng nhập số hộ chiếu",
		text45: "số giấy phép lái xe",
		text46: "Vui lòng nhập số giấy phép lái xe của bạn",
	},
	jiaoyi: {
		text1: 'Hợp đồng thứ hai',
		text2: 'Hợp đồng vĩnh viễn',
		text3: 'Giao dịch giao ngay',
		text4: 'Ủy quyền ngay lập tức',
		text5: 'Loại tài khoản',
		text6: 'thời gian giao hàng',
		text7: 'Phạm vi giá',
		text8: 'Giá mua',
		text9: 'chi phí',
		text10: 'Cân bằng',
		text11: 'ít nhất',
		text12: 'mong đợi',
		text13: 'Số tiền mua',
		text14: 'hướng',
		text15: 'Giá mua',
		text16: 'Giá theo thời gian thực',
		text17: 'Đếm ngược',
		text18: 'Hợp đồng của tôi',
		text19: 'Lợi nhuận',
		text20: 'Hợp đồng',
		text21: 'giá giao hàng',
		text22: 'thời gian giao hàng',
		text23: 'Giá thị trường',
		text24: 'tăng',
		text25: 'rơi',
		text26: 'Giá mua',
		text27: 'tối đa',
		text28: 'Lợi nhuận',
		text29: 'phí xử lý',
		text30: 'Mua',
		text31: 'Bán',
		text32: 'Số lượng mua',
		text33: 'tài khoản thật',
		text34: 'Tài khoản ảo',
		text35: 'Chưa có dữ liệu',
		text36: 'Vị trí hiện tại',
		text37: 'Ủy ban lịch sử',
		text38: 'Đóng vị trí',
		text39: 'Lề',
		text40: 'Lợi nhuận và thua lỗ',
		text41: 'giữ',
		text42: 'Hồ sơ mua hàng',
		text43: 'Bán kỷ lục',
		text44: 'Có sẵn',
		text45: 'đã chuyển đổi',
		text46: 'Xác thực thành công',
		text47: 'Thời gian mua hàng',
		text48: 'chưa đọc',
		text49: 'Đọc',
		text50: 'Thứ tự máy chủ',
		text51: 'Tổng chênh lệch giá',
		text58: 'Thu nhập hôm nay',
		text52: 'Robot trí tuệ nhân tạo hoạt động như thế nào',
		text53: 'sản phẩm chênh lệch giá',
		text54: 'ngày',
		text55: 'Số tiền',
		text56: 'Thu nhập hàng ngày',
		text57: 'Loại tiền tệ',
		text59: 'Tham gia kinh doanh chênh lệch giá AI',
		text60: 'Không rủi ro, lợi nhuận nhanh chóng',
		text61: 'Kinh doanh chênh lệch giá AI',
		text62: 'Thu nhập dự kiến',
		text63: 'Loại tiền chênh lệch giá',
		text64: 'Số tiền ký quỹ',
		text65: 'Lưu trữ ngay bây giờ',
		text66: 'Thu nhập hàng ngày được gửi tới ví USDT của bạn',
		text67: 'Quỹ đầu tư không có rủi ro',
		text68: 'Bạn có thể rút tiền bất cứ lúc nào',
		text69: 'Trí tuệ nhân tạo hoạt động 24 giờ một ngày',
		text70: 'Thu nhập tích lũy',
		text71: 'Thời gian hết hạn',
		text72: 'tất cả',
		text73: 'Đang tiến hành',
		text74: 'Hoàn thành',
		text75: 'Chi tiết máy chủ',
		text76: 'Chọn loại tiền tệ',
		text77: 'Hỗ trợ kỹ thuật',
		text78: 'Gửi email',
	},
	tabbar:{
		text1:'Trang chủ',
		text2:'Chợ',
		text3:'Định lượng Ai',
		text4:'Cho vay',
		text5:'Tài khoản',
		text6:'Số dư tài khoản',
		text7:'Thu nhập hôm nay',
		text8:'Tỷ lệ hoàn vốn',
		text9:'thêm',
		text10:'Sắp có',
		text11:'Bán trước xu mới',
		text12:'Bắt tay vào hành trình làm giàu kỹ thuật số',
		text13:'Mua ngay',
		text14:'Dễ vận hành',
		text15:'Giao dịch định lượng AI',
		text16:'An toàn / ổn định / đơn giản',
		text17:'Tạo giao dịch',
		text18:'Tổng hoa hồng',
		text19:'Chia sẻ liên kết này với bạn bè của bạn và nhận tới $200. ',
		text20:'Liên kết được đề xuất',
		text21:'Chia sẻ với bạn bè thông qua phần mềm xã hội',
	},
	tab4:{
		text1: "Số dư tài khoản",
		text2: "Lợi nhuận hôm nay",
		text3: "Định lượng AI",
		text4: "Tỷ lệ hoàn vốn",
		text5: "Gửi tiền",
		text6: "Chuyển khoản ngân hàng",
		text7: "Rút tiền",
		text8: "Gửi tiền",
		text9: "Địa chỉ ví",
		text10: "Đã sao chép",
		text11: "Tải lên chứng từ",
		text12: "Vui lòng kiểm tra kỹ địa chỉ gửi tiền để đảm bảo địa chỉ đó chính xác vì chuyển khoản sai có thể không được hoàn lại. Tiền gửi yêu cầu xác nhận trước khi được ghi có vào tài khoản của bạn.",
		text13: "Chuyển khoản ngân hàng",
		text14: "Trước khi bắt đầu chuyển khoản ngân hàng:",
		text15: "Vui lòng liên hệ với nhóm dịch vụ khách hàng của chúng tôi để biết thông tin chính xác về tài khoản T/T trước khi bắt đầu T/T. Bước này đảm bảo tiền của bạn được chuyển vào tài khoản một cách an toàn và suôn sẻ. ",
		text16: "Thời gian xử lý T/T:",
		text17: "Vui lòng liên hệ với nhóm dịch vụ khách hàng của chúng tôi để biết thông tin chính xác về tài khoản T/T trước khi bắt đầu T/T. Bước này đảm bảo tiền của bạn được chuyển vào tài khoản một cách an toàn và suôn sẻ. ",
		text18: "Hỗ trợ trong quá trình chuyển khoản ngân hàng",
		text19: "Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào trong quá trình chuyển khoản, vui lòng liên hệ với nhóm dịch vụ khách hàng của chúng tôi. Chúng tôi cam kết hỗ trợ và hỗ trợ để đảm bảo cung cấp cho bạn trải nghiệm giao dịch trơn tru. ",
		text20: "Số tiền",
		text21: "Tiền rút tiền",
		text22: "Xác nhận",
		text23: "Hãy chắc chắn xác nhận địa chỉ nhận của bạn vì việc chuyển tiền sai sẽ không thể hoàn tác được. Sẽ mất vài phút để xác nhận được ghi có vào tài khoản của bạn.",
		text24: "Lệnh lựa chọn",
		text25:  "Lệnh hợp đồng",
		text26: "Lệnh định lượng Ai",
		text27: "Lịch sử",
		text28: "Mời bạn bè",
		text29: "Câu hỏi thường gặp",
		text30: "dịch vụ khách hàng",
		text31: "Về chúng tôi",
		text32: "Thay đổi ngôn ngữ",
		text33: "Thoát khỏi hạ cánh"
	},
	uploadProof: {
		text1: "Tải lên bằng chứng",
		text2: "Chọn hình ảnh",
		text3: "Xem trước",
		text4: "Tải lên ảnh chụp màn hình tiền gửi của bạn",
		text5: "Số tiền",
		text6: "Hash giao dịch",
		text7: "6 chữ số cuối",
		text8: "Vui lòng tải lên ảnh chụp màn hình thanh toán tiền mã hóa của bạn và chờ đợi xem xét sau khi gửi",
		text9: "Xác nhận"
	},
	newadd:{
		text1:'thành công',
		text2:'Thất bại',
		text3:'Việc cần làm',
		text4:'Mã vương miện của tôi',
		text5:'Cung cấp',
		text6:'Đã bán',
		text7:'Chương trình bán trước kết thúc',
		text8:'số tiền',
		text9:'Trao đổi',
		text10:'Đặc điểm kỹ thuật',
		text11:'Loại tiền mới sử dụng công nghệ blockchain, có tốc độ giao dịch nhanh, phí xử lý thấp, bảo mật giao dịch cao, cũng như các chức năng hợp đồng thông minh và công nghệ bảo vệ quyền riêng tư. ',
		text12:'Tầm nhìn dự án',
		text13:'Tầm nhìn của Xincoin là trở thành nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới, cung cấp cho người dùng các dịch vụ giao dịch tài sản kỹ thuật số an toàn, thuận tiện và hiệu quả hơn. ',
		text14:'Kịch bản ứng dụng',
		text15:'Tiền tệ mới có nhiều tình huống ứng dụng, bao gồm giao dịch tiền kỹ thuật số, giao dịch chứng khoán kỹ thuật số, giao dịch tài sản kỹ thuật số, thanh toán, tài chính chuỗi cung ứng và các lĩnh vực khác. ',
		text16:'Số tiền vay',
		text17:'Xác minh danh tính của bạn và nhận thêm dịch vụ! ',
		text18:'Bắt đầu xác minh',
		text19:'Tôi muốn mượn...',
		text20:'Thời gian cho vay',
		text21:'ngày',
		text22:'Lãi suất hàng ngày',
		text23:'Tổng số tiền lãi',
		text24:'Không phải trả lãi trong 1 ngày sau khi vay, lãi sẽ được trả sau đó. ',
		text25:'Mượn ngay',
		text26:'Hồ sơ khoản vay',
		text27:'Chưa vay tiền',
		text28:'Không thể tìm thấy thông tin khoản vay của bạn',
		text29:'Vượt quá phạm vi giới hạn cho vay',
		text30:'Đăng ký',
		text31:'đang tiến hành',
		text32:'Đã kết thúc',
		text33:'Mở',
		text34:'Đóng',
		text35:'bản ghi',
		text36:'Gửi tiền',
		text37:'Rút tiền',
		text38:'Rất tiếc!',
		text39:'Bạn chưa có hồ sơ giao dịch. Danh sách trống. ',
		text40:'Xin vui lòng nhập số tiền',
		text41:'bình thường',
		text42:'Đã kết thúc',
		text43:'hộp thư',
		text44:'Mã xác minh email',
		text45:'Xác nhận thanh toán trên DAPP',
		text46:'Lỗi nạp tiền, vui lòng thử lại hoặc liên hệ bộ phận dịch vụ khách hàng',
		text47:'Đơn đăng ký nạp tiền đã được gửi',
		text48:'Đang xem xét',
		text49:'Đã phê duyệt',
		
		//关于我们
		text50:'HKEX là nền tảng dịch vụ giao dịch tiền kỹ thuật số nổi tiếng thế giới. HKEX hỗ trợ nhiều giao dịch tài sản kỹ thuật số. Được thành lập vào tháng 6 năm 2019, nó đã phát triển thành một trong những nền tảng dịch vụ giao dịch tiền kỹ thuật số phổ biến nhất.',
		text51:'Hiện tại, nó cung cấp các dịch vụ một cửa như tiền tệ, tiền hợp pháp, hợp đồng và vay cho hơn 1 triệu người dùng ở 35 quốc gia và khu vực trên thế giới. Nó được mệnh danh là "nền tảng dịch vụ giao dịch thông minh và nhanh chóng nhất".',
		text52:'HKEX hoạt động tại Hồng Kông, cung cấp cho người dùng đội ngũ dịch vụ khách hàng đa ngôn ngữ, 7×24 giờ, đồng thời đã thành lập các đại lý địa phương tại Canada, Pháp, Singapore, Hoa Kỳ, Hàn Quốc, Nhật Bản, Ý. , Thổ Nhĩ Kỳ, Nga, Ấn Độ và các nơi khác. nhóm để cung cấp các dịch vụ được bản địa hóa nhất cho người dùng từ khắp nơi trên thế giới. Vào tháng 1 năm 2020, HKEX đã nhận được khoản tài trợ Series A trị giá 150 triệu USD từ KTR Capital và Kunpeng Venture Capital của Canada. Sàn giao dịch HKEX đã tham gia sâu vào thị trường quốc tế trong nhiều năm và có hàng triệu người dùng ở gần 30 quốc gia trên thế giới.',
		text53:'Kể từ tháng 5 năm 2022, để thâm nhập thị trường Trung Quốc, nhóm HKEX Châu Á Thái Bình Dương đã làm việc chăm chỉ trong vài tháng quan trọng vừa qua để tạo ra sản phẩm thông minh tối ưu, thu hút sự chú ý lớn trong các cộng đồng lớn của Trung Quốc. Sàn giao dịch HKEX tuân thủ chiến lược phát triển "quyền chọn + hợp đồng" và tuân thủ nghiêm ngặt các điểm mấu chốt của việc tuân thủ, cung cấp cho người dùng toàn cầu các dịch vụ tài chính tài sản kỹ thuật số tập trung vào "giao dịch quyền chọn và giao dịch hợp đồng" và hỗ trợ phát triển thanh khoản tài chính kỹ thuật số toàn cầu .',
		//关于我们 end
		
		text54:'Thông báo Thỏa thuận Người dùng',
		text55:'Tuyên bố pháp lý',
		text56:'Quy tắc giao dịch OTC',
		text57:'Thông báo rủi ro khi rút tiền hoạt động',
		
		text58:'Phạm vi trọng số',
		text59:'Phạm vi hạn ngạch',
		text60:'Lợi nhuận tối đa',
		text61:'Tỷ lệ lợi nhuận dự kiến',
		text62:'Chọn khu vực giao dịch',
		text63:'Chọn đồng tiền',
		text64:'Hệ số đòn bẩy',
		text65:'Chọn hệ số đòn bẩy',
		text66:'Số tiền đã lên kế hoạch',
		text67:'Vui lòng nhập số tiền đã lên kế hoạch',
		text68:'Chọn gói cấp độ',
		text69:'Điểm trọng số hiện tại của bạn là',
		text70:'Tỷ lệ đòn bẩy này vẫn chưa được mở khóa',
		text71:'Vui lòng nhập số tiền',
		text72:'Vui lòng chọn hệ số đòn bẩy',
		text73: 'Mất mát tối đa',
		
		text74:'Tiếp tục giao dịch',
		text75:'định cư',
		text76:'Mã xác minh của Google',
		text77:'Vui lòng nhập mã xác minh Google',
	}

}

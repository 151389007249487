export default {
	"964128-0": "天",
	"964128-1": "哎呀!",
	"964128-2": "您还没有交易记录。列表为空。",
	"652934-0": "AI量化交易",
	"652934-1": "Ai量化金额",
	"652934-2": "今日收益",
	"652934-3": "总收益",
	"652934-4": "资金安全",
	"652934-5": "收益稳定",
	"652934-6": "操作简单",
	"652934-7": "展开更多",
	"652934-8": "创建Ai量化",
	"652934-9": "期限",
	"652934-10": "金额",
	"652934-11": "回报率",
	"652934-12": "额度",
	"652934-13": "确认",
	"652934-14": "顶部信息",
	"652934-15": "主题模式",
	"652934-16": "测试---",
	account: {
		Start_making_money_plan: "開始賺錢計劃",
		Market: "市場",
		Migital_currency: "數位貨幣",
		Foreign_exchange: "外匯",
		Noble_metal: "貴金屬",
		Send_Cryop_Now: "立即發送凍結",
		Send: "傳送",
		Receive: "收到",
		Buy: "買",
		Currency_account: "貨幣帳戶",
		Contract_account: "合約帳戶",
		Transfer: "轉移",
		Select_a_wallet: "選擇錢包",
		Select_Coin: "選擇硬幣",
		Confirm: "確認",
		Wallet: "錢包",
		Availavle: "可用的",
		Frozen: "凍結",
		Convert: "轉變",
		Receiving_Address: "收貨地址",
		Amount: "數量",
		Max: "最大限度",
		Send_Now: "現在發送",
		Please_check_if: "寄送前請檢查您的收貨地址是否正確，以免造成資產損失提現費用為",
		new1: '加密貨幣',
		new2: '指數',
		new3: '大宗',
		new4: '外匯',
		new5: '總資產',
	},
	"314962-0": "Google 2FA",
	"314962-1": "下載 Google Authenticator 進行綁定",
	"314962-2": "複製驗證碼",
	"314962-3": "輸入驗證碼",
	"314962-4": "請求失敗:",
	"314962-5": "驗證碼已複製",
	"314962-6": "無法複製文字:",
	"314962-7": "複製失敗，請重試",
	"199917-0": "設定",
	"199917-1": "通知",
	"199917-2": "Google 2FA",
	"199917-3": "電子郵件",
	"199917-4": "語言",
	"199917-5": "版本",
	"199917-6": "登出",
	home: {
		USDTAccount: 'USDT 帳戶',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: '授權',
		ETHTodayProfit: 'ETH今日利潤',
		totalRevenue: '總收入',
		portfolio: '投資',
		reliableSecurity: '可靠的安全保障',
		stableReliable: '投資穩定可靠',
		convenienEasy: '操作方便簡單',
		pleaseLookForward: '敬請期待...',
		rateOfReturn: '回報率',
		walletnot: '請安裝 MetaMask 或任何以太坊擴充錢包',
		wallettip: '請綁定您的地址，以免影響體驗',
	},
	"ConnectW": "連接錢包",
	"dappbrowser": "本網站僅接受去中心化錢包DAPP瀏覽器的訪問。",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "賺取利息",
	"$": "$",
	"ETH Today's Profit": "ETH今日利潤",
	"Wallet": "錢包",
	"Record": "訂單",
	"Support": "客服",
	"General": "綜合",
	"Notifications": "通知",
	"Invite Friends": "邀請好友",
	"FAQ": "常見問題",
	"Privacy & Security": "隱私與安全",
	"Legality": "隱私政策",
	"Authenticator": "實名認證",
	"Settings": "設定",
	"Change Language": "改變語言",
	"Daytime mode": "日間模式",
	"Black mode": "夜間模式",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'開盤',
    'High':'最高',
    'Low':'最低',
    'Close':'閉盤',
    'Up':'買漲',
    'Down':'買跌',
    'Time':'時間',
    'TransactionFee':'手續費',
    'AvailableBalance':'可用餘額',
    'submit':'訂閱',
    'Direction':'方向',
    'Profit':'利潤',
    'Quantity':'數量',
    'Price':'價格',
    'different':'點擊不同比例可顯示最低限制',
    'lessthan':'至少不能小於',
	'lessthans':'不能大於',
    'least':'最少：',
    'Transactionhistory':'交易記錄',
    'Transactionrecords':'交易記錄',
    'Number':'數量',
    'Profitloss':'利潤損失',
	'Ror':'回報',
	'operate':'操作',
	'About_us': '關於我們',





	//钱包
	TransactiónDetails: '交易明細',
	dealdetails: '交易詳情',
	ViewTransactionHistory: '查看交易記錄',
	Deposit: '儲值',
	Rechargeaddress: '儲值地址：',
	DepositAmount: '存款金額：',
	EnterDepositAmount: '輸入存款金額',
	EnterHash: '輸入哈希值',
	Hash: '哈希值：',
	Voucherimage: '憑證影像：',
	Continue: '繼續',
	Withdraw: '提現',
	Withdrawal: '取回：',
	EnterAmounttoWithdraw: '輸入提款金額',
	Available: "可用的：",
	ReceiveAddress: '接收地址：',
	EnterWalletAddress: '輸入錢包位址',
	WithdrawalDesc: '以英文提現需要1%的手續費，需要網路節點確認後才能到帳，請勿將加密貨幣轉給陌生人',
	Exchange: '兌換',
	Send: '傳送:',
	Max: '最大限度:',
	rechargeAmount: '請輸入儲值金額',
	hashcode: '哈希值和截圖不能同時為空',
	correcthashcode: '請輸入正確的哈希值',
	maximumwithdrawalamount: '最大提款金額為',
	Incorrectamountformat: '金額格式不正確',
	copy: '複製',


	//tab2
	"Total Profit": "利潤總額",
	"Today's Profit": "今日利潤",
	"What is Automated Trading?": "什麼是自動交易？",
	"Learn about earning": "了解收入",
	"Staking Period": "質押期",
	"Staking Amount": "質押數量",
	"Yield": "效益",
	"Limit": "限額",
	"Subscribe": "訂閱",

	//tab3
	"Proof of Stake": "權益證明",
	"ETH Today is Price": "ETH 今日價格",
	"ETH Today's Increase": "ETH今日漲幅",
	"Joint Staking": "聯合質押",
	"Individual Staking": "個人質押",
	"tab3long1": "透過智能合約配對參與者，實現聯合下注，總下注金額達到32 ETH。",
	"tab3long2": "獨立下注不需要透過智能合約來配對參與者。 達到32 ETH的下注金額，即可完成個人下注",


	//web_view
	"webviewlong1": "加密貨幣空投有助於新興的基於區塊鏈的項目的發展。 他們的根源透過獎勵和共同目標將社區成員聯繫在一起。 成功空投的結果是更強大的社群、更好的專案以及對最終用戶的獎勵。 多年來，我們看到許多空投是在工作量證明（POW）區塊鏈上進行的。 股權證明（POS）以太坊鏈現在支援數十個網路中的數百個項目加密貨幣空投。 隨著 PoS 成為主導共識範式，以太坊網路上的空投數量並沒有顯示出放緩的跡象。",
	"webviewlong2": "透過質押您的加密貨幣，您有資格根據您質押的加密貨幣數量解鎖有價值的空投獎勵。",
	"webviewlong3": "獎勵股權只提供給權益持有者，它來自數百個不同的專案空投，其收益率遠高於任何一個獨立的空投。",
	"webviewlong4": "對於在交易所存入的加密貨幣所有者來說，空投獎勵通常很困難，而且有些託管人根本不支援空投。 這就是為什麼管理自己的加密貨幣很重要。 以太坊是一個非託管實體，可以在所有主要網路上進行質押",
	"Introduction": "介紹",
	"webviewlong5": "領先的區塊鏈平台以太坊正在透過以太坊2.0升級從工作量證明（PoW）共識機制過渡到權益證明（PoS）機制。 PoS 質押是這項轉變的重要組成部分，為用戶提供支援網路安全和營運的機會，同時有可能獲得獎勵。 本文概述了以太坊 PoS 質押及其好處。",
	"Ethereum 2.0 and Proof of Stake (PoS)": "以太坊 2.0 和權益證明 (PoS)",
	"webviewlong6": '以太坊 2.0 是一項重大網路升級，旨在提高可擴展性、安全性和永續性。 關鍵的變化之一是從能源密集的 PoW 共識機制轉向更環保的 PoS 機制。 在 PoS 中，驗證者被選擇創建新區塊並根據他們持有的加密貨幣數量並願意「抵押」作為抵押品來確認交易。',
	"The Staking Process": "質押過程",
	"webviewlong7": "要參與以太坊 PoS 質押，用戶必須透過將其存入以太坊 2.0 存款合約來質押至少 32 ETH。 一旦質押，ETH 就會被鎖定一段時間，並作為確保網路安全的抵押品。 驗證者是根據抵押的 ETH 數量及其線上活動來選擇的。 他們負責提議和驗證新區塊，以及確認網路上的交易。",
	"Staking Rewards and Risks": "質押獎勵和風險",
	"webviewlong8": "透過參與 PoS 質押，用戶可以獲得新鑄造的 ETH 和交易費用形式的獎勵。 獎勵基於質押的 ETH 數量和整體網路活動。 然而，質押也伴隨著風險，例如，如果驗證者行為惡意或未能持續保持在線，則可能會受到處罰。 此外，質押的 ETH 會被鎖定一段時間，使其缺乏流動性並容易受到價格波動的影響。",
	"Joint Staking and Individual Staking": "聯合質押與個人質押",
	"webviewlong9": "對於沒有足夠 32 ETH 單獨質押的用戶來說，聯合質押提供了另一種選擇。 在聯合質押中，多個用戶將他們的 ETH 集中在一起以達到所需的質押金。 這種池化通常透過智能合約或質押服務來促進，允許用戶以較小數量的 ETH 參與以太坊 PoS 質押。",
	"Conclusion": "結論",
	"webviewlong10": "以太坊 PoS 質押是以太坊 2.0 升級的一個重要方面，為用戶提供支援網路安全、改善去中心化和賺取獎勵的機會。 透過了解質押過程、其回報和風險，用戶可以就參與以太坊網路的這一關鍵演變做出明智的決定。",


	//mywallet
	"Total": "總額",

	//record
	"Account": "帳戶",
	"interest-bearing": "利息",
	"POS Staking": "POS 質押",
	"convert": "兌換",
	"transaction": "交易",
	"pledge": "抵押",
	"recordno": "你還沒有任何消息。 該列表為空。",
	"Oops": "哎呀",
	"recharge": "儲值",
	"payment": "支付",
	"staking": "質押",
	"income": "收益",
	"Pledge quantity": "質押數量",
	"cycle": "週期",
	"Days Remaining": "剩餘天數",
	"cumulative gain": "累積增益",
	"Price increase": "價格上漲",
	"Price decrease": "價格下降",
	"Opening price": "開盤價",
	"Closing price": "收盤價",
	"loss": "損失",
	"pfofit": "利潤",
	"amount": "數量",

	//notifiCations
	"notifiCationslong1": "註：VIP註冊是根據對應整體帳戶的儲值金額來決定您的等級。 此區間需扣除現有帳戶提領後的總儲值金額來決定會員等級。",
	"notifiCationslong2": "會員期限也根據不同等級進行劃分。 為維持您的會員資格，您需要在規定時間內每次充值至少5,000 USDT才能享有會員權益。 每次儲值會員資格後，週期重新計算並可累積。",
	"notifiCationslong3": "客戶服務依會員等級進行劃分。 官方客服由APP工作人員提供，為廣大會員提供統一服務。 專屬秘書數量有限，保證更好的服務。 專屬私人秘書為使用者提供專屬接待服務和理財建議。",
	"notifiCationslong4": "提前聯絡客服預約儲值。 在規定時間內完成儲值，可依對應會員等級額外獲得預約儲值獎勵。",
	"notifiCationslong5": "VIP1：基於UID帳戶，累計儲值10,000 USD，可獲得177 USDT獎勵。 達到VIP等級並獲得普通會員勳章後，積分將空投至帳戶。",
	"notifiCationslong6": "VIP2：基於UID帳戶，累計儲值達30,000 USD，送777 USDT。 獎金金額將在達到VIP等級並獲得銅牌會員勳章後立即存入帳戶。",
	"notifiCationslong7": "VIP3：根據UID帳戶，累計儲值7萬美元，即可獲贈1777 USDT。 達到VIP等級並獲得銀卡會員勳章後，積分將空投至帳戶。 此外，您將在下次預訂儲值金額的基礎上獲得額外1.5%的獎勵。",
	"notifiCationslong8": "VIP4：根據UID帳戶，累計儲值15萬美元，獲贈2,777 USDT。 達到VIP等級並獲得金牌會員勳章後，積分將空投至帳戶。 此外，您將在下次預訂儲值金額上獲得額外 2% 的獎勵。",
	"notifiCationslong9": "VIP5：基於UID帳戶，累計儲值達300,000 USD，贈送4777 USDT。 達到VIP等級並獲得鑽石VIP徽章後，積分將空投至帳戶。 此外，下次預訂儲值金額還將額外獲得2.5%的獎勵。",
	"notifiCationslong10": "VIP6：基於UID帳戶，累計儲值達800,000 USD，贈送16,888 USDT。 獎金金額將在達到VIP等級並獲得黑鑽會員徽章後立即空投至帳戶，並額外獲得下次預登記充值金額的3%。 您將有一名私人專屬秘書來提供任何財務建議或處理稅務問題。",
	"notifiCationslong11": "VIP7：基於UID帳戶，累計儲值金額達300萬美元，贈送15天歐洲豪華之旅（有折扣）。 授予CoinBpos全球終身榮譽VIP，並獲得皇冠會員勳章。 下次預約儲值將可額外獲得3.5%的獎勵。",
	"notifiCationslong12": "VIP8：根據UID帳戶，累計儲值達800萬美元，可獲得CoinBpos摩洛哥年宴禮券一張。 獎勵：1 BTC，並獲得CoinBpos全球終身榮譽VIP。 下次預約儲值可額外獲得4%獎勵。",
	"notifiCationslong13": "VIP9：基於UID帳戶，累計入3000萬美元，即可獲得CoinBpos 3%的股份。 並享有每年3%的利潤分紅。 免費贈送 Cronix 純金紀念徽章。",

	//invite
	"Refer and Earn": "推薦並賺取",
	"Copy Link": "複製連結",

	//faq
	"Frequently Asked Questions": "經常問的問題",

	//authenticator
	"mailbox": "信箱",
	"Front photo": "正面照片",
	"Back photo": "背面照片",
	"Handheld ID photo": "手持證件照",
	"define": "確定",

	//legality
	"legalitylong1": "Coinbpos由多家加密貨幣公司組成，他們共同提供工具來幫助加密社群創建、發展、維護社群穩定，為數以千計的去中心化應用程式提供動力，推動加密貨幣的未來以及推動傳統貨幣的數位化和促進全球經濟繁榮。",
	"legalitylong2": "Coinbpos 內的每家公司均充當處理與其服務相關的個人資料的資料控制者，每項服務的資料控制者是：",
	"legalitylong3": "1.區塊鏈技術－以太坊區塊鏈。",
	"legalitylong4": "2.去中心化錢包-Trust錢包、MetaMask和加密錢包。",
	"legalitylong5": "3. 交易技術-Coinbase、Crypto、linch、Binance。",
	"legalitylong6": "隱私政策詳細說明了Coinbpos如何根據其在相關數據保護法律下的義務處理個人數據，包括歐盟的《通用數據保護條例》（GDPR），",
	"legalitylong7": '《加州消費者隱私法》（CCPA）和其他一般法律（統稱為「資料保護法」*）。',

	//language
	"language": "語言",
	"Confirm": "確認",
	"Cancel":'取消',

	"newtext1": "退出",
	"newtext2": "取消",
	"newtext3": "確定退出此帳號嗎？",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "登錄",
	"newtext9": "用戶名",
	"newtext10": "手機號",
	"newtext11": "請輸入用戶名",
	"newtext12": "請輸入手機號",
	"newtext13": "密碼",
	"newtext14": "請輸入密碼",
	"newtext15": "還沒有帳戶",
	"newtext16": "注冊",
	"newtext17": "所在國家",
	"newtext18": "選擇國家",
	"newtext19": "手機號驗證碼",
	"newtext20": "請輸入驗證碼",
	"newtext21": "郵箱地址",
	"newtext22": "請輸入郵箱地址",
	"newtext23": "確認密碼",
	"newtext24": "請輸入確認密碼",
	"newtext25": "擁有一個帳戶",
	"newtext26": "發送驗證碼",
	"newtext27": "手機號不正確",
	"newtext28": "發送成功",
	"newtext29": "注冊成功",

	"newtext30": '貨幣',
	"newtext31": '外匯',
	"newtext32": '貴金屬',

	"newtext33": '賬戶',
	"newtext34": '平臺活動',
	"newtext35": '在线客服',
	"newtext36": '邀請',
	"newtext37": 'KYC 驗證',
	"newtext38": '設置',
	wallet_p: {
		text1: "複製位址",
		text2: '確認',
		text3: '從...交換',
		text4: "交換至",
		text5: "最大",
		text6: "邀請",
		text7: '獲得推薦獎勵',
		text8: "當你的朋友參與AI套利並租用礦機時，你可以獲得推薦獎勵",
		text9: "複製",
		text10: '分享連結',
		text11: "開始驗證",
		text12: "國家",
		text13: "請選擇一個國家",
		text14: "名字",
		text15: "請輸入您的名字",
		text16: "姓氏",
		text17: "請輸入您的姓氏",
		text18: "證書類型",
		text19: "請選擇證書類型",
		text20: "證書號碼",
		text21: "請輸入證書號碼",
		text22: "請上傳您身份證的正面照片",
		text23: "請上傳您身份證的反面照片",
		text24: "請上傳您護照的正面照片",
		text25: "請上傳您護照的反面照片",
		text26: "電子郵件",
		text27: "請輸入您的電子郵件",
		text28: "發送",
		text29: "驗證碼",
		text30: "請輸入您的驗證碼",
		text31: "身份證",
		text32: "護照",
		text33: "取消",
		text34: "國家/地區",
		text35: "請輸入正確的信息以進行驗證提交",
		text36: "成功發送！",
		text37: "電子郵件格式錯誤！",
		text38: "駕照",
		text39: "請上傳您駕照的正面照片",
		text40: "請上傳您駕照的反面照片",

		text41: "身份證號碼",
		text42: "請輸入身份證號碼",
		text43: "護照號碼",
		text44: "請輸入護照號碼",
		text45: "駕照號碼",
		text46: "請輸入駕照號碼",
	},
	jiaoyi: {
		text1: '秒合約',
		text2: '永續合約',
		text3: '現貨交易',
		text4: '立即委託',
		text5: '帳戶類型',
		text6: '交貨時間',
		text7: '價格區間',
		text8: '購買價格',
		text9: '費用',
		text10: '余额',
		text11: '至少',
		text12: '預期',
		text13: '購買金額',
		text14: '方向',
		text15: '購買價格',
		text16: '即時價格',
		text17: '倒數計時',
		text18: '我的合約',
		text19: '利潤',
		text20: '合約',
		text21: '交貨價格',
		text22: '交貨時間',
		text23: '市價',
		text24: '涨',
		text25: '跌',
		text26: '購買價格',
		text27: '最大',
		text28: '利潤',
		text29: '手續費',
		text30: '買入',
		text31: '賣出',
		text32: '購買數量',
		text33: '真實帳戶',
		text34: '虛擬帳戶',
		text35: '暫無數據',
		text36: '當前持倉',
		text37: '歷史委託',
		text38: '平倉',
		text39: '保證金',
		text40: '盈虧',
		text41: '持有',
		text42: '買進紀錄',
		text43: '賣出記錄',
		text44: '可用',
		text45: '折合',
		text46: '認證成功',
		text47: '購買時間',
		text48: '未讀',
		text49: '已讀',
		text50: '托管訂單',
		text51: '總套利',
		text58: '今日收益',
		text52: '人工智慧機器人是如何工作的',
		text53: '套利產品',
		text54: '天',
		text55: '金額',
		text56: '每日收入',
		text57: '貨幣類型',
		text59: '加入AI套利',
		text60: '零風險，快速回報',
		text61: 'AI套利',
		text62: '預期收益',
		text63: '套利硬幣類型',
		text64: '托管金額',
		text65: '立即托管',
		text66: '每日收入發送到您的USDT錢包',
		text67: '投資基金零風險',
		text68: '您可以隨時取回資金',
		text69: '人工智能每天24小時工作',
		text70: '累計收益',
		text71: '到期時間',
		text72: '全部',
		text73: '進行中',
		text74: '已完成',
		text75: '託管詳情',
		text76: '選擇幣種',
		text77: '技術支持',
		text78: '發送郵件',
	},
	tabbar:{
		text1:'首頁',
		text2:'市場',
		text3:'Ai量化',
		text4:'貸款',
		text5:'帳戶',
		text6:'帳戶餘額',
		text7:'今日收益',
		text8:'回報率',
		text9:'更多',
		text10:'即將推出',
		text11:'新幣預售',
		text12:'踏上數字財富之旅',
		text13:'立即購買',
		text14:'操作簡單',
		text15:'AI量化交易',
		text16:'安全 / 穩定 / 簡單',
		text17:'建立交易',
		text18:'總佣金',
		text19:'與你的朋友分享此鏈接，可獲得高達 $200 的獎勵。 ',
		text20:'推薦連結',
		text21:'透過社群軟體與您的朋友分享',
	},
	tab4:{
		text1: "賬戶餘額",
		text2: "今日收益",
		text3: "AI量化",
		text4: "回報率",
		text5: "存款",
		text6: "電匯",
		text7: "提款",
		text8: "存款",
		text9: "錢包地址",
		text10: "複製",
		text11: "上傳憑證",
		text12: "請仔細檢查存款地址以確保正確，因為錯誤的轉帳可能無法撤銷。存款需要獲得確認才能記入您的帳戶。",
		text13: "電匯",
		text14: "發起電匯前:",
		text15: "在發起電匯之前，請聯繫我們的客戶服務團隊獲取準確的電匯賬戶信息。這一步確保您資金的安全和順利到賬。",
		text16: "電匯處理時間:",
		text17: "此條目與text15重複，但通常不會這樣設置，可能是一個錯誤。正確的情況下，這裡應該描述處理時間的細節。", // 假設修正為處理時間的描述
		text18: "電匯過程中的協助:",
		text19: "如果您在電匯過程中遇到任何問題或有疑問，請隨時聯繫我們的客戶服務團隊。我們致力於提供協助和支持，確保為您提供一個順暢的交易體驗。",
		text20: "金額",
		text21: "提款貨幣",
		text22: "確認",
		text23: "請務必確認您的接收地址，因為錯誤的轉帳是無法撤銷的。並需要在幾分鐘確認之後才能記入您的帳戶。",
		text24: "期權訂單",
		text25: "合約訂單",
		text26: "AI量化訂單",
		text27: "歷史記錄",
		text28: "邀請好友",
		text29: "常見問答",
		text30: "網路客服",
		text31: "關於我們",
		text32: "語言更改",
		text33: "退出登录"
	},
	uploadProof: {
		text1: "上傳憑證",
		text2: "選擇圖片",
		text3: "預覽",
		text4: "上傳您的存款截圖",
		text5: "金額",
		text6: "交易哈希",
		text7: "後6位數字",
		text8: "請上傳您的加密貨幣支付截圖，並在提交後等待審核",
		text9: "確認"
	},
	newadd:{
		text1:'成功',
		text2:'失敗',
		text3:'待辦',
		text4:'我的 Cown 代幣',
		text5:'供應',
		text6:'已售出',
		text7:'預售結束',
		text8:'金額',
		text9:'兌換',
		text10:'技術特點',
		text11:'新幣採用區塊鏈技術，具有快速交易速度、低手續費、高交易安全性，以及智慧合約功能和隱私保護技術。 ',
		text12:'專案願景',
		text13:'新幣的願景是成為全球領先的數位資產交易平台，為用戶提供更安全、便利和高效的數位資產交易服務。 ',
		text14:'應用場景',
		text15:'新幣具有廣泛的應用場景，包括數位貨幣交易、數位證券交易、數位資產交易、支付結算、供應鏈金融等領域。 ',
		text16:'貸款額度',
		text17:'驗證您的身分並獲得更多服務！ ',
		text18:'開始驗證',
		text19:'我想藉...',
		text20:'貸款期限',
		text21:'天',
		text22:'日利率',
		text23:'總利息金額',
		text24:'貸款後 1 天內無需支付利息，之後需支付利息。 ',
		text25:'現在藉款',
		text26:'借款記錄',
		text27:'尚未藉款',
		text28:'找不到您的貸款資訊',
		text29:'超過貸款額度範圍',
		text30:'申請中',
		text31:'進行中',
		text32:'已結束',
		text33:'開啟',
		text34:'關閉',
		text35:'記錄',
		text36:'存款',
		text37:'提款',
		text38:'哎呀!',
		text39:'您還沒有交易記錄。列表為空。 ',
		text40:'請輸入金額',
		text41:'正常',
		text42:'已結束',
		text43:'郵箱',
		text44:'郵箱驗證碼',
		text45:'在DAPP上確認付款',
		text46:'儲值錯誤，請重試或聯絡客服',
		text47:'儲值申請已提交',
		text48:'審核中',
		text49:'審核通過',
		
		//关于我们
		text50:'HKEX是全球知名的數宇貨幣交易服務平台，HKEX支持多種數宇資產交易。成立於2019年6月，已成長為最受歡迎的數宇貨幣交易服務平台之一。',
		text51:'目前為全球35個國家和地區的超100萬用戶提供幣幣、法幣、合約、借貨等一站式服務。以“最智能快捷的交易服務平台”著稱。',
		text52:'HKEX的運營地為香港，為用戶提供多語言、7×24小時客服團隊，同時，HKEX在加拿大、法國、新加坡、美國、韓國、日本、意大利、土耳其、俄羅斯、印度等地建立了本地化社群，為各地用戶提供最本地化的服務。 2020年1月，HKEX獲得來自加拿大KTR資本和鯤鵬創投的1.5億美元A輪融資。 HKEX交易所目前深耕國際市場多年，在全球近30個國家擁有百萬用戶。',
		text53:'自2022年5月以來HKEX亞太站團隊人員為進入中文市場，在過去關鍵的幾個月時間內全員發力為做好極致智能產品而不斷夯實，並在各大中文社區內引起很大關注度。 HKEX交易所國繞“期權＋合約”的發展戰略，嚴守合規底線，為全球用戶提供以“期權交易、合約交易”為主的數字資產金融服務，助力全球數宇金融流動性發展。',
		//关于我们 end
		
		text54:'用戶協議通知',
		text55:'法律聲明',
		text56:'OTC交易規則',
		text57:'操作提幣風險告知書',
		
		text58:'權重範圍',
		  text59:'配額範圍',
		  text60:'最大利潤',
		  text61:'預期報酬率',
		  text62:'選擇交易區域',
		  text63:'選擇硬幣',
		  text64:'槓桿乘數',
		  text65:'選擇乘數',
		  text66:'計劃金額',
		  text67:'請輸入計畫金額',
		  text68:'選擇關卡計劃',
		  text69:'您目前的權重分數是',
		  text70:'該槓桿比例尚未解鎖',
		  text71:'請輸入金額',
		  text72:'請選擇槓桿倍數',
		  text73:'最大損失',
		
		text74:'繼續交易',
		text75:'結算中',
		text76:'Google驗證碼',
		text77:'請輸入Google驗證碼',
	}
}

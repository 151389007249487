//泰语
export default {
	account: {
	    Start_making_money_plan: "เริ่มแผนการทำเงิน",
	    Market: "ตลาด",
	    Migital_currency: "สกุลเงินดิจิทัล",
	    Foreign_exchange: "การแลกเปลี่ยนเงินตราต่างประเทศ",
	    Noble_metal: "โลหะมีค่า",
	    Send_Cryop_Now: "ส่งการแช่แข็งทันที",
	    Send: "ส่ง",
	    Receive: "รับ",
	    Buy: "ซื้อ",
	    Currency_account: "บัญชีสกุลเงิน",
	    Contract_account: "บัญชีสัญญา",
	    Transfer: "โอน",
	    Select_a_wallet: "เลือกกระเป๋าเงิน",
	    Select_Coin: "เลือกเหรียญ",
	    Confirm: "ยืนยัน",
	    Wallet: "กระเป๋าเงิน",
	    Availavle: "ใช้ได้",
	    Frozen: "ถูกแช่แข็ง",
	    Convert: "แปลง",
	    Receiving_Address: "ที่อยู่ผู้รับ",
	    Amount: "จำนวน",
	    Max: "สูงสุด",
	    Send_Now: "ส่งตอนนี้",
	    Please_check_if: "กรุณาตรวจสอบที่อยู่ผู้รับของคุณให้ถูกต้องก่อนส่ง เพื่อป้องกันการสูญเสียทรัพย์สิน ค่าธรรมเนียมการถอนคือ",
	    new1: 'สกุลเงินดิจิทัล',
	    new2: 'ดัชนี',
	    new3: 'สินค้าโภคภัณฑ์',
	    new4: 'การแลกเปลี่ยนเงินตราต่างประเทศ',
	    new5: 'สินทรัพย์ทั้งหมด',
	},
	"314962-0": "Google 2FA",
	"314962-1": "ดาวน์โหลด Google Authenticator เพื่อทำการเชื่อมต่อ",
	"314962-2": "คัดลอกรหัสยืนยัน",
	"314962-3": "กรอกรหัสยืนยัน",
	"314962-4": "คำขอล้มเหลว:",
	"314962-5": "รหัสยืนยันถูกคัดลอกแล้ว",
	"314962-6": "ไม่สามารถคัดลอกข้อความได้:",
	"314962-7": "คัดลอกไม่สำเร็จ โปรดลองอีกครั้ง",
	"199917-0": "การตั้งค่า",
	"199917-1": "การแจ้งเตือน",
	"199917-2": "Google 2FA",
	"199917-3": "อีเมล",
	"199917-4": "ภาษา",
	"199917-5": "เวอร์ชัน",
	"199917-6": "ออกจากระบบ",
	home: {
		USDTAccount: 'บัญชี USDT',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: 'อนุญาต',
		ETHTodayProfit: 'กำไร ETH วันนี้',
		totalRevenue: 'รายได้รวม',
		portfolio: 'ลงทุน',
		reliableSecurity: 'รับประกันความปลอดภัยที่เชื่อถือได้',
		stableReliable: 'การลงทุนที่มั่นคงและเชื่อถือได้',
		convenienEasy: 'สะดวกและใช้งานง่าย',
		pleaseLookForward: 'โปรดตั้งตารอที่จะ...',
		rateOfReturn: 'อัตราการตอบสนอง',
		walletnot: 'โปรดติดตั้ง MetaMask หรือกระเป๋าเงินส่วนขยาย Ethereum',
		wallettip: 'กรุณาผูกที่อยู่ของคุณเพื่อหลีกเลี่ยงผลกระทบต่อประสบการณ์',
	},
	"ConnectW": "เชื่อมต่อกระเป๋าสตางค์",
	"dappbrowser": "เว็บไซต์นี้ยอมรับการเข้าถึงจากเบราว์เซอร์ DAPP กระเป๋าเงินแบบกระจายอำนาจเท่านั้น",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "รับดอกเบี้ย",
	"$": "$",
	"ETH Today's Profit": "กำไร ETH วันนี้",
	"Wallet": "กระเป๋าสตางค์",
	"Record": "คำสั่ง",
	"Support": "บริการลูกค้า",
	"General": "ครอบคลุม",
	"Notifications": "แจ้ง",
	"Invite Friends": "เชิญเพื่อน ๆ",
	"FAQ": "ปัญหาทั่วไป",
	"Privacy & Security": "ความเป็นส่วนตัวและความปลอดภัย",
	"Legality": "นโยบายความเป็นส่วนตัว",
	"Authenticator": "ตรวจสอบแล้ว",
	"Settings": "ติดตั้ง",
	"Change Language": "เปลี่ยนภาษา",
	"Daytime mode": "โหมดวัน",
	"Black mode": "โหมดกลางคืน",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",
	"964128-0": "วัน",
	"964128-1": "โอ้!",
	"964128-2": "คุณยังไม่มีประวัติการซื้อขาย รายการว่างเปล่า",
	"652934-0": "การซื้อขาย AI ปริมาณ",
	"652934-1": "จำนวน AI ปริมาณ",
	"652934-2": "กำไรในวันนี้",
	"652934-3": "กำไรรวม",
	"652934-4": "ความปลอดภัยของเงิน",
	"652934-5": "กำไรที่มั่นคง",
	"652934-6": "การดำเนินการง่าย",
	"652934-7": "แสดงเพิ่มเติม",
	"652934-8": "สร้าง AI ปริมาณ",
	"652934-9": "ระยะเวลา",
	"652934-10": "จำนวน",
	"652934-11": "อัตราผลตอบแทน",
	"652934-12": "โควตา",
	"652934-13": "ยืนยัน",
	"652934-14": "ข้อมูลด้านบน",
	"652934-15": "โหมดธีม",
	"652934-16": "ทดสอบ---",
	// marketInfo.vue
	'Open': 'เปิด',
	'High': 'สูงสุด',
	'Low': 'ต่ำสุด',
	'Close': 'ปิด',
	'Up': 'ซื้อ',
	'Down': 'ซื้อหรือขาย',
	'Time': 'เวลา',
	'TransactionFee': 'ค่าธรรมเนียมการจัดการ',
	'AvailableBalance': 'ยอดเงินคงเหลือ',
	'submit': 'สมัครสมาชิก',
	'Direction': 'ทิศทาง',
	'Profit': 'กำไร',
	'Quantity': 'ปริมาณ',
	'Price': 'ราคา',
	'different': 'คลิกที่มาตราส่วนต่างๆ เพื่อแสดงขีดจำกัดขั้นต่ำ',
	'lessthan': 'อย่างน้อยไม่ต่ำกว่า',
	'least': 'น้อยที่สุด:',
	'Transactionhistory': 'บันทึกการทำธุรกรรม',
	'Transactionrecords': 'บันทึกการทำธุรกรรม',
	'Number': 'ปริมาณ',
	'Profitloss': 'การสูญเสียกำไร',
	'Ror': 'กลับ',
	'operate': 'ดำเนินงาน',
	'About_us': 'เกี่ยวกับเรา',

	//钱包
	TransactiónDetails: 'รายละเอียดการทำธุรกรรม',
	dealdetails: 'รายละเอียดการทำธุรกรรม',
	ViewTransactionHistory: 'ดูประวัติการทำธุรกรรม',
	Deposit: 'เติม',
	Rechargeaddress: 'ที่อยู่เติมเงิน:',
	DepositAmount: 'จำนวนเงินฝาก:',
	EnterDepositAmount: 'กรอกจำนวนเงินฝาก',
	EnterHash: 'ป้อนค่าแฮช',
	Hash: 'ค่าแฮช:',
	Voucherimage: 'ภาพบัตรกำนัล:',
	Continue: 'ดำเนินการต่อ',
	Withdraw: 'ถอน',
	Withdrawal: 'ดึงข้อมูล:',
	EnterAmounttoWithdraw: 'ป้อนจำนวนเงินที่ถอน',
	Available: "ใช้งานได้:",
	ReceiveAddress: 'ที่อยู่รับ:',
	EnterWalletAddress: 'ป้อนที่อยู่กระเป๋าสตางค์',
	WithdrawalDesc: 'การถอนเงินเป็นภาษาอังกฤษต้องเสียค่าธรรมเนียมการจัดการ 1% และต้องได้รับการยืนยันจากโหนดเครือข่ายก่อนจึงจะเครดิตได้ โปรดอย่าโอนสกุลเงินดิจิตอลให้กับคนแปลกหน้า',
	Exchange: 'แลกเปลี่ยน',
	Send: 'เทเลพอร์ต:',
	Max: 'ขีดสุด:',
	rechargeAmount: 'กรุณากรอกจำนวนเงินที่เติม',
	hashcode: 'ค่าแฮชและภาพหน้าจอไม่สามารถเว้นว่างได้ในเวลาเดียวกัน',
	correcthashcode: 'โปรดป้อนค่าแฮชที่ถูกต้อง',
	maximumwithdrawalamount: 'จำนวนเงินถอนสูงสุดคือ',
	Incorrectamountformat: 'รูปแบบจำนวนเงินไม่ถูกต้อง',
	copy: 'สำเนา',


	//tab2
	"Total Profit": "รวมกำไร",
	"Today's Profit": "กำไรวันนี้.",
	"What is Automated Trading?": "การซื้อขายอัตโนมัติคืออะไร?",
	"Learn about earning": "เรียนรู้เกี่ยวกับรายได้",
	"Staking Period": "ระยะเวลาจำนำ",
	"Staking Amount": "ปริมาณจำนำ",
	"Yield": "ผลประโยชน์",
	"Limit": "ขีด จำกัด",
	"Subscribe": "สมัครสมาชิก",

	//tab3
	"Proof of Stake": "หลักฐานการเดิมพัน",
	"ETH Today is Price": "ราคา ETH วันนี้",
	"ETH Today's Increase": "ETH เพิ่มขึ้นในวันนี้",
	"Joint Staking": "ร่วมจำนำ",
	"Individual Staking": "คำมั่นสัญญาส่วนบุคคล",
	"tab3long1": "ผู้เข้าร่วมจะถูกจับคู่ผ่านสัญญาอันชาญฉลาดเพื่อรับรู้การเดิมพันร่วม โดยยอดเดิมพันทั้งหมดจะสูงถึง 32 ETH",
	"tab3long2": "การเดิมพันอิสระไม่จำเป็นต้องมีสัญญาอันชาญฉลาดในการจับคู่ผู้เข้าร่วม  การเดิมพันส่วนบุคคลสามารถเสร็จสิ้นได้เมื่อจำนวนเงินเดิมพันถึง 32 ETH",


	//web_view
	"webviewlong1": "การ Airdrops ของ Cryptocurrency ช่วยการเติบโตของโครงการที่ใช้บล็อคเชนที่เกิดขึ้นใหม่  รากฐานของพวกเขาเชื่อมโยงสมาชิกในชุมชนผ่านรางวัลและเป้าหมายที่มีร่วมกัน  ผลลัพธ์ของการแจกแจงที่ประสบความสำเร็จคือชุมชนที่เข้มแข็งขึ้น โครงการที่ดีขึ้น และรางวัลสำหรับผู้ใช้ปลายทาง  ในช่วงหลายปีที่ผ่านมา เราได้เห็นการแจกแจงทางอากาศหลายครั้งบนบล็อกเชน Proof-of-Work (POW)  ขณะนี้เครือข่าย Ethereum แบบ Proof-of-Stake (POS) รองรับการ Airdrops ของสกุลเงินดิจิทัลสำหรับโครงการหลายร้อยโครงการในเครือข่ายหลายสิบเครือข่าย  เนื่องจาก PoS กลายเป็นกระบวนทัศน์ฉันทามติที่โดดเด่น จำนวนการแอร์ดรอปบนเครือข่าย Ethereum จึงไม่แสดงสัญญาณของการชะลอตัว",
	"webviewlong2": "ด้วยการปักหลักสกุลเงินดิจิทัลของคุณ คุณจะมีสิทธิ์ปลดล็อกรางวัล Airdrop อันมีค่าตามจำนวนสกุลเงินดิจิทัลที่คุณเดิมพัน",
	"webviewlong3": "ส่วนของรางวัลจะมีให้เฉพาะผู้ถือทุนเท่านั้น และมาจากโปรเจ็กต์การแจกรางวัลที่แตกต่างกันหลายร้อยรายการ โดยมีอัตราผลตอบแทนที่สูงกว่าการแจกรางวัลอิสระใดๆ มาก",
	"webviewlong4": "รางวัล Airdropping มักจะเป็นเรื่องยากสำหรับเจ้าของสกุลเงินดิจิทัลที่ฝากเงินในการแลกเปลี่ยน และผู้ดูแลบางคนไม่สนับสนุน Airdrops เลย  นี่คือเหตุผลว่าทำไมการจัดการสกุลเงินดิจิทัลของคุณเองจึงมีความสำคัญ  Ethereum เป็นนิติบุคคลที่ไม่ได้รับการคุ้มครองและสามารถเดิมพันได้บนเครือข่ายหลักทั้งหมด",
	"Introduction": "แนะนำ",
	"webviewlong5": "Ethereum ซึ่งเป็นแพลตฟอร์มบล็อกเชนชั้นนำ กำลังเปลี่ยนจากกลไกฉันทามติ Proof-of-Work (PoW) ไปเป็นกลไก Proof-of-Stake (PoS) ผ่านการอัปเกรด Ethereum 2.0 การวางเดิมพัน PoS เป็นส่วนสำคัญของการเปลี่ยนแปลงนี้ โดยมอบโอกาสให้ผู้ใช้สนับสนุนความปลอดภัยเครือข่ายและการดำเนินงานไปพร้อมๆ กับการรับรางวัล  บทความนี้จะให้ภาพรวมของการปักหลัก Ethereum PoS และคุณประโยชน์ของมัน",
	"Ethereum 2.0 and Proof of Stake (PoS)": "Ethereum 2.0 และหลักฐานการเดิมพัน (PoS)",
	"webviewlong6": 'Ethereum 2.0 เป็นการอัปเกรดเครือข่ายหลักที่ออกแบบมาเพื่อปรับปรุงความสามารถในการปรับขนาด ความปลอดภัย และความยืดหยุ่น  การเปลี่ยนแปลงที่สำคัญประการหนึ่งคือการย้ายจากกลไกฉันทามติ PoW ที่ประหยัดพลังงานไปเป็นกลไก PoS ที่เป็นมิตรต่อสิ่งแวดล้อมมากขึ้น  ใน PoS ผู้ตรวจสอบจะถูกเลือกให้สร้างบล็อกใหม่และยืนยันธุรกรรมตามจำนวนสกุลเงินดิจิทัลที่พวกเขาถืออยู่และเต็มใจที่จะ "เดิมพัน" เป็นหลักประกัน',
	"The Staking Process": "กระบวนการจำนำ",
	"webviewlong7": "หากต้องการเข้าร่วมการวางเดิมพัน Ethereum PoS ผู้ใช้จะต้องเดิมพันอย่างน้อย 32 ETH โดยฝากไว้ในสัญญาฝาก Ethereum 2.0  เมื่อเดิมพันแล้ว ETH จะถูกล็อคเป็นระยะเวลาหนึ่งและทำหน้าที่เป็นหลักประกันเพื่อรับรองความปลอดภัยของเครือข่าย  ผู้ตรวจสอบจะถูกเลือกตามจำนวน ETH ที่วางเดิมพันและกิจกรรมออนไลน์ของพวกเขา  พวกเขามีหน้าที่รับผิดชอบในการเสนอและตรวจสอบบล็อคใหม่ รวมถึงการยืนยันธุรกรรมบนเครือข่าย",
	"Staking Rewards and Risks": "การปักหลักรางวัลและความเสี่ยง",
	"webviewlong8": "ด้วยการเข้าร่วมการวางเดิมพัน PoS ผู้ใช้สามารถรับรางวัลในรูปแบบของ ETH ที่เพิ่งสร้างใหม่และค่าธรรมเนียมการทำธุรกรรม  รางวัลจะขึ้นอยู่กับจำนวน ETH ที่วางเดิมพันและกิจกรรมเครือข่ายโดยรวม  อย่างไรก็ตาม การปักหลักมาพร้อมกับความเสี่ยง เช่น บทลงโทษที่อาจเกิดขึ้นหากผู้ตรวจสอบกระทำการที่มุ่งร้ายหรือไม่สามารถออนไลน์ได้อย่างต่อเนื่อง  นอกจากนี้ ETH ที่เดิมพันไว้จะถูกล็อคเป็นระยะเวลาหนึ่ง ทำให้ไม่มีสภาพคล่องและเสี่ยงต่อความผันผวนของราคา",
	"Joint Staking and Individual Staking": "คำมั่นสัญญาร่วมและคำมั่นสัญญาส่วนบุคคล",
	"webviewlong9": "สำหรับผู้ใช้ที่มี 32 ETH ไม่เพียงพอที่จะเดิมพันเป็นรายบุคคล การร่วมเดิมพันถือเป็นอีกทางเลือกหนึ่ง  ในการวางเดิมพันร่วมกัน ผู้ใช้หลายรายจะรวม ETH ของตนเข้าด้วยกันเพื่อให้ได้เงินฝากตามที่กำหนด  การรวมกลุ่มนี้มักได้รับการอำนวยความสะดวกผ่านสัญญาอัจฉริยะหรือบริการปักหลัก ทำให้ผู้ใช้สามารถเข้าร่วมในการวางเดิมพัน Ethereum PoS ด้วย ETH จำนวนน้อยกว่า",
	"Conclusion": "สรุปแล้ว",
	"webviewlong10": "การวางเดิมพัน Ethereum PoS เป็นส่วนสำคัญของการอัพเกรด Ethereum 2.0 โดยมอบโอกาสให้ผู้ใช้สนับสนุนความปลอดภัยของเครือข่าย ปรับปรุงการกระจายอำนาจ และรับรางวัล  ด้วยการทำความเข้าใจกระบวนการปักหลัก รางวัล และความเสี่ยง ผู้ใช้สามารถตัดสินใจอย่างมีข้อมูลเกี่ยวกับการเข้าร่วมในวิวัฒนาการที่สำคัญของเครือข่าย Ethereum",


	//mywallet
	"Total": "เงินก้อน",

	//record
	"Account": "บัญชี",
	"interest-bearing": "ความสนใจ",
	"POS Staking": "คำมั่นสัญญา POS",
	"convert": "แลกเปลี่ยน",
	"transaction": "ซื้อขาย",
	"pledge": "จำนอง",
	"recordno": "คุณยังไม่ได้ยินอะไรเลย  รายการว่างเปล่า",
	"Oops": "อ๊ะ",
	"recharge": "เติม",
	"payment": "จ่าย",
	"staking": "จำนำ",
	"income": "รายได้",
	"Pledge quantity": "ปริมาณจำนำ",
	"cycle": "วงจร",
	"Days Remaining": "จำนวนวันที่เหลืออยู่",
	"cumulative gain": "กำไรสะสม",
	"Price increase": "ราคาเพิ่มขึ้น",
	"Price decrease": "ราคาตก",
	"Opening price": "ราคาเปิด",
	"Closing price": "ราคาปิด",
	"loss": "การสูญเสีย",
	"pfofit": "กำไร",
	"amount": "ปริมาณ",

	//notifiCations
	"notifiCationslong1": "หมายเหตุ: การลงทะเบียน VIP จะกำหนดระดับของคุณตามมูลค่าที่เก็บไว้ของบัญชีโดยรวมที่เกี่ยวข้อง  ช่วงนี้จะต้องหักมูลค่าที่เก็บไว้ทั้งหมดหลังจากถอนออกจากบัญชีที่มีอยู่เพื่อกำหนดระดับความเป็นสมาชิก",
	"notifiCationslong2": "ระยะเวลาการเป็นสมาชิกยังแบ่งตามระดับต่างๆ  เพื่อรักษาสถานะสมาชิกของคุณ คุณต้องเติมเงินอย่างน้อย 5,000 USDT ในแต่ละครั้งภายในเวลาที่กำหนดเพื่อรับสิทธิประโยชน์สำหรับสมาชิก  หลังจากการเติมเงินสมาชิกแต่ละครั้ง จะมีการคำนวณรอบใหม่และสามารถสะสมได้",
	"notifiCationslong3": "การบริการลูกค้าแบ่งตามระดับสมาชิก  การบริการลูกค้าอย่างเป็นทางการจัดทำโดยเจ้าหน้าที่ APP เพื่อให้บริการแบบครบวงจรแก่สมาชิก  จำนวนเลขานุการที่ทุ่มเทนั้นมีจำกัดเพื่อการบริการที่ดียิ่งขึ้น  เลขานุการส่วนตัวพิเศษจะให้บริการต้อนรับและคำแนะนำด้านการจัดการทางการเงินแก่ผู้ใช้",
	"notifiCationslong4": "ติดต่อฝ่ายบริการลูกค้าล่วงหน้าเพื่อนัดหมายการเติมเงิน  หากคุณเติมเงินเสร็จสิ้นภายในเวลาที่กำหนด คุณจะได้รับรางวัลเติมเงินที่สงวนไว้เพิ่มเติมตามระดับสมาชิกที่เกี่ยวข้อง",
	"notifiCationslong5": "VIP1: ขึ้นอยู่กับบัญชี UID หากมูลค่าสะสมคือ 10,000 USD คุณจะได้รับรางวัล 177 USDT  หลังจากถึงระดับ VIP และได้รับเหรียญตราสมาชิกสามัญแล้ว คะแนนจะถูกส่งไปยังบัญชี",
	"notifiCationslong6": "VIP2: ตามบัญชี UID หากมูลค่าที่เก็บไว้สะสมถึง 30,000 USD คุณจะได้รับ 777 USDT  จำนวนโบนัสจะถูกฝากเข้าบัญชีทันทีหลังจากถึงระดับ VIP และได้รับป้ายสมาชิกสีบรอนซ์",
	"notifiCationslong7": "VIP3: ตามบัญชี UID หากมูลค่าสะสมคือ 70,000 ดอลลาร์สหรัฐ คุณจะได้รับ 1,777 USDT  หลังจากไปถึงระดับ VIP และได้รับเหรียญสมาชิกเงินแล้ว คะแนนจะถูกส่งไปยังบัญชี  นอกจากนี้ คุณจะได้รับโบนัสเพิ่มเติม 1.5% จากมูลค่าการจองครั้งต่อไปของคุณ",
	"notifiCationslong8": "VIP4: ตามบัญชี UID มูลค่าที่เก็บไว้สะสมคือ 150,000 ดอลลาร์สหรัฐ และจะมีการมอบรางวัล 2,777 USDT  หลังจากถึงระดับ VIP และได้รับ Gold Member Medal แล้ว คะแนนจะถูกส่งไปยังบัญชี  นอกจากนี้ คุณจะได้รับโบนัสเพิ่มเติม 2% จากมูลค่าการเติมของการจองครั้งต่อไปของคุณ",
	"notifiCationslong9": "VIP5: ขึ้นอยู่กับบัญชี UID หากมูลค่าสะสมสะสมถึง 300,000 USD จะได้รับ 4777 USDT เป็นของขวัญ  หลังจากถึงระดับ VIP และได้รับตรา Diamond VIP แล้ว คะแนนจะถูกส่งไปยังบัญชี  นอกจากนี้ คุณจะได้รับโบนัสเพิ่มเติม 2.5% จากมูลค่าที่เก็บไว้สำหรับการจองครั้งต่อไปของคุณ",
	"notifiCationslong10": "VIP6: ขึ้นอยู่กับบัญชี UID มูลค่าสะสมสะสมจะสูงถึง 800,000 USD และ 16,888 USDT จะถูกมอบให้เป็นของขวัญ  จำนวนโบนัสจะถูกส่งไปยังบัญชีทันทีหลังจากถึงระดับวีไอพีและได้รับตราสมาชิกแบล็คไดมอนด์ และจะได้รับเพิ่มอีก 3% ของจำนวนเงินที่ลงทะเบียนล่วงหน้าครั้งถัดไป  คุณจะมีเลขานุการส่วนตัวโดยเฉพาะเพื่อให้คำแนะนำทางการเงินหรือจัดการเรื่องภาษี",
	"notifiCationslong11": "VIP7: ตามบัญชี UID หากมูลค่าสะสมสะสมถึง 3 ล้านเหรียญสหรัฐ คุณจะได้รับทริปหรูหราไปยุโรป 15 วัน (พร้อมส่วนลด)  ได้รับรางวัลวีไอพีกิตติมศักดิ์ระดับโลกตลอดชีพของ CoinBpos และได้รับเหรียญสมาชิกคราวน์  คุณจะได้รับโบนัสเพิ่มเติม 3.5% เมื่อคุณเติมเงินในการจองครั้งถัดไป",
	"notifiCationslong12": "VIP8: ตามบัญชี UID หากมูลค่าสะสมสะสมถึง 8 ล้านดอลลาร์สหรัฐ คุณจะได้รับคูปองของขวัญงานเลี้ยงประจำปีของ CoinBpos Moroccan  รางวัล: 1 BTC และรับ CoinBpos เกียรติยศระดับโลกตลอดชีพ VIP  คุณสามารถรับโบนัสเพิ่มเติม 4% เมื่อคุณเติมเงินในการจองครั้งถัดไป",
	"notifiCationslong13": "VIP9: ตามบัญชี UID หากคุณฝากเงินรวม 30 ล้านดอลลาร์สหรัฐ คุณจะได้รับหุ้น 3% ของ CoinBpos  และเพลิดเพลินกับเงินปันผลกำไร 3% ต่อปี  เข็มกลัดที่ระลึกทองคำแท้ Cronix ฟรี",

	//invite
	"Refer and Earn": "อ้างอิงและรับ",
	"Copy Link": "คัดลอกลิงค์",

	//faq
	"Frequently Asked Questions": "คำถามที่พบบ่อย",

	//authenticator
	"mailbox": "จดหมาย",
	"Front photo": "ภาพถ่ายตรง",
	"Back photo": "ภาพถ่ายด้านหลัง",
	"Handheld ID photo": "ถือรูปถ่ายประจำตัว",
	"define": "แน่นอน",

	//legality
	"legalitylong1": "Coinbpos ประกอบด้วยบริษัทสกุลเงินดิจิทัลหลายแห่งที่ร่วมกันจัดหาเครื่องมือเพื่อช่วยให้ชุมชนสกุลเงินดิจิทัลสร้าง เติบโต และรักษาเสถียรภาพของชุมชน ขับเคลื่อนแอปพลิเคชันที่มีการกระจายอำนาจนับพัน ขับเคลื่อนอนาคตของสกุลเงินดิจิทัล และส่งเสริมวิวัฒนาการของสกุลเงินดั้งเดิม การแปลงเป็นดิจิทัลและส่งเสริมเศรษฐกิจโลก ความเจริญรุ่งเรือง.",
	"legalitylong2": "แต่ละบริษัทภายใน Coinbpos ทำหน้าที่เป็นผู้ควบคุมข้อมูลสำหรับการประมวลผลข้อมูลส่วนบุคคลที่เกี่ยวข้องกับบริการของตน ผู้ควบคุมข้อมูลสำหรับแต่ละบริการคือ:",
	"legalitylong3": "1. เทคโนโลยีบล็อคเชน - Ethereum blockchain",
	"legalitylong4": "2. กระเป๋าเงินแบบกระจายอำนาจ - กระเป๋าเงินที่เชื่อถือได้, MetaMask และกระเป๋าเงินดิจิตอลเข้ารหัส",
	"legalitylong5": "3. เทคโนโลยีการซื้อขาย-Coinbase, Crypto, linch, Binance",
	"legalitylong6": "นโยบายความเป็นส่วนตัวให้รายละเอียดวิธีที่ Coinbpos ประมวลผลข้อมูลส่วนบุคคลตามภาระผูกพันภายใต้กฎหมายคุ้มครองข้อมูลที่เกี่ยวข้อง รวมถึงกฎระเบียบคุ้มครองข้อมูลทั่วไป (GDPR) ของสหภาพยุโรป",
	"legalitylong7": 'พระราชบัญญัติความเป็นส่วนตัวของผู้บริโภคแห่งแคลิฟอร์เนีย (CCPA) และกฎหมายทั่วไปอื่นๆ (เรียกรวมกันว่า "กฎหมายคุ้มครองข้อมูล"*)',

	//language
	"language": "ภาษา",
	"Confirm": "ยืนยัน",
	"Cancel":'ยกเลิก',

	"newtext1": "ล้มเลิก",
	"newtext2": "ยกเลิก",
	"newtext3": "คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบบัญชีนี้",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "เข้าสู่ระบบ",
	"newtext9": "ชื่อผู้ใช้",
	"newtext10": "หมายเลขโทรศัพท์",
	"newtext11": "กรุณากรอกชื่อผู้ใช้",
	"newtext12": "กรุณากรอกหมายเลขโทรศัพท์",
	"newtext13": "รหัสผ่าน",
	"newtext14": "กรุณากรอกรหัสผ่าน",
	"newtext15": "ยังไม่มีบัญชี",
	"newtext16": "ลงทะเบียน",
	"newtext17": "ประเทศ",
	"newtext18": "เลือกประเทศ",
	"newtext19": "รหัสยืนยันหมายเลขโทรศัพท์มือถือ",
	"newtext20": "กรุณากรอกรหัสยืนยัน",
	"newtext21": "ที่อยู่อีเมล",
	"newtext22": "กรุณากรอกที่อยู่อีเมล",
	"newtext23": "ยืนยันรหัสผ่าน",
	"newtext24": "กรุณากรอกรหัสผ่านยืนยัน",
	"newtext25": "มีบัญชี",
	"newtext26": "ส่งรหัสยืนยัน",
	"newtext27": "หมายเลขโทรศัพท์มือถือไม่ถูกต้อง",
	"newtext28": "ส่งเรียบร้อยแล้ว",
	"newtext29": "ความสำเร็จในการลงทะเบียน",

	"newtext30": 'สกุลเงิน',
	"newtext31": 'ฟอเร็กซ์',
	"newtext32": 'โลหะมีค่า',
	
	"newtext33": 'บัญชี',
	"newtext34": 'กิจกรรมแพลตฟอร์ม',
	"newtext35": 'บริการลูกค้าออนไลน์',
	"newtext36": 'คำเชิญ',
	"newtext37": 'การยืนยัน KYC',
	"newtext38": 'การตั้งค่า',
	
	
	wallet_p: {
		text1: "คัดลอกที่อยู่",
		text2: 'ยืนยัน',
		text3: 'แลกเปลี่ยนจาก',
		text4: "แลกเปลี่ยนไป",
		text5: "MAX",
		text6: "เชิญ",
		text7: 'รับรางวัลการแนะนำ',
		text8: "เมื่อเพื่อนของคุณเขົ້າร่วม AI arbitrage และเช่าเครื่องขุดแร่, คุณสามารถรับรางวัลการแนะนำ",
		text9: "คัดลອກ",
		text10: 'แชร์ลิงค์',
		text11: "KYC Verification",
		text12: "ประเทศ",
		text13: "โปรดเลือกประเทศ",
		text14: "ชื่อ",
		text15: "โปรดระบุชื่อ",
		text16: "นามสกุล",
		text17: "โปรดระบุนามสกุล",
		text18: "ประเภทใบรับรอง",
		text19: "โปรดเลือกประเภทใบรับรอง",
		text20: "หมายเลขใบรับรອງ",
		text21: "โปรดระบุหมายเลขใบรับรອງ",
		text22: "โปรดอัพโหลดรูปฝั่งหน้าบัตรประจำตัว",
		text23: "โปรดอัพโหลดรูปฝั่งหลังบัตรประจำตัว",
		text24: "โปรดอัพโหลดรูปฝั่งหน้าหนังสือเดີນทาง",
		text25: "โปรดอัพโหลดรูปฝั່ງหลังหนังสือเดີນทาง",
		text26: "อีเมล",
		text27: "โปรดระบุอีเมล",
		text28: "ส่ง",
		text29: "รหัสยืนยัน",
		text30: "โปรดระบุรหัสยืนยัน",
		text31: "ID",
		text32: "หนังสือเดີນทาง",
		text33: "ยกเลิก",
		text34: "ประเทศ/ภูมิภาค",
		text35: "โปรดระบุข้อมูลที่ถูกต้องเพื่อยื่นเพื่อการยืนยัน",
		text36: "ส่งเรียบร้อย!",
		text37: "รูปแบบอีเมลผิด!",
		text38: "ใบขับขี่",
		text39: "โปรดอัปโหลดรูปถ่ายด้านหน้าของใบขับขี่ของคุณ",
		text40: "โปรดอัปโหลดรูปถ่ายด้านหลังของใบขับขี่ของคุณ",
		
		text41: "หมายเลขบัตรประจำตัวประชาชน",
		text42: "กรุณากรอกหมายเลขประจำตัวของคุณ",
		text43: "หมายเลขหนังสือเดินทาง",
		text44: "กรุณากรอกหมายเลขหนังสือเดินทางของคุณ",
		text45: "หมายเลขใบขับขี่",
		text46: "กรุณากรอกหมายเลขใบขับขี่ของคุณ",
	},
	jiaoyi: {
		text1: 'สัญญาที่สอง',
		text2: 'สัญญาถาวร',
		text3: 'ธุรกรรมทันที',
		text4: 'มอบหมายตอนนี้',
		text5: 'ประเภทบัญชี',
		text6: 'เวลาจัดส่ง',
		text7: 'ช่วงราคา',
		text8: 'ราคาซื้อ',
		text9: 'ค่าธรรมเนียม',
		text10: 'สมดุล',
		text11: 'อย่างน้อย',
		text12: 'คาดหวัง',
		text13: 'จำนวนการซื้อ',
		text14: 'ทิศทาง',
		text15: 'ราคาซื้อ',
		text16: 'ราคาแบบเรียลไทม์',
		text17: 'นับถอยหลัง',
		text18: 'สัญญาของฉัน',
		text19: 'กำไร',
		text20: 'สัญญา',
		text21: 'ราคาส่ง',
		text22: 'เวลาจัดส่ง',
		text23: 'ราคาตลาด',
		text24: 'เพิ่มขึ้น',
		text25: 'ตก',
		text26: 'ราคาซื้อ',
		text27: 'สูงสุด',
		text28: 'กำไร',
		text29: 'ค่าธรรมเนียมการจัดการ',
		text30: 'ซื้อ',
		text31: 'ขาย',
		text32: 'ปริมาณการซื้อ',
		text33: 'บัญชีจริง',
		text34: 'บัญชีเสมือน',
		text35: 'ยังไม่มีข้อมูล',
		text36: 'ตำแหน่งปัจจุบัน',
		text37: 'ค่าคอมมิชชั่นในอดีต',
		text38: 'ปิดตำแหน่ง',
		text39: 'ระยะขอบ',
		text40: 'กำไรและขาดทุน',
		text41: 'ถือ',
		text42: 'บันทึกการซื้อ',
		text43: 'บันทึกการขาย',
		text44: 'ว่าง',
		text45: 'พับ',
		text46: 'การรับรองความถูกต้องสำเร็จ',
		text47: 'เวลาซื้อ',
		text48: 'ยังไม่ได้อ่าน',
		text49: 'อ่าน',
		text50: 'คำสั่งโฮสต์',
		text51: 'การเก็งกำไรทั้งหมด',
		text58: 'รายได้วันนี้',
		text52: 'หุ่นยนต์ปัญญาประดิษฐ์ทำงานอย่างไร',
		text53: 'ผลิตภัณฑ์เก็งกำไร',
		text54: 'วัน',
		text55: 'จำนวนเงิน',
		text56: 'รายได้รายวัน',
		text57: 'ประเภทสกุลเงิน',
		text59: 'เข้าร่วมการเก็งกำไรของ AI',
		text60: 'ความเสี่ยงเป็นศูนย์ คืนทุนเร็ว',
		text61: 'การเก็งกำไรของ AI',
		text62: 'รายได้ที่คาดหวัง',
		text63: 'ประเภทเหรียญเก็งกำไร',
		text64: 'จำนวนเอสโครว์',
		text65: 'โฮสต์ทันที',
		text66: 'รายได้รายวันส่งไปยังกระเป๋าเงิน USDT ของคุณ',
		text67: 'กองทุนรวมที่ลงทุนเป็นศูนย์ความเสี่ยง',
		text68: 'คุณสามารถถอนเงินของคุณได้ตลอดเวลา',
		text69: 'ปัญญาประดิษฐ์ทำงานตลอด 24 ชั่วโมง',
		text70: 'รายได้สะสม',
		text71: 'เวลาหมดอายุ',
		text72: 'ทั้งหมด',
		text73: 'กำลังดำเนินการ',
		text74: 'เสร็จสมบูรณ์',
		text75: 'รายละเอียดโฮสต์',
		text76: 'เลือกสกุลเงิน',
		text77: 'การสนับสนุนทางเทคนิค',
		text78: 'ส่งอีเมล',
	},
	tabbar:{
		text1:'หน้าแรก',
		text2:'ตลาด',
		text3:'ปริมาณ Ai',
		text4:'เงินกู้',
		text5:'บัญชี',
		text6:'ยอดเงินในบัญชี',
		text7:'รายได้วันนี้',
		text8:'อัตราผลตอบแทน',
		text9:'เพิ่มเติม',
		text10:'เร็วๆ นี้',
		text11:'ขายเหรียญใหม่ล่วงหน้า',
		text12:'เริ่มต้นการเดินทางสู่ความมั่งคั่งทางดิจิทัล',
		text13:'ซื้อเลย',
		text14:'ใช้งานง่าย',
		text15:'การซื้อขายเชิงปริมาณ AI',
		text16:'ปลอดภัย / เสถียร / เรียบง่าย',
		text17:'สร้างธุรกรรม',
		text18:'ค่าคอมมิชชั่นทั้งหมด',
		text19:'แชร์ลิงก์นี้กับเพื่อนของคุณและรับสูงถึง $200',
		text20:'ลิงก์แนะนำ',
		text21:'แบ่งปันกับเพื่อนของคุณผ่านซอฟต์แวร์โซเชียล',
	},
	tab4:{
		text1: "ยอดคงเหลือในบัญชี",
		text2: "รายได้วันนี้",
		text3: "ปริมาณ AI",
		text4: "อัตราผลตอบแทน",
		text5: "เงินฝาก",
		text6: "โอนเงิน",
		text7: "การถอนเงิน",
		text8: "เงินฝาก",
		text9: "ที่อยู่กระเป๋าเงิน",
		text10: "คัดลอก",
		text11: "อัปโหลดใบรับรอง",
		text12: "โปรดตรวจสอบที่อยู่การฝากเงินอีกครั้งเพื่อให้แน่ใจว่าถูกต้อง เนื่องจากการโอนเงินที่ไม่ถูกต้องอาจไม่สามารถย้อนกลับได้ การฝากเงินต้องได้รับการยืนยันก่อนที่จะโอนเข้าบัญชีของคุณ",
		text13: "โอนเงิน",
		text14: "ก่อนเริ่มการโอนเงิน:",
		text15: "ก่อนที่จะเริ่มการโอนเงิน โปรดติดต่อทีมบริการลูกค้าของเราเพื่อรับข้อมูลบัญชีการโอนเงินที่ถูกต้อง ขั้นตอนนี้ช่วยให้มั่นใจถึงความปลอดภัยและการมาถึงของเงินของคุณได้อย่างราบรื่น",
		text16: "เวลาดำเนินการโอนเงิน:",
		text17: "รายการนี้ซ้ำกับ text15 แต่ปกติจะไม่ได้ตั้งค่าเช่นนี้ อาจเป็นข้อผิดพลาด ในกรณีที่ถูกต้อง ควรอธิบายรายละเอียดของเวลาในการประมวลผลที่นี่", // สมมติว่าการแก้ไขคือ คำอธิบายของเวลาในการประมวลผล
		text18: "ความช่วยเหลือในระหว่างขั้นตอนการโอนเงิน:",
		text19: "หากคุณพบปัญหาหรือมีคำถามใดๆ ในระหว่างขั้นตอนการโอนเงิน โปรดติดต่อทีมบริการลูกค้าของเรา เรามุ่งมั่นที่จะให้ความช่วยเหลือและสนับสนุนเพื่อให้แน่ใจว่าประสบการณ์การซื้อขายจะราบรื่นสำหรับคุณ",
		text20: "จำนวนเงิน",
		text21: "สกุลเงินที่ถอน",
		text22: "ยืนยัน",
		text23: "โปรดตรวจสอบให้แน่ใจว่าได้ยืนยันที่อยู่รับของคุณแล้ว เนื่องจากการโอนเงินที่ไม่ถูกต้องไม่สามารถย้อนกลับได้ และจะใช้เวลาสักครู่เพื่อยืนยันก่อนที่จะโอนเข้าบัญชีของคุณ",
		text24: "ลำดับตัวเลือก",
		text25: "คำสั่งสัญญา",
		text26: "ลำดับเชิงปริมาณ AI",
		text27: "ประวัติศาสตร์",
		text28: "เชิญเพื่อน",
		text29: "คำถามที่พบบ่อย",
		text30: "บริการลูกค้าออนไลน์",
		text31: "เกี่ยวกับเรา",
		text32: "การเปลี่ยนภาษา",
		text33: "ออกจากระบบ"
	},
	uploadProof: {
		text1: "อัปโหลดใบรับรอง",
		text2: "เลือกรูปภาพ",
		text3: "ดูตัวอย่าง",
		text4: "อัปโหลดภาพหน้าจอของการฝากเงินของคุณ",
		text5: "จำนวนเงิน",
		text6: "แฮชธุรกรรม",
		text7: "เลข 6 หลักสุดท้าย",
		text8: "โปรดอัปโหลดภาพหน้าจอการชำระเงินสกุลเงินดิจิตอลของคุณและรอการตรวจสอบหลังจากส่ง",
		text9: "ยืนยัน"
	},
	newadd: {
	    text1: 'สำเร็จ',
	    text2: 'ล้มเหลว',
	    text3: 'รอดำเนินการ',
	    text4: 'โทเค็น Cown ของฉัน',
	    text5: 'อุปทาน',
	    text6: 'ขายแล้ว',
	    text7: 'การขายล่วงหน้าสิ้นสุด',
	    text8: 'จำนวนเงิน',
	    text9: 'แลกเปลี่ยน',
	    text10: 'คุณสมบัติทางเทคนิค',
	    text11: 'เหรียญใหม่นำเทคโนโลยีบล็อกเชนมาใช้ มีความเร็วในการทำธุรกรรมสูง ค่าธรรมเนียมต่ำ ความปลอดภัยในการทำธุรกรรมสูง พร้อมฟังก์ชั่นสัญญาอัจฉริยะและเทคโนโลยีการปกป้องความเป็นส่วนตัว',
	    text12: 'วิสัยทัศน์ของโปรเจกต์',
	    text13: 'วิสัยทัศน์ของเหรียญใหม่นี้คือการเป็นแพลตฟอร์มการซื้อขายสินทรัพย์ดิจิทัลชั้นนำของโลก โดยให้บริการซื้อขายสินทรัพย์ดิจิทัลที่ปลอดภัย สะดวก และมีประสิทธิภาพแก่ผู้ใช้',
	    text14: 'กรณีการใช้งาน',
	    text15: 'เหรียญใหม่มีกรณีการใช้งานที่หลากหลาย รวมถึงการซื้อขายสกุลเงินดิจิทัล การซื้อขายหลักทรัพย์ดิจิทัล การซื้อขายสินทรัพย์ดิจิทัล การชำระเงิน การเงินซัพพลายเชน และอื่นๆ',
	    text16: 'วงเงินกู้ยืม',
	    text17: 'ยืนยันตัวตนของคุณและรับบริการเพิ่มเติม!',
	    text18: 'เริ่มการยืนยัน',
	    text19: 'ฉันต้องการกู้...',
	    text20: 'ระยะเวลากู้ยืม',
	    text21: 'วัน',
	    text22: 'อัตราดอกเบี้ยรายวัน',
	    text23: 'จำนวนดอกเบี้ยทั้งหมด',
	    text24: 'ไม่ต้องจ่ายดอกเบี้ยภายใน 1 วันแรก หลังจากนั้นต้องจ่ายดอกเบี้ย',
	    text25: 'กู้เงินตอนนี้',
	    text26: 'ประวัติการกู้ยืม',
	    text27: 'ยังไม่ได้กู้เงิน',
	    text28: 'ไม่พบข้อมูลการกู้ยืมของคุณ',
	    text29: 'เกินขอบเขตวงเงินกู้ยืม',
	    text30: 'อยู่ในระหว่างการสมัคร',
	    text31: 'กำลังดำเนินการ',
	    text32: 'เสร็จสิ้น',
	    text33: 'เปิด',
	    text34: 'ปิด',
	    text35: 'บันทึก',
	    text36: 'ฝากเงิน',
	    text37: 'ถอนเงิน',
	    text38: 'โอ๊ะ!',
	    text39: 'คุณยังไม่มีประวัติการทำธุรกรรม รายการว่างเปล่า',
	    text40: 'กรุณาใส่จำนวนเงิน',
	    text41: 'ปกติ',
	    text42: 'เสร็จสิ้น',
	    text43: 'อีเมล',
	    text44: 'รหัสยืนยันทางอีเมล',
	    text45: 'ยืนยันการชำระเงินบน DAPP',
	    text46: 'เกิดข้อผิดพลาดในการเติมเงิน โปรดลองอีกครั้งหรือติดต่อฝ่ายบริการลูกค้า',
	    text47: 'การสมัครเติมเงินได้ถูกส่งแล้ว',
	    text48: 'อยู่ในระหว่างการตรวจสอบ',
	    text49: 'ตรวจสอบผ่านแล้ว',
	
	    // เกี่ยวกับเรา
	    text50: 'HKEX เป็นแพลตฟอร์มบริการซื้อขายสกุลเงินดิจิทัลที่มีชื่อเสียงระดับโลก HKEX รองรับการซื้อขายสินทรัพย์ดิจิทัลหลายประเภท ก่อตั้งขึ้นในเดือนมิถุนายน 2019 และเติบโตเป็นหนึ่งในแพลตฟอร์มบริการซื้อขายสกุลเงินดิจิทัลที่ได้รับความนิยมมากที่สุด',
	    text51: 'ปัจจุบันให้บริการแบบครบวงจรแก่ผู้ใช้กว่า 1 ล้านคนใน 35 ประเทศและภูมิภาคทั่วโลก รวมถึงการซื้อขายเหรียญ การซื้อขายเงินตรา การทำสัญญา และการกู้ยืม โดดเด่นในฐานะแพลตฟอร์มบริการซื้อขายที่ฉลาดและรวดเร็วที่สุด',
	    text52: 'HKEX มีสำนักงานอยู่ที่ฮ่องกง ให้บริการลูกค้าตลอด 24 ชั่วโมง 7 วันต่อสัปดาห์ และมีชุมชนในท้องถิ่นในแคนาดา ฝรั่งเศส สิงคโปร์ สหรัฐอเมริกา เกาหลี ญี่ปุ่น อิตาลี ตุรกี รัสเซีย อินเดีย และประเทศอื่นๆ ตั้งแต่เดือนมกราคม 2020 HKEX ได้รับเงินทุนรอบ A มูลค่า 150 ล้านดอลลาร์จาก KTR Capital และ Qianpeng Ventures ในแคนาดา',
	    text53: 'ตั้งแต่เดือนพฤษภาคม 2022 ทีมงานสถานีเอเชียแปซิฟิกของ HKEX ได้ทำงานอย่างหนักเพื่อพัฒนาเข้าสู่ตลาดภาษาจีนและได้รับความสนใจอย่างมากในชุมชนจีน HKEX ยึดมั่นในกลยุทธ์การพัฒนา "ออปชัน + สัญญา" และมุ่งมั่นในการปฏิบัติตามข้อกำหนดทางกฎหมาย เพื่อให้บริการทางการเงินดิจิทัลแก่ผู้ใช้ทั่วโลก',
	
	    // เกี่ยวกับเรา end
	
	    text54: 'ประกาศข้อตกลงการใช้งาน',
	    text55: 'ประกาศทางกฎหมาย',
	    text56: 'กฎการซื้อขาย OTC',
	    text57: 'ประกาศความเสี่ยงในการดำเนินการถอนเงิน',
	
	    text58: 'ขอบเขตน้ำหนัก',
	    text59: 'ขอบเขตโควต้า',
	    text60: 'กำไรสูงสุด',
	    text61: 'อัตราผลตอบแทนที่คาดหวัง',
	    text62: 'เลือกพื้นที่การซื้อขาย',
	    text63: 'เลือกเหรียญ',
	    text64: 'ตัวคูณเลเวอเรจ',
	    text65: 'เลือกตัวคูณ',
	    text66: 'จำนวนเงินแผนการ',
	    text67: 'กรุณากรอกจำนวนเงินแผนการ',
	    text68: 'เลือกแผนขั้นตอน',
	    text69: 'คะแนนน้ำหนักปัจจุบันของคุณคือ',
	    text70: 'เลเวอเรจระดับนี้ยังไม่ถูกปลดล็อก',
	    text71: 'กรุณากรอกจำนวนเงิน',
	    text72: 'กรุณาเลือกตัวคูณเลเวอเรจ',
	    text73: 'ขาดทุนสูงสุด',
		
		text74:'ซื้อขายต่อ',
		text75:'กำลังปักหลัก',
		text76:'รหัสยืนยันของ Google',
		text77:'กรุณากรอกรหัสยืนยัน Google',
	}
}
